import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../UserContext';

const GiftCardForm = ({ show, handleClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { giftMessage, setGiftMessage } = useContext(UserContext);

  const onSubmit = data => {
    const templateParams = {
      from_name: data.your_name,
      your_name: data.your_name,
      gift_receiver_name: data.gift_receiver_name,
      your_phone: data.your_phone,
      your_email: data.your_email,
      message: giftMessage || 'No message provided',
    };

    // console.log(templateParams); // Check if 'your_email' is populated
    emailjs.send('service_p62d03w', 'template_xo6t5rb', templateParams, 'lv1IeTKgYIwTGD7A5').then(
      result => {
        console.log(result.text);
        setIsSubmitted(true);
        reset();
        setTimeout(() => {
          setIsSubmitted(false);
          handleClose();
        }, 3000);
      },
      error => {
        console.log(error.text);
      }
    );
  };

  return (
    <Modal show={show} onHide={handleClose} centered className='gift-card-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Gift Card</Modal.Title>
      </Modal.Header>
      <Modal.Body className='gift-card-body'>
        {isSubmitted ? (
          <div className='text-center'>
            <h5>Sit back, someone from our gifting team will call you shortly.</h5>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='form-group'>
              <label>Your Name</label>
              <input
                {...register('your_name', { required: 'Your name is required' })}
                className={`form-control ${errors.your_name ? 'is-invalid' : ''}`}
              />
              {errors.your_name && <div className='invalid-feedback'>{errors.your_name.message}</div>}
            </div>

            <div className='form-group'>
              <label>Gift Receiver's Name</label>
              <input
                {...register('gift_receiver_name', { required: "Gift receiver's name is required" })}
                className={`form-control ${errors.gift_receiver_name ? 'is-invalid' : ''}`}
              />
              {errors.gift_receiver_name && <div className='invalid-feedback'>{errors.gift_receiver_name.message}</div>}
            </div>
            <div className='form-group'>
              <label>Your Phone</label>
              <input
                type='tel'
                {...register('your_phone', {
                  required: 'Your phone number is required',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Phone number must contain only numeric values',
                  },
                })}
                className={`form-control ${errors.your_phone ? 'is-invalid' : ''}`}
              />
              {errors.your_phone && <div className='invalid-feedback'>{errors.your_phone.message}</div>}
            </div>

            <div className='form-group'>
              <label>Your Email ID</label>
              <input
                type='email'
                {...register('your_email', { required: 'Your email ID is required' })}
                className={`form-control ${errors.your_email ? 'is-invalid' : ''}`}
              />
              {errors.your_email && <div className='invalid-feedback'>{errors.your_email.message}</div>}
            </div>

            <Button variant='primary' type='submit' className='gift-form-submit-btn'>
              Submit
            </Button>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GiftCardForm;
