import React, { useEffect, useState, useContext } from "react";
import { Image, Modal, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ArrowBtn from "../assets/images/gradient-right-arrow.svg";
import LightArrowLeft from "../assets/images/arrow-left.svg";
import LightArrowRight from "../assets/images/arrow-right.svg";
import { UserContext } from "../../UserContext";
import { useParams } from "react-router-dom";
import { fetchMediaData, fetchDestinationData } from "../../apiUtils";
import HeaderBtn from "../assets/images/Rectangle.svg";
import GradientBtn from "../../desktop-view/gradient-btn";

function DetailsAccomodation() {
  const { destinationData, selectedDestination, setDestinationData } =
    useContext(UserContext);
  const [accommodationData, setAccommodationData] = useState([]);
  const { destination } = useParams();
  const [modalImages, setModalImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (destinationData && destinationData.length > 0) {
      handleDataFromContext();
    } else {
      fetchData();
    }
  }, []);

  const handleDataFromContext = async () => {
    try {
      if (!destinationData || !selectedDestination) {
        return;
      }

      const destinationDetails =
        destinationData[destination]?.[0]?.destination_details;

      if (!destinationDetails || !destinationDetails.accommodation_options) {
        console.error("Invalid destination details structure.");
        return;
      }

      const cardsImages = destinationDetails.accommodation_options;
      const imageIds = [
        ...new Set(cardsImages.map((item) => item.image).filter(Boolean)),
      ];

      if (imageIds.length > 0) {
        const mediaDataArray = await fetchMediaData(imageIds);

        const mediaDataMap = mediaDataArray.reduce((acc, media) => {
          if (media && media.description) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(
              media.description.rendered,
              "text/html"
            );
            const imgElement = doc.querySelector("img");
            acc[media.id] = imgElement ? imgElement.src : null;
          }
          return acc;
        }, {});

        const accommodations = cardsImages
          .map((item) => ({
            ...item,
            imageUrl: mediaDataMap[item.image] || null,

          }))
          .filter((img) => img.imageUrl);

        setAccommodationData(accommodations.filter((item) => item.imageUrl));
      } else {
        console.error("No image IDs found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const fetchedData = await fetchDestinationData();
      setDestinationData(fetchedData);

      const destinationDetails =
        fetchedData[destination]?.[0]?.destination_details;

      if (!destinationDetails || !destinationDetails.accommodation_options) {
        return;
      }

      const cardsImages = destinationDetails.accommodation_options;
      const imageIds = [
        ...new Set(cardsImages.map((item) => item.image).filter(Boolean)),
      ];

      if (imageIds.length > 0) {
        const mediaDataArray = await fetchMediaData(imageIds);

        const mediaDataMap = mediaDataArray.reduce((acc, media) => {
          if (media && media.description) {


            const fullImageUrl = media.media_details.sizes?.full?.source_url;
            acc[media.id] = fullImageUrl || null;
          }
          return acc;
        }, {});

        const accommodations = cardsImages
          .map((item) => ({
            ...item,
            imageUrl: mediaDataMap[item.image] || null,
         
          }))
          .filter((img) => img.imageUrl);

        setAccommodationData(accommodations.filter((item) => item.imageUrl));
      } else {
        console.error("No image IDs found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick = (url) => {
    window.open(url);
  };

  const handleCardClick = (accommodation) => {
    setModalImages(accommodation.accommodationImages);
    setCurrentIndex(0);
    setShowModal(true);
  };

  const goToPreviousImage = () => {
    setCurrentIndex(
      (currentIndex - 1 + modalImages.length) % modalImages.length
    );
  };

  const goToNextImage = () => {
    setCurrentIndex((currentIndex + 1) % modalImages.length);
  };

  if (!accommodationData.length) {
    return null;
  }

  return (
    <div className="accomodation-section">
      <span>Accommodation Options</span>
        <div className="cards-section">
          {accommodationData.map((item, index) => (
            <Card key={index}>
              <Card.Img
                src={item.imageUrl}
                onClick={() => handleClick(item.book_now_link)}
              />
              <Card.Body>
                <Card.Title>{item.card_title}</Card.Title>
                <Card.Text className="d-flex justify-content-between align-items-center">
                  <div className="">
                    <span>{item.card_text_1}</span>
                    <span>{item.card_text_2}</span>
                    <span>{item.card_text_3}</span>
                  </div>

                  <a
                    className="text-decoration-none"
                    onClick={() => handleClick(item.book_now_link)}
                  >
                    <GradientBtn
                      className="desktop-header-btn book-now-button ms-0 mt-3"
                      text="Book Now"
                    />
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      

    </div>
  );
}

export default DetailsAccomodation;
