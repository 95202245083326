import React, { useState } from "react";
import { Image } from "react-bootstrap";
import PageNotFoundImg from "./desktop-assets/images/404_page_not_foundΓÇôwith_text.png";

function DesktopPageNotFound() {
  return (
    <div>
      <Image src={PageNotFoundImg} className="desktop-not-found-img" 
      
       />
    </div>
  );
}

export default DesktopPageNotFound;
