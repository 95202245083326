import React from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import '../style/events.scss';
import Card from 'react-bootstrap/Card';
import Calender from '../assets/images/events-calender.svg';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { UserContext } from '../../UserContext';
import { useContext, useEffect, useState } from 'react';
import { fetchMediaData } from '../../apiUtils';

function AnimatedCard({ children, from }) {
  const fromAnimation = from === 'left' ? { x: -100 } : { x: 100 };

  // return (
  //   <motion.div
  //     initial={{ x: fromAnimation.x, opacity: 0 }}
  //     animate={{ x: 0, opacity: 1 }}
  //     transition={{ duration: 0.5 }}
  //   >
  //     {children}
  //   </motion.div>
  // );
}

function CorporateEvents() {
  const { eventsData, setSelectedEvents } = useContext(UserContext);
  const [images, setImages] = useState({});

  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        if (!eventsData) {
          // console.error("eventsData is not available.");
          return;
        }

        const cardNames = Object.keys(eventsData).filter(key => key.startsWith('card_'));

        const cardsImageIds = cardNames
          .map(cardName => eventsData[cardName]?.corporate_events?.corporate_events_card?.image)
          .filter(Boolean);

        const mediaData = await fetchMediaData(cardsImageIds);

        if (!Array.isArray(mediaData)) {
          console.error('Invalid media data format:', mediaData);
          return;
        }

        const imagesMap = {};
        mediaData.forEach(media => {
          if (media.id && media.source_url) {
            imagesMap[media.id] = media.source_url;
          } else {
            console.warn(`Media missing id or source_url:`, media);
          }
        });

        const fetchedImages = {};
        cardNames.forEach(cardName => {
          const imageId = eventsData[cardName]?.corporate_events?.corporate_events_card?.image;
          if (imageId && imagesMap[imageId]) {
            fetchedImages[cardName] = imagesMap[imageId];
          }
        });

        setImages(fetchedImages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchLogoData();
  }, [eventsData]);

  const handleEventsClick = event => {
    setSelectedEvents(event);
  };

  const cardNames = eventsData ? Object.keys(eventsData).filter(key => key.startsWith('card_')) : [];

  const cards = cardNames.map(name => eventsData[name]).filter(Boolean);

  if (cards.length === 0) {
    return null;
  }

  return (
    <div className='corporate-events-section'>
      <div className='corporate-events-title'>{/* <span>{eventsData?.title || "Loading Title..."}</span> */}</div>
      <div className='events-cards-section'>
        <Row>
          {cards.map((item, index) => {
            const cardName = cardNames[index];
            const card = item.corporate_events.corporate_events_card;
            const imageSrc = images[cardName];

            return card && card.title && card.date && imageSrc ? (
              <Col key={index} xs={6} md={6} lg={6} className='mb-2'>
                {/* <AnimatedCard from={index % 2 === 0 ? 'left' : 'right'}> */}
                <Link
                  to={`/events-details/${cardName}`}
                  onClick={() => handleEventsClick(item)}
                  className='text-decoration-none'
                >
                  <Card className='card-hover-effect'>
                    <Card.Img src={imageSrc} />

                    <Card.Body>
                      <Card.Text>
                        <div className='d-flex align-items-center'>
                          {/* <Image src={Calender} /> */}
                          {/* <h4>{card.date}</h4> */}
                          {/* <h4>{card.time}</h4> */}
                        </div>
                        {/* <div className="d-flex align-items-center mt-2">
                              <Image src={Location} />
                              <h4>{card.city_name}</h4>
                            </div> */}
                        <Card.Title>{card.title}</Card.Title>
                        <div className='fees-section'>
                          <span>Fees </span>
                          <span>{card.fees}</span>
                          <span> Onwards</span>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
                {/* </AnimatedCard> */}
              </Col>
            ) : null;
          })}
        </Row>
      </div>
    </div>
  );
}

export default CorporateEvents;
