import React, { useContext, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import LeftArrowDark from "../assets/images/arrow-left-dark.svg";
import RightArrowDark from "../assets/images/arrow-right-dark (1).svg";
import { UserContext } from "../../UserContext";
import { fetchMediaData } from "../../apiUtils";

function AboutUs() {
  const { homeData } = useContext(UserContext);
  const [imageSrcs, setImageSrcs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const visibleLogosCount = 3; 
 
  useEffect(() => {
    const fetchLogos = async () => {
      if (!homeData || !homeData.seen_on || !homeData.seen_on.seen_on_images) {
        // console.log("homeData is not populated yet");
        return;
      }

      try {
        const seenOnImages = homeData.seen_on.seen_on_images;

        const imageIds = seenOnImages.map((item) => item.image);
        const mediaData = await fetchMediaData(imageIds);

        const fetchedImageSrcs = mediaData
          .map((media) => media.source_url)
          .filter((url) => url !== null);

        setImageSrcs(fetchedImageSrcs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLogos();
  }, [homeData]);

   if (!homeData || !homeData.about_us_ || !homeData.seen_on || imageSrcs.length === 0) {
    return null;
  }


  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - visibleLogosCount;
      return newIndex < 0 ? imageSrcs.length - visibleLogosCount : newIndex;
    });
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + visibleLogosCount;
      return newIndex >= imageSrcs.length ? 0 : newIndex;
    });
  };


  return (
    <div className="about-us">
      <div className="about-us-title">
        <span>{homeData?.about_us_?.title_1}</span>
        <span>{homeData?.about_us_?.title_2}</span>
        <span>{homeData?.about_us_?.description}</span>
      </div>

      <div className="seen-on">
 <span>{homeData?.seen_on?.title_1}</span>

        <div className="seen-on-title d-flex justify-content-start">
   <span>{homeData?.seen_on?.title_2}</span>
        </div>
        <div className="d-flex justify-content-end dark-arrow-img">

          <Image src={LeftArrowDark} onClick={handleLeftClick} />
          <Image src={RightArrowDark} onClick={handleRightClick} />
         
        </div>
        <div className="logo-grid-wrapper">
          <div
            className="logo-grid"
            style={{ transform: `translateX(-${(currentIndex / visibleLogosCount) * 100}%)` }}
          >
            {imageSrcs.map((logo, index) => (
              <img
                key={index}
                src={logo}
                alt={`Logo ${index + 1}`}
                className="brand-logo"
                style={{ width: `${100 / visibleLogosCount}%` }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
