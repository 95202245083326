// import React, { useState } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';
// import emailjs from 'emailjs-com';

// const BookNowMobile = ({ show, handleClose }) => {
//   const [formData, setFormData] = useState({
//     name: '',
//     companyName: '',
//     designation: '',
//     email: '',
//     phone: '',
//     tentativePeople: '',
//     tentativeMonth: '',
//   });
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//     setErrors((prevErrors) => ({...prevErrors, [name]: ''}))
//   };

//   const handleWheel = () => {
//     if (
//       document.activeElement.type === "number" &&
//       document.activeElement.classList.contains("noscroll")
//     ) {
//       document.activeElement.blur();
//     }
//   };

//   document.addEventListener("wheel", handleWheel);

//   const validateForm = ()=>{
//     let formErrors = {};
//     const phoneRegex = /^[0-9]{10}$/;;
//     const numericRegex = /^[0-9]*$/;
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//     if(!formData.name) formErrors.name = 'Name is required';
//     if(!formData.companyName) formErrors.companyName = 'Company name is required';
//     if (!formData.designation) formErrors.designation = 'Designation is required';
//     if (!formData.email) {
//       formErrors.email = 'Email is required';
//     } else if (!emailRegex.test(formData.email)) {
//       formErrors.email = 'Invalid email format';
//     }
//     if (!formData.phone) {
//         formErrors.phone = 'Phone number is required';
//       } else if (!phoneRegex.test(formData.phone)) {
//         if (!numericRegex.test(formData.phone)) {
//           formErrors.phone = 'Phone number must contain only numeric values';
//         } else {
//           formErrors.phone = 'Phone number must be exactly 10 digits';
//         }
//       }
//     if (!formData.tentativePeople) {
//       formErrors.tentativePeople = 'Tentative People is required';
//     } else if (formData.tentativePeople <= 0) {
//       formErrors.tentativePeople = 'Tentative People must be greater than zero';
//     }
//     if (!formData.tentativeMonth) formErrors.tentativeMonth = 'Tentative Month is required'

//     setErrors(formErrors);
//     return Object.keys(formErrors).length === 0;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
// if(validateForm()){
//     const templateParams = {
//       name: formData.name,
//       companyName: formData.companyName,
//       designation: formData.designation,
//       email: formData.email,
//       phone: formData.phone,
//       tentativePeople: formData.tentativePeople,
//       tentativeMonth: formData.tentativeMonth,
//     };

//     emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', templateParams, 'YOUR_USER_ID')
//       .then((response) => {
//         console.log('Email sent successfully!', response.status, response.text);
//         handleClose();
//         setFormData({
//           name: '',
//           companyName: '',
//           designation: '',
//           email: '',
//           phone: '',
//           tentativePeople: '',
//           tentativeMonth: '',
//         });
//       })
//       .catch((error) => {
//         console.error('Failed to send email:', error);
//       });
//   };

//   }
//   return (
//     <Modal show={show} onHide={handleClose} className='gift-card-modal'>
//       <Modal.Header closeButton>
//         <Modal.Title>Book Now</Modal.Title>
//       </Modal.Header>
//       <Modal.Body className='gift-card-body'>
//       {isSubmitted ? (
//           <div className='text-center'>
//             <h5>Thank you. Someone from the team will connect with you the details shortly.</h5>
//           </div>
//         ) : (
//         <Form onSubmit={handleSubmit}>
//           <Form.Group controlId="formName">
//             <Form.Label>Name</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter your name"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               isInvalid={!!errors.name}
//             //   required
//             />
//               <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
//           </Form.Group>

//           <Form.Group controlId="formCompanyName">
//             <Form.Label>Company Name</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter your company name"
//               name="companyName"
//               value={formData.companyName}
//               onChange={handleChange}
//             //   required
//             isInvalid={!!errors.companyName}
//             />
//             <Form.Control.Feedback type='invalid'>{errors.companyName}</Form.Control.Feedback>
//           </Form.Group>

//           <Form.Group controlId="formDesignation">
//             <Form.Label>Designation</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter your designation"
//               name="designation"
//               value={formData.designation}
//               onChange={handleChange}
//             //   required
//             isInvalid={!!errors.designation}
//             />
//             <Form.Control.Feedback type='invalid'>{errors.designation}</Form.Control.Feedback>
//           </Form.Group>

//           <Form.Group controlId="formEmail">
//             <Form.Label>Email</Form.Label>
//             <Form.Control
//               type="email"
//               placeholder="Enter your email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//             //   required
//             isInvalid={!!errors.email}
//             />
//             <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>

//           </Form.Group>

//           <Form.Group controlId="formPhone">
//             <Form.Label>Phone</Form.Label>
//             <Form.Control
//               type="tel"
//               placeholder="Enter your phone number"
//               name="phone"
//               value={formData.phone}
//               onChange={handleChange}
//             //   required
//             inputMode='numeric'
//             isInvalid={!!errors.phone}
//             />
//             <Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>

//           </Form.Group>

//           <Form.Group controlId="formTentativePeople">
//             <Form.Label>Tentative People</Form.Label>
//             <Form.Control
//               type="number"
//               className=' noscroll'
//               placeholder="Enter number of people"
//               name="tentativePeople"
//               value={formData.tentativePeople}
//               onChange={handleChange}
//             //   required
//             isInvalid={!!errors.tentativePeople}
//             />
//             <Form.Control.Feedback type='invalid'>{errors.tentativePeople}</Form.Control.Feedback>

//           </Form.Group>

//           <Form.Group controlId="formTentativeMonth">
//             <Form.Label>Tentative Month</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter tentative month"
//               name="tentativeMonth"
//               value={formData.tentativeMonth}
//               onChange={handleChange}
//             //   required
//             isInvalid={!!errors.tentativeMonth}
//             />
//             <Form.Control.Feedback type='invalid'>{errors.tentativeMonth}</Form.Control.Feedback>

//           </Form.Group>
//           <Button variant='primary' type='submit' className='gift-form-submit-btn'>
//               Submit
//             </Button>
//         </Form>
//         )}
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default BookNowMobile;

import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';

const BookNowMobile = ({ show, handleClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  // const handleChange = e => {
  //   const { name, value } = e.target;
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  //   setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  // };

  // const handleWheel = () => {
  //   if (document.activeElement.type === 'number' && document.activeElement.classList.contains('noscroll')) {
  //     document.activeElement.blur();
  //   }
  // };

  // document.addEventListener('wheel', handleWheel);

  // const validateForm = () => {
  //   let formErrors = {};
  //   const phoneRegex = /^[0-9]{10}$/;
  //   const numericRegex = /^[0-9]*$/;
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!formData.name) formErrors.name = 'Name is required';
  //   if (!formData.companyName) formErrors.companyName = 'Company name is required';
  //   if (!formData.designation) formErrors.designation = 'Designation is required';
  //   if (!formData.email) {
  //     formErrors.email = 'Email is required';
  //   } else if (!emailRegex.test(formData.email)) {
  //     formErrors.email = 'Invalid email format';
  //   }
  //   if (!formData.phone) {
  //     formErrors.phone = 'Phone number is required';
  //   } else if (!phoneRegex.test(formData.phone)) {
  //     if (!numericRegex.test(formData.phone)) {
  //       formErrors.phone = 'Phone number must contain only numeric values';
  //     } else {
  //       formErrors.phone = 'Phone number must be exactly 10 digits';
  //     }
  //   }
  //   if (!formData.tentativePeople) {
  //     formErrors.tentativePeople = 'Tentative People is required';
  //   } else if (formData.tentativePeople <= 0) {
  //     formErrors.tentativePeople = 'Tentative People must be greater than zero';
  //   }
  //   if (!formData.tentativeMonth) formErrors.tentativeMonth = 'Tentative Month is required';

  //   setErrors(formErrors);
  //   return Object.keys(formErrors).length === 0;
  // };

  const onSubmit = data => {
    const templateParams = {
      from_name: data.name,
      name: data.name,
      companyName: data.companyName,
      designation: data.designation,
      email: data.email,
      phone: data.phone,
      tentativePeople: data.tentativePeople,
      tentativeMonth: data.tentativeMonth,
    };

    // console.log('Sending email with params:', templateParams); // Check data

    emailjs
      .send('service_p62d03w', 'template_mvz94wh', templateParams, 'lv1IeTKgYIwTGD7A5')
      .then(response => {
        console.log('Email sent successfully!', response.status, response.text);
        setIsSubmitted(true);
        reset();
        setTimeout(() => {
          setIsSubmitted(false);
          handleClose();
        }, 3000);
      })
      .catch(error => {
        console.error('Failed to send email:', error);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} className='book-now-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Inquire Now</Modal.Title>
      </Modal.Header>
      <Modal.Body className='book-now-body'>
        {isSubmitted ? (
          <div className='text-center'>
            <h5>Thank you. Someone from the team will connect with you the details shortly.</h5>
          </div>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)} className='book-now-form'>
            <Form.Group controlId='formName'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                // placeholder='Enter your name'
                {...register('name', { required: 'Name is required' })}
                isInvalid={!!errors.name}
                //   required
              />
              <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formCompanyName'>
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type='text'
                // placeholder='Enter your company name'
                {...register('companyName', { required: 'Company name is required' })}
                isInvalid={!!errors.companyName}
              />
              <Form.Control.Feedback type='invalid'>{errors.companyName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formDesignation'>
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type='text'
                // placeholder='Enter your designation'
                {...register('designation', { required: 'Designation is required' })}
                isInvalid={!!errors.designation}
              />
              <Form.Control.Feedback type='invalid'>{errors.designation}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formEmail'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                // placeholder='Enter your email'
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Invalid email format',
                  },
                })}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formPhone'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='tel'
                // placeholder='Enter your phone number'
                {...register('phone', {
                  required: 'Phone number is required',
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: 'Phone number must be exactly 10 digits',
                  },
                })}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formTentativePeople'>
              <Form.Label>Tentative People</Form.Label>
              <Form.Control
                type='number'
                className=' noscroll'
                // placeholder='Enter number of people'
                {...register('tentativePeople', {
                  required: 'Tentative People is required',
                  min: { value: 1, message: 'Must be greater than zero' },
                })}
                isInvalid={!!errors.tentativePeople}
              />
              <Form.Control.Feedback type='invalid'>{errors.tentativePeople}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formTentativeMonth'>
              <Form.Label>Tentative Month</Form.Label>
              <Form.Control
                type='text'
                // placeholder='Enter tentative month'
                {...register('tentativeMonth', { required: 'Tentative Month is required' })}
                isInvalid={!!errors.tentativeMonth}
              />
              <Form.Control.Feedback type='invalid'>{errors.tentativeMonth}</Form.Control.Feedback>
            </Form.Group>
            <Button variant='primary' type='submit' className='book-now-submit-btn'>
              Submit
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default BookNowMobile;
