import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "react-phone-input-2/lib/style.css";
import { Link, useLocation } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import heroImgUrl from "./desktop-view/desktop-assets/images/privacy.jpg";
import Footer from "./mobile-view/homepage/footer";
import mobHeroImg from './desktop-view/desktop-assets/images/privacy-mobile.jpg';


function DesktopTermsAndConditions() {
  return (
    <div>
      <div className="terms-and-condition-img">
        <Image src={window.innerWidth < 1200 ? mobHeroImg : heroImgUrl} />
        <div className="terms-and-condition-overlay-text">
          Terms and Conditions
        </div>
      </div>
      <div className="terms-and-condition-description">
        <div>
          <h1>
            This is a Glamping site located in a rural area and not a hotel.
            Please treat this like a home rather than a hotel.
          </h1>
          <ul>
            <li>
              Strictly NO eating inside the pool and any tents or bathrooms.
            </li>
            <li>
              {" "}
              Please keep all valuables safe and secured. Management is not
              responsible for any theft.
            </li>
            <li>
              No firecrackers or color inside the tents is allowed or in the
              campsite.
            </li>
            <li>
              Strictly NO Drugs or any illegal substance allowed in or around
              the campsite. Guests will be reported to the local police station
              and will be asked to vacate the location immediately without any
              refunds.
            </li>
            <li>
              Any damage to property intentionally or unintentionally will
              result in forfeiture of the full security deposit.
            </li>
            <li>
              The final tent rate will be as determined by the stay for the
              period of the stay, at the time of booking.
            </li>
            <li>
              If the guests need early Check-In or late check out, it must be
              upon written approval of the Reservation Team. Day-use stay and
              half day room service is not available
            </li>
            <li>
              No date changes or refunds will be entertained for any stays
              during the festivals like Christmas (22nd December – 25th
              December) and New Years (26th December -1 January) periods.
            </li>
            <li>
              All valid refund requests will be processed via Bank transfer or
              Gpay.
            </li>
            <li>
              Any last minute cancellation due to medical issues please mail us
              a doctor certificate or report.
            </li>
          </ul>
        </div>

        <div>
          <h2>Cancellation Policy</h2>
          <h1 className="pt-3">
            Reservation cancelled 15 days prior to check-in date will incur a
            40% cancellation charge.
          </h1>
          <ul>
            <li>
              {" "}
              Reservation cancelled 8-14 days or prior to check-in date will
              incur a 60% cancellation charge.
            </li>
            <li>
              Reservation cancelled 4 days or prior to check-in date will incur
              100% cancellation charge.
            </li>
            <li>
              No date changed or refunds will be entertained for any
              cancellations with 3 days of the scheduled check-in date,
            </li>
          </ul>
        </div>
        <div>
          <h2>Disclaimer</h2>
          <ul>
            <li>
              Please note that this is Glamping site. There are animals, insects
              and bugs in the area. Though the property is kept very clean but
              guests must carry mosquito repellent etc. Also there is
              fluctuation of power in this area though there is a generator back
              up.
            </li>
            <li>
              Do not use glass near the pool area; you can request the staff for
              plastic glasses.
            </li>
            <li>
              We have a full-generator back up but it takes 5-10 minutes to
              switch it on manually, so don’t panic if the lights go out, it is
              common in this area.
            </li>
            <li>
              Please switch off lights, geysers and air-conditioning if you are
              leaving your room. Moonstone Hammock takes a lot of time and
              energy to maintain and it would greatly be appreciated if you can
              help us.
            </li>
            <li>
              This is an area with water scarcity please help conserve water by
              using water efficiently during your stay here.
            </li>
            <li>
              The Buddha cafe area, Band area, Dining area and the swimming pool
              are common for all guests staying on the property. Guests are
              requested to cooperate with each other.
            </li>

            <li>
              {" "}
              All breakages and damages will be billed, and late check out is
              chargeable.
            </li>
            <li>
              Guests are free to get their own alcohol, as long as they have a
              permit.
            </li>
            <li>Please don’t create nuisance caused due to loud music</li>
            <li>
              {" "}
              Please strive to preserve the cleanliness of the property. Holi
              colors are not allowed in the property.
            </li>
            <li>
              The staff will not be available post 11 PM at night for any
              services.
            </li>
          </ul>
        </div>
      </div>

    
    </div>

    
  );
}

export default DesktopTermsAndConditions;
