import { Image, Spinner } from 'react-bootstrap';
import { UserContext } from '../../UserContext';
import { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBlogsData, fetchMediaData } from '../../apiUtils';
import calenderImg from '../desktop-assets/images/calender@2x.png';
import AuthorLogo from '../desktop-assets/images/author@2x.png';

function DesktopBlogsDetailsView() {
  const { desktopBlogsData, setDesktopBlogsData } = useContext(UserContext);
  const { cardName } = useParams();
  const [heroImg, setHeroImg] = useState(null);
  const [socialMediaIcon, setSocialMediaIcon] = useState([]);

  const { currentBlog, setCurrentBlog } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (desktopBlogsData && desktopBlogsData.length > 0) {
      handleDataFromContext();
    } else {
      fetchData();
    }
  }, [cardName, desktopBlogsData]);

  const handleDataFromContext = async () => {
    setLoading(true);
    const currentBlogDetails = desktopBlogsData.find(blog => blog.id === cardName);

    if (currentBlogDetails) {
      const blogsDetailsData = currentBlogDetails.blog_details;
      setCurrentBlog(blogsDetailsData);

      if (blogsDetailsData) {
        const heroImgId = blogsDetailsData.hero_image;
        const iconImageIds = blogsDetailsData.date_and_time
          ? blogsDetailsData.date_and_time.map(item => item.icon).filter(Boolean)
          : [];

        const socialMediaIconsData = blogsDetailsData.social_media?.social_media_icons || [];
        const socialMediaIds = socialMediaIconsData.map(item => item.icon).filter(Boolean);

        const allImageIds = [heroImgId, ...iconImageIds, ...socialMediaIds].filter(Boolean);

        if (allImageIds.length > 0) {
          try {
            const mediaData = await fetchMediaData(allImageIds);

            const heroMedia = mediaData.find(media => media.id === heroImgId);
            if (heroMedia) {
              setHeroImg(heroMedia.source_url);
            }

            const socialMediaIconUrls = socialMediaIconsData
              .map(item => {
                const media = mediaData.find(media => media.id === item.icon);
                return media ? { url: media.source_url, link: item.link.url } : null;
              })
              .filter(Boolean);
            // console.log('Social Media Icon URLs:', socialMediaIconUrls);
            setSocialMediaIcon(socialMediaIconUrls);
          } catch (error) {
            console.error('Error fetching media data:', error);
          }
        }
      } else {
        console.error('No blog details found for the specified cardName');
      }
    } else {
      console.error('Blog not found');
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const fetchedData = await fetchBlogsData();
      const formattedData = Object.keys(fetchedData)
        .filter(key => key.startsWith('card_'))
        .map(key => ({ ...fetchedData[key], id: key }));

      setDesktopBlogsData(formattedData);

      const currentBlogDetails = formattedData.find(blog => blog.id === cardName);

      if (currentBlogDetails) {
        const blogsDetailsData = currentBlogDetails.blog_details;
        setCurrentBlog(blogsDetailsData);

        if (blogsDetailsData) {
          const heroImgId = blogsDetailsData.hero_image;
          const iconImageIds = blogsDetailsData.date_and_time
            ? blogsDetailsData.date_and_time.map(item => item.icon).filter(Boolean)
            : [];

          const socialMediaIconsData = blogsDetailsData.social_media?.social_media_icons || [];
          const socialMediaIds = socialMediaIconsData.map(item => item.icon).filter(Boolean);

          const allImageIds = [heroImgId, ...socialMediaIds].filter(Boolean);

          if (allImageIds.length > 0) {
            try {
              const mediaData = await fetchMediaData(allImageIds);

              const heroMedia = mediaData.find(media => media.id === heroImgId);
              if (heroMedia) {
                setHeroImg(heroMedia.source_url);
              }

              const socialMediaIconUrls = socialMediaIconsData
                .map(item => {
                  const media = mediaData.find(media => media.id === item.icon);
                  return media ? { url: media.source_url, link: item.link.url } : null;
                })
                .filter(Boolean);
              setSocialMediaIcon(socialMediaIconUrls);
            } catch (error) {
              console.error('Error fetching media data:', error);
            }
          }
        } else {
          console.error('No blog details found for the specified cardName');
        }
      } else {
        console.error('Blog not found');
      }
    } catch (error) {
      console.error('Error fetching blogs data:', error);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className='loading-container d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading</span>
        </Spinner>
      </div>
    );
  }

  if (!currentBlog || !heroImg) return null;

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <div className='desktop-blogs-details-view'>
      {heroImg && <Image src={heroImg} />}

      <div>
        {currentBlog && (
          <div className='d-flex justify-content-between desktop-blogs-details-desc pl-120 pr-120'>
            <div>
              <span>{currentBlog.title}</span>
              <div className='d-flex'>
                {/* <div className='d-flex align-items-center mt-3'>
                  <Image src={calenderImg} />
                  <h4>
                    {currentBlog.date_and_time ? `${currentBlog.date_and_time[0].text}` : 'Date and Time not available'}
                  </h4>
                </div> */}
                {/* <div className='d-flex align-items-center mt-3 ms-5'>
                  <Image src={AuthorLogo} />
                  <h4>{currentBlog.date_and_time ? `${currentBlog.date_and_time[1].text}` : 'Author not available'}</h4>
                </div> */}
              </div>
            </div>

            <div className='d-flex desktop-blogs-details-social-media-logos justify-content-center align-items-center'>
              <p>{currentBlog.social_media?.text}</p>
              {socialMediaIcon.map((icon, index) => (
                <Image
                  key={index}
                  src={icon.url}
                  onClick={() => openInNewTab(icon.link)}
                  style={{ cursor: 'pointer' }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className='desktop-blogs-details-hr-line'></div>
    </div>
  );
}

export default DesktopBlogsDetailsView;
