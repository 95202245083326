import React from "react";
import "../style/events.scss";
import Dropdown from "react-bootstrap/Dropdown";
import HeroImg from "../assets/images/mobile-our-story-hero.png";
import { Col, Image, Row, Spinner } from "react-bootstrap";
import { UserContext } from "../../UserContext";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { fetchOurStoryData, fetchSingleMedia } from "../../apiUtils";

function OurStoryView() {
  const { ourStoryData, setOurStoryData } = useContext(UserContext);
  const [heroImg, setHeroImg] = useState([]);
  const[loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchLogoData = async () => {
      try {
        const pageResponse = await fetchOurStoryData();

        setOurStoryData(pageResponse);

        const heroImgId = pageResponse.mobile_hero_image;
        // console.log(heroImgId);

        if (heroImgId) {
          const mediaResponse = await fetchSingleMedia(heroImgId);

          const fullImageUrl = mediaResponse.media_details?.sizes?.full?.source_url;

          setHeroImg(fullImageUrl);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchLogoData();
  }, [setOurStoryData]);

  if (loading) {
    return(
      <div className="loading-container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading</span>
        </Spinner>
      </div>
    );
  }

   if (!ourStoryData || !heroImg) {
    return null;
  }

  return (
    <div className="our-story-section">
      <div>{heroImg && <Image src={heroImg} />}</div>

      <div className="our-story-desc">
        <p>
          {ourStoryData?.craft_your_dream_experience?.title_1 }
        </p>
        <p>
          {ourStoryData?.craft_your_dream_experience?.title_2 }
        </p>
        <p>
          {ourStoryData?.craft_your_dream_experience?.description }
        </p>
      </div>
    </div>
  );
}

export default OurStoryView;
