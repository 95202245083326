import React from "react";
import { Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";

function MobileAllFAQ() {
  const { homeData } = useContext(UserContext);

  return (
    <div className="faq-section mt-5">
      <div className="faq-title">
        <span>{homeData?.faq?.faqs_title}</span>
      </div>
      <div>
        <Accordion defaultActiveKey="0" className="custom-accordion">
          {homeData?.faq?.faqs?.map((faq, index) => (
            <React.Fragment key={index}>
              <Accordion.Item eventKey={index.toString()}>
                <Accordion.Header>{faq.faq_}</Accordion.Header>
                <Accordion.Body>{faq.faq_description}</Accordion.Body>
              </Accordion.Item>
              {index < homeData.faq.faqs.length - 1 && (
                <hr className="hr-line" />
              )}
            </React.Fragment>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default MobileAllFAQ;
