// import React from 'react';
// import '../style/events.scss';
// import { Col, Image, Row, Card, Container } from 'react-bootstrap';
// import DotImage from '../assets/images/point.svg';
// import { useEffect, useRef, useState } from 'react';

// function OurStoryHistoryTimeline() {
// const events = [
//   { year: '2016', description: ['Begins', 'Karjat'] },
//   { year: '2017', description: ['Floating Tentts'] },
//   { year: '2018', description: ['365 days Glamping experience'] },
//   { year: '2019', description: ['Forest Cabins'] },
//   {
//     year: '2020',
//     description: ['Hunters Nest', 'Top Glamping sites in India by TOI'],
//   },
//   { year: '2021', description: ['Alibaug'] },
//   { year: '2022', description: ['Buddha cafe became the must visit restaurant in Alibaug', 'Jibhi'] },
//   // { year: "2023", description: ["Lorem Ipsum Sit Amet"] },
//   { year: '2024', description: ['Bengaluru & Outdoor adventure partner with Decathlon'] },
// ];

import React from 'react';
import '../style/events.scss';
import { Col, Image, Row, Card, Container } from 'react-bootstrap';
import DotImage from '../assets/images/point.svg';
import { useEffect, useRef, useState } from 'react';

function OurStoryHistoryTimeline() {
  const events = [
    {
      year: '2016',
      description: [
        { text: 'Begins', className: 'class-begins' },
        { text: 'Karjat', className: 'class-karjat' },
      ],
    },
    {
      year: '2017',
      description: [{ text: 'Floating Tentts', className: 'class-floating-tents' }],
    },
    {
      year: '2018',
      description: [{ text: '365 days Glamping experience', className: 'class-glamping' }],
    },
    {
      year: '2019',
      description: [{ text: 'Forest Cabins', className: 'class-forest-cabins' }],
    },
    {
      year: '2020',
      description: [
        { text: 'Hunters Nest', className: 'class-hunters-nest' },
        { text: 'Top Glamping sites in India by TOI', className: 'class-glamping-sites' },
      ],
    },
    {
      year: '2021',
      description: [{ text: 'Alibaug', className: 'class-alibaug' }],
    },
    {
      year: '2022',
      description: [
        { text: 'Buddha cafe became the must visit restaurant in Alibaug', className: 'class-buddha-cafe' },
        { text: 'Jibhi', className: 'class-jibhi' },
      ],
    },
    {
      year: '2024',
      description: [
        { text: 'Bengaluru', className: 'class-jibhi' },
        { text: 'Outdoor adventure partner with Decathlon', className: 'class-buddha-cafe' },
      ],
    },
  ];

  const cardRefs = useRef([]);
  const [lineHeights, setLineHeights] = useState([]);

  useEffect(() => {
    const heights = cardRefs.current.map((card, index) => {
      if (card && index < cardRefs.current.length - 1) {
        return card.offsetHeight + 20;
      }
      return 0;
    });
    setLineHeights(heights);
  }, []);
  return (
    <div className='our-story-history-timeline'>
      <div className='history-timeline-title'>
        <p className='d-flex justify-content-center mb-0'>How Did</p>
        <p className='d-flex justify-content-center'>We Get Here?</p>
      </div>

      <Container className='mob-timeline-container'>
        {events.map((event, index) => (
          <Row key={index} className='mob-timeline-row'>
            <Col xs={2} className='mob-timeline-dot-container pe-0'>
              <img
                src={DotImage}
                alt='Timeline Dot'
                className={`mob-timeline-dot-image ${index === 4 ? 'fifth-child-dot' : ''} ${
                  index === 2 ? 'third-child-dot' : ''
                }  ${index === 8 ? 'last-child-dot' : ''}`}
              />
              {index < events.length && (
                <div className='mob-timeline-line' style={{ height: lineHeights[index] || '70px' }}></div>
              )}
            </Col>
            <Col xs={10} ref={el => (cardRefs.current[index] = el)}>
              <Card className='mob-timeline-card'>
                <Card.Body>
                  <Card.Title>{event.year}</Card.Title>
                  {event.description.map((desc, i) => (
                    <Card.Text key={i}>
                      <ul>
                        {/* <li> {desc}</li> */}
                        <li className={desc.className}>{desc.text}</li>
                      </ul>
                    </Card.Text>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
}

export default OurStoryHistoryTimeline;
