import DesktopFooter from "../desktop-footer/desktop-footer";
import DesktopEventsDetailsView from "./desktop-events-details-view";
import DesktopEventsDetailsGuide from "./desktop-events-details-guide";
import DesktopEventsDetailsGallery from "./desktop-events-details-gallery";
import DesktopEventsDetailsAbout from "./desktop-events-details-about";
import React, { useState, useEffect } from "react";

function DesktopEventsDetails() {
  return (
    <div>
      <DesktopEventsDetailsView />
      <DesktopEventsDetailsGuide />
      <DesktopEventsDetailsGallery />
      <DesktopEventsDetailsAbout />
      {/* <DesktopEventsDetailsVenue /> */}
    </div>
  );
}
export default DesktopEventsDetails;
