import DesktopHomepageView from './desktop-homepage-view';
import DesktopAboutUs from './desktop-about-us';
import DesktopMediaLogos from './desktop-media-logos';
import DesktopFAQ from './desktop-FAQ';
import { Link } from 'react-router-dom';

function DesktopHomepage() {
  return (
    <div>
      <DesktopHomepageView />
      <DesktopAboutUs />
      <DesktopMediaLogos />
      <DesktopFAQ />
    </div>
  );
}

export default DesktopHomepage;
