import React from "react";
import DesktopDestinationDetailsView from "./desktop-destination-details-view";
import DesktopDestinationDetailsAccomodation from "./desktop-destination-details-accomodation";
import DesktopDestinationDetailsItinerary from "./desktop-destination-details-itinerary";
import DesktopDestinationDetailsAmenities from "./desktop-destination-details-amenities";
import DesktopDestinationDetailsLocation from "./desktop-destination-details-location";

function DesktopDestinationDetails() {

  return (
    <div>
      <DesktopDestinationDetailsView />
      <DesktopDestinationDetailsItinerary />
      <DesktopDestinationDetailsAccomodation />
      {/* <DesktopDestinationDetailsAmenities /> */}
      <DesktopDestinationDetailsLocation />
    </div>
  );
}

export default DesktopDestinationDetails;
