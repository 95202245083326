import React, { useContext, useEffect, useState } from 'react';
import '../style/events.scss';
import Card from 'react-bootstrap/Card';
import { Image, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import BlogsCardArrow from '../assets/images/blogs-cards-arrow.png';
import { UserContext } from '../../UserContext';
import { fetchMobileBlogsData, fetchSingleMedia } from '../../apiUtils';

function BlogsView() {
  const { blogsData, setBlogsData, setSelectedBlogs } = useContext(UserContext);
  const [images, setImages] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchLogoData = async () => {
      try {
        const fetchedData = await fetchMobileBlogsData();

        if (fetchedData) {
          setBlogsData(fetchedData);
        } else {
          console.error('No data found in response');
        }

        const mediaIds = Object.keys(fetchedData)
          .filter(key => key.startsWith('card_'))
          .flatMap(key => {
            const card = fetchedData[key]?.card;
            return card ? [card.image, card.icon] : [];
          })
          .filter(id => id);

        if (mediaIds.length > 0) {
          const mediaResponses = await Promise.all(mediaIds.map(id => fetchSingleMedia(id)));

          const fetchedImages = {};
          mediaResponses.forEach(response => {
            if (response) {
              const { id, source_url } = response;
              fetchedImages[id] = source_url;
            }
          });

          setImages(fetchedImages);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchLogoData();
  }, [setBlogsData, setImages]);

  const handleDestinationClick = blog => {
    setSelectedBlogs(blog);
  };

  const getImageUrl = id => images[id] || '';

  if (loading) {
    return (
      <div className='loading-container d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className='blogs-view-section'>
      <div className='blogs-title'>
        <p>{blogsData?.title_1}</p>
        <p>{blogsData?.title_2}</p>
      </div>

      <div className='blogs-cards-section'>
        {blogsData ? (
          Object.keys(blogsData)
            .filter(key => key.startsWith('card_'))
            .map((key, index) => {
              const card = blogsData[key]?.card;

              if (card && !card.image && !card.icon && !card.title && !card.date_and_time && !card.text) {
                return null;
              }

              return (
                <Link
                  to={`/blogs-details/${key}`}
                  className='text-decoration-none'
                  key={index}
                  onClick={() => handleDestinationClick(blogsData[key])}
                >
                  <Card className='blogs-card-hover-effect'>
                    <Card.Img src={getImageUrl(card.image)} alt={`Blog ${index + 1}`} />
                    <Card.Body>
                      <Card.Text className='d-flex'>
                        <div className='blogs-location-box d-flex justify-content-flex'>
                          <p className='mb-0 d-flex justify-content-center'>{card.text}</p>
                        </div>
                        <div className='blogs-calender d-flex align-items-center'>
                          {/* <Image src={getImageUrl(card.icon)} alt='Calendar Icon' /> */}
                          {/* <h4>{card.date_and_time}</h4> */}
                        </div>
                      </Card.Text>
                      <Card.Title>{card.title}</Card.Title>
                      <div className='text-left'>
                        <Button className='blogs-read-more-btn'>
                          <span>Read More</span>
                          <Image src={BlogsCardArrow}></Image>
                        </Button>
                      </div>
                      <Card.Text>
                        <div className='blogs-cards-btn'></div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              );
            })
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default BlogsView;
