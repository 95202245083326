import axios from "axios";

const mediaApiUrl = process.env.REACT_APP_MEDIA_API_URL;
const destinationApiUrl = process.env.REACT_APP_DESTINATION_API_URL;
const headerAndHomePageApiUrl = process.env.REACT_APP_HEADER_PAGE_API_URL;
const blogsViewPageApiUrl = process.env.REACT_APP_DESKTOP_BLOGS_VIEW_PAGE_API_URL;
const mobileEventsPageApiUrl = process.env.REACT_APP_EVENTS_VIEW_PAGE_API_URL;
const ourStoryViewPageApiUrl = process.env.REACT_APP_OUR_STORY_VIEW_PAGE_API_URL;
const mobileBlogsPageApiUrl = process.env.REACT_APP_BLOGS_VIEW_PAGE_API_URL;
const desktopEventsPageApiUrl = process.env.REACT_APP_DESKTOP_EVENTS_PAGE_URL;
const desktopOurStoryViewApiUrl = process.env.REACT_APP_DESKTOP_OUR_STORY_VIEW_API_URL;
const acfMessageApiUrl = process.env.REACT_APP_FOOTER_API_URL;

export const fetchMediaData = async (imageIds) => {
  if (!imageIds || imageIds.length === 0) {
    return []; // Return an empty array if no image IDs are provided
  }

  try {
    const fetchPromises = imageIds.map(id =>
      axios.get(`${mediaApiUrl}${id}`)
    );

    const responses = await Promise.all(fetchPromises);

    return responses.map(response => response.data);
  } catch (error) {
    console.error("Error fetching media data:", error);
    return [];
  }
};


export const fetchDestinationData = async () => {
  try {
    const pageResponse = await axios.get(destinationApiUrl);
    return pageResponse.data.acf;
  } catch (error) {
    console.error("Error fetching page data:", error);
    return {};
  }
};

export const fetchSingleMedia = async (id) => {
  try {
    const mediaResponse = await axios.get(`${mediaApiUrl}${id}`);
    return mediaResponse.data;
  } catch (error) {
    console.error("Error fetching single media:", error);
    return null;
  }
};

export const fetchHomePageData = async () => {
  try {
    const pageResponse = await axios.get(headerAndHomePageApiUrl);
    return pageResponse.data.acf;
  } catch (error) {
    console.error("Error fetching homepage data:", error);
    return {};
  }
};

export const fetchBlogsData = async () => {
  try {
    const pageResponse = await axios.get(blogsViewPageApiUrl);
    return pageResponse.data.acf;
  } catch (error) {
    console.error("Error fetching blogs data:", error);
    return {};
  }
};

export const fetchMobileEventsData = async () => {
  try {
    const response = await axios.get(mobileEventsPageApiUrl);
    return response.data.acf;
  } catch (error) {
    console.error("Error fetching mobile events data:", error);
    throw error;
  }
};


export const fetchOurStoryData = async () => {
    try {
      const response = await axios.get(ourStoryViewPageApiUrl);
      return response.data.acf;
    } catch (error) {
      console.error("Error fetching our story data:", error);
      return {};
    }
  };


  export const fetchMobileBlogsData = async () => {
    try {
      const response = await axios.get(mobileBlogsPageApiUrl);
      return response.data.acf;
    } catch (error) {
      console.error("Error fetching mobile blogs data:", error);
      throw error;
    }
  };

  export const fetchdesktopOurStoryView = async () => {
    try {
      const response = await axios.get(desktopOurStoryViewApiUrl);
      return response.data.acf;
    } catch (error) {
      console.error('error fetching desktop our story view data:', error);
      throw error;
      
    }
  }

  export const fetchDesktopEventData = async () => {
    try {
      const response = await axios.get(desktopEventsPageApiUrl);
      // console.log("API response:", response.data);
      return response.data.acf;
    } catch (error) {
      console.error("Error fetching event data:", error);
      throw error;
    }
  };
  

  export const fetchAcfMessageData = async () => {
    try {
      const response = await axios.get(acfMessageApiUrl);
      return response.data.acf;
    } catch (error) {
      console.error("Error fetching ACF message data:", error);
      return {};
    }
  };