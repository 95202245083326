import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Button } from "react-bootstrap";
import { UserContext } from "../../UserContext";
import { useContext } from "react";
import { useParams } from "react-router-dom";

function DesktopDestinationDetailsLocation() {
  const { desktopDestinationDetailsData } = useContext(UserContext);
  const { destination } = useParams();

  const selectedDestination = desktopDestinationDetailsData?.[destination]?.[0] || null;

  const mapLocation = selectedDestination?.map_location;
  const embedMapLocation = selectedDestination?.embed_map_location;
  const locationTitle = selectedDestination?.location_title;

  return (
    <div className="desktop-destination-details-location pl-120 pr-120">
      <h1>Location</h1>
      {selectedDestination ? (
        <div className="desktop-destination-details-location-desc d-flex justify-content-between">
          <p>{locationTitle}</p>
          <div>
            <a href={mapLocation} target="_blank" rel="noopener noreferrer">
              <Button className="desktop-get-direction-btn">
                <span>Get Direction</span>
              </Button>
            </a>
          </div>
        </div>
      ) : (
        <p>Destination not found</p>
      )}
      {embedMapLocation && (
        <div className="desktop-destination-details-location-img">
          <Container className="gx-0 mt-3" fluid>
            <iframe
              title="Google Map"
              width="100%"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              src={embedMapLocation}
              allowFullScreen
            ></iframe>
          </Container>
        </div>
      )}
    </div>
  );
}

export default DesktopDestinationDetailsLocation;
