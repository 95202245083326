import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Col, Image, Row, Spinner } from "react-bootstrap";
import { motion } from "framer-motion";
import { UserContext } from "../../UserContext";
import { fetchMediaData, fetchDestinationData } from "../../apiUtils";

function DesktopDestinationView() {
  const {
    desktopDestinationData,
    setDesktopDestinationData,
    setDesktopSelectedDestination,
  } = useContext(UserContext);

  const [images, setImages] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedData = await fetchDestinationData();

        if (fetchedData) {
          setDesktopDestinationData(fetchedData);
        } else {
          console.error("No data found in response");
          return;
        }

        const destinations = Object.keys(fetchedData).filter(
          (key) => key !== "title_1" && key !== "title_2"
        );

        const bannerImageIds = destinations
        .map((destination) => fetchedData[destination]?.[0]?.banner_image)
        .filter((id) => id);
      
      // console.log(bannerImageIds);
      
      const mediaData = await fetchMediaData(bannerImageIds);

        const fetchedImages = {};
        destinations.forEach((destination, index) => {
          fetchedImages[destination] = mediaData.find(
            (media) => media.id === bannerImageIds[index]
          )?.source_url;
        });

        setImages(fetchedImages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [setDesktopDestinationData]);

  const handleDestinationClick = (destination) => {
    setDesktopSelectedDestination(destination);
  };

  if (loading) {
    return (
      <div
        className="loading-container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

   if (
    !desktopDestinationData ||
    Object.keys(images).length === 0 ||
    !Object.keys(desktopDestinationData).some(
      (key) => key !== "title_1" && key !== "title_2"
    )
  ) {
    return null; 
  }

  return (
    <div className="desktop-destination-view-section">
      <div className="desktop-view-title">
        <p className="d-flex justify-content-center mb-0">
          {desktopDestinationData?.title_1}
        </p>
        <p className="d-flex justify-content-center mb-0">
          {desktopDestinationData?.title_2}
        </p>
      </div>

      <Row className="desktop-destination-container">
        {/* {Object.keys(images).map((destination) => ( */}
        {Object.keys(desktopDestinationData).map((key) => {
          const destinationData = desktopDestinationData[key];
          // console.log(destinationData)

          if (destinationData && destinationData.length > 0 && destinationData[0].title) {
            const title = destinationData[0].title; 

            return (
          <Col
            key={key}
            lg={3}
            md={6}
            sm={12}
            className="desktop-destination-img"
          >
            <motion.div
              key={key}
              className="desktop-image-container"
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
            >
              <Link
                to={`/destination-details/${key}`}
                onClick={() => handleDestinationClick(key)}
              >
                {images[key] && <Image src={images[key]} />}
                <div className="desktop-image-overlay"></div>
                <div className="desktop-destination-img-text">
                  {title}
                </div>
              </Link>
            </motion.div>
          </Col>
            );
          }
        })}
      </Row>
    </div>
  );
}

export default DesktopDestinationView;



