import React from "react";
import "../style/events.scss";
import { Image } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { UserContext } from "../../UserContext";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchMediaData } from "../../apiUtils";

function BlogsDetailsRecentSearch() {
  const { blogsData } = useContext(UserContext);
  const { cardName } = useParams();
  const [recentImg, setRecentImg] = useState([]);
  const [recentIcon, setRecentIcon] = useState([]);
  const [recentArticles, setRecentArticles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!blogsData || !cardName) {
          console.error("blogsData or cardName is not available.");
          return;
        }
  
        const blogsDetailsData = blogsData?.[cardName]?.blog_details;
  
        if (blogsDetailsData) {
          const recentArticlesData = blogsDetailsData?.recent_articles?.camping_places || [];
          setRecentArticles(recentArticlesData);
  
          const imageIds = recentArticlesData
            .map((item) => item.images)
            .filter(Boolean)
            .flat();
  
          const iconIds = recentArticlesData
            .map((item) => item.icon)
            .filter(Boolean);
  
          const allImageIds = [...imageIds, ...iconIds].filter(Boolean);
  
          if (allImageIds.length > 0) {
            const mediaData = await fetchMediaData(allImageIds);
  
            const imagesUrls = imageIds
              .map((id) => {
                const media = mediaData.find((media) => media.id === id);
                return media ? media.source_url : null;
              })
              .filter(Boolean);
  
            setRecentImg(imagesUrls);
  
            const iconUrls = iconIds
              .map((id) => {
                const media = mediaData.find((media) => media.id === id);
                return media ? media.source_url : null;
              })
              .filter(Boolean);
  
            setRecentIcon(iconUrls);
          } else {
            console.error("No image or icon IDs found.");
          }
        } else {
          console.error("No blog details found for the specified cardName");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [blogsData, cardName]);
  
  const hasData = recentArticles.length > 0 || recentImg.length > 0 || recentIcon.length > 0;

  return (
    <div className="blogs-details-section">

      <div className="blogs-details-recent-search pt-6">
        <InputGroup className="mb-3 custom-input-group">
          <Form.Control
            placeholder="Search…"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2 "
          />
          <InputGroup.Text id="basic-addon2 d-flex justify-content-center align-items-center">
            <div class="blogs-details-icon-container d-flex justify-content-center align-items-center">
              <svg class="blogs-details-search-icon" viewBox="0 0 24 24">
                <circle
                  cx="11"
                  cy="11"
                  r="7"
                  stroke="white"
                  stroke-width="2"
                  fill="none"
                ></circle>
                <line
                  x1="16.65"
                  y1="16.65"
                  x2="21"
                  y2="21"
                  stroke="white"
                  stroke-width="2"
                ></line>
              </svg>
            </div>
          </InputGroup.Text>
        </InputGroup>

        <div className="blogs-details-recent-articles">
          <h1>Recent Articles</h1>
          {recentArticles.map((article, index) => (
            <div key={index} className="d-flex pb-3">
              <Image src={recentImg[index]} className="recent-article-img" />
              <div>
                {/* <div className="d-flex mt-1">
                  
                    <Image src={recentIcon[index]} height={18} width={18} />
                  <p>{article.date_and_time}</p>
                </div> */}
                <h2>{article.text}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    // )
  );
}

export default BlogsDetailsRecentSearch;
