import React from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { useState, useContext, useEffect } from "react";
import { fetchMediaData } from "../../apiUtils";

function Gallery() {
  const { eventsData } = useContext(UserContext);
  const { cardName } = useParams();
  const [eventsGallery, setEventsGallery] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!eventsData || !cardName) {
          // console.error("eventsData or cardName is not available.");
          return;
        }
  
        const eventDetailsData = eventsData?.[cardName]?.events_details_;
  
        if (eventDetailsData) {
          const galleryImgIds = eventDetailsData.gallery?.images
            .map((content) => content.image)
            .filter(Boolean);
          // console.log("galleryImgIds:", galleryImgIds);
  
          if (galleryImgIds.length > 0) {
            // console.log("allImageIds:", galleryImgIds);
  
            const mediaData = await fetchMediaData(galleryImgIds);
            // console.log("mediaData:", mediaData);
  
            const guideMedia = eventDetailsData?.gallery?.images
              ?.map((content) => {
                const media = mediaData.find(
                  (media) => media.id === content.image
                );
                return {
                  src: media ? media.source_url : null,
                };
              })
              .filter((media) => media.src); 
  
            setEventsGallery(guideMedia || []);
          } else {
            console.error("No image IDs found.");
          }
        } else {
          console.error("No event details found for the specified cardName");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [eventsData, cardName]);

   const hasData = eventsGallery.length > 0 || eventsData?.[cardName]?.events_details_?.gallery?.title;
   if (!hasData) {
     return null;
   }
  
  return (
    <div className="gallery-section">
      <span>
        {eventsData?.card_1?.events_details_?.gallery?.title }
      </span>
      <div className="gallery-grid">
        {eventsGallery.length > 0 ? (
          eventsGallery.map((item, index) => (
            <img
              key={index}
              src={item.src}
              alt={`Gallery Image ${index + 1}`}
            />
          ))
        ):(
          <p>No Image available</p>
        )}
      </div>
    </div>
  );
}

export default Gallery;
