import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Image, Modal } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import GradientRightArrow from '../desktop-assets/images/gradient-right-arrow.svg';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import { fetchMediaData } from '../../apiUtils';
import { useEffect, useContext, useState } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import LightArrowLeft from '../desktop-assets/images/arrow-left copy.svg';
import LightArrowRight from '../desktop-assets/images/arrow-right copy.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import GradientBtn from '../gradient-btn';
import { Row, Col } from 'react-bootstrap';

function DesktopDestinationDetailsAccomodation() {
  const { desktopDestinationDetailsData } = useContext(UserContext);
  const { destination } = useParams();
  const [accommodationData, setAccommodationData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchAccommodationData = async () => {
      try {
        if (!desktopDestinationDetailsData || !destination) {
          return;
        }

        const destinationDetails = desktopDestinationDetailsData[destination]?.[0]?.destination_details;

        if (!destinationDetails || !destinationDetails.desktop_accommodation_options) {
          console.error('Invalid destination details structure.');
          return;
        }

        const cardsImages = destinationDetails.desktop_accommodation_options;

        const imageIds = [...new Set(cardsImages.map(item => item.image).filter(Boolean))];

        if (imageIds.length > 0) {
          const mediaDataArray = await fetchMediaData(imageIds);

          const mediaDataMap = mediaDataArray.reduce((acc, media) => {
            if (media && media.description) {
              const fullImageUrl = media.media_details.sizes?.full?.source_url;
              acc[media.id] = fullImageUrl || null;
            }
            return acc;
          }, {});

          const accommodations = cardsImages
            .map(item => ({
              ...item,
              imageUrl: mediaDataMap[item.image] || null,
            }))
            .filter(img => img.imageUrl);
          // }));

          setAccommodationData(accommodations.filter(item => item.imageUrl));
        } else {
          console.error('No image IDs found.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAccommodationData();
  }, [desktopDestinationDetailsData, destination]);

  const handleClick = url => {
    window.open(url);
  };

  const handleShowModal = images => {
    const imageUrls = images.map(img => img.imageUrl);
    setModalImages(imageUrls);
    setCurrentIndex(0);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSelect = selectedIndex => setCurrentIndex(selectedIndex);

  if (accommodationData.length === 0) {
    return null;
  }
  return (
    <div className='desktop-destination-details-accomodation pl-120 pr-120 '>
      <span>Accommodation Options</span>

      <div className='desktop-destination-details-card-section'>
        <Row>
          {accommodationData.map((item, index) => (
            <Col key={index} xs={12} sm={6} md={6} lg={4} className='mb-4'>
              <Card>
                <Card.Img src={item.imageUrl} onClick={() => handleClick(item.book_now_link)} />
                <Card.Body>
                  <Card.Title>{item.card_title}</Card.Title>
                  <Card.Text className='d-flex justify-content-between align-items-center'>
                    <div className='pt-2'>
                      <span>{item.card_text_1}</span>
                      <span>{item.card_text_2}</span>
                      <span>{item.card_text_3}</span>
                    </div>
                    <a className='text-decoration-none' onClick={() => handleClick(item.book_now_link)}>
                      <GradientBtn
                        className='desktop-header-btn desktop-accomodation-btn ms-0 mt-3 d-block'
                        text='Book Now'
                      />
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default DesktopDestinationDetailsAccomodation;
