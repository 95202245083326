import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row } from "react-bootstrap";
import { UserContext } from "../../UserContext";
import { useContext } from "react";

function DesktopAboutUs() {
  const { desktopHomeData } = useContext(UserContext);

  return (
    <div className="desktop-about-us-section">
      <Row className="gx-0">
        <Col lg={3} md={3} sm={3} className="desktop-know-about-us-text">
          <span>
            {desktopHomeData?.about_us_?.title_1 }
          </span>
          <span>
            {desktopHomeData?.about_us_?.title_2 }
          </span>
        </Col>
        <Col lg={9} md={9} sm={9} className="desktop-know-about-us-para">
          <span>
            {desktopHomeData?.about_us_?.description }
          </span>
                  </Col>
      </Row>
    </div>
  );
}

export default DesktopAboutUs;
