import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Card, Image, Spinner } from 'react-bootstrap';
import { UserContext } from '../../UserContext';
import DesktopCalender from '../desktop-assets/images/Group 36997 (1).svg';
import { fetchDesktopEventData, fetchMediaData } from '../../apiUtils';
import { Col, Row } from 'react-bootstrap';

const cardVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

const chunkArray = (array, size) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
};

function DesktopCorporateEvents() {
  const { setDesktopEventDetailsView } = useContext(UserContext);
  const { desktopEvent, setDesktopEvent } = useContext(UserContext);
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchEventData = async () => {
      try {
        const pageResponse = await fetchDesktopEventData();
        const pageData = pageResponse;

        if (pageData) {
          setDesktopEvent(pageData);
        } else {
          console.error('No data found in response');
        }
      } catch (error) {
        console.error('Error fetching page data:', error);
      }
      setLoading(false);
    };

    fetchEventData();
  }, [setDesktopEvent]);

  useEffect(() => {}, [desktopEvent]);

  useEffect(() => {
    if (!desktopEvent) return;

    const fetchImages = async () => {
      try {
        const eventDetails = Object.keys(desktopEvent).filter(key => key.startsWith('card_'));
        const cardImageIds = eventDetails.map(
          eventDetail => desktopEvent[eventDetail]?.corporate_events_card?.banner_image
        );
        const mediaResponses = await fetchMediaData(cardImageIds);

        const fetchedImages = {};
        mediaResponses.forEach((response, index) => {
          const eventKey = eventDetails[index];
          if (eventKey && response && response) {
            fetchedImages[eventKey] = response.source_url;
          }
        });
        setImages(fetchedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [desktopEvent]);

  const handleApplyClick = eventDetail => {
    const event = desktopEvent[eventDetail]?.corporate_events_card;
    if (event) {
      setDesktopEventDetailsView(event);
    }
  };

  if (!desktopEvent || !images) {
    return;
  }

  const eventDetails = Object.keys(desktopEvent).filter(key => key.startsWith('card_'));
  const chunkedEventDetails = chunkArray(eventDetails, 3);

  if (loading) {
    return (
      <div className='loading-container d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading</span>
        </Spinner>
      </div>
    );
  }

  if (!desktopEvent || !images || Object.keys(images).length === 0) {
    return null;
  }

  return (
    <div className='desktop-corporate-events pl-120 pr-120'>
      <motion.div
        className='corporate-event-title'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      ></motion.div>

      {chunkedEventDetails.map((row, rowIndex) => (
        <motion.div
          className='desktop-events-cards-section'
          initial='hidden'
          animate='visible'
          variants={cardVariants}
          key={rowIndex}
        >
          <Row className='g-4'>
            {row.map(eventDetail => {
              const event = desktopEvent[eventDetail]?.corporate_events_card;
              const imageUrl = images[eventDetail];

              if (!event || !imageUrl) {
                return null;
              }
              return (
                <Col xs={12} md={6} lg={4} key={eventDetail}>
                  <Link
                    to={`/events-details/${eventDetail}`}
                    className='text-decoration-none'
                    onClick={() => handleApplyClick(eventDetail)}
                  >
                    <motion.div className='desktop-card-hover-effect'>
                      <Card>
                        <Card.Img className='cardevent-img' src={imageUrl} />
                        <Card.Body>
                          <Card.Text className='d-flex'>
                            <div className='desktop-calender d-flex align-items-center'>
                              {/* <Image src={DesktopCalender} /> */}
                              {/* <h4>{event.time_and_date}</h4> */}
                            </div>
                          </Card.Text>
                          <Card.Title>{event.titile}</Card.Title>

                          <Card.Text>
                            <div className='desktop-fees-section'>
                              <span>{event.fees}</span>
                              <span>{event.price}</span>
                              <span>{event.onwards}</span>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </motion.div>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </motion.div>
      ))}
    </div>
  );
}
export default DesktopCorporateEvents;
