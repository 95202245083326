import React from "react";
import "../style/events.scss";
import EventView from "./events-view";
import CorporateEvents from "./corporate-events";

function EventPage() {
  return (
    <div>
      <EventView />
      <CorporateEvents />
    </div>
  );
}

export default EventPage;
