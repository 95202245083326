import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Image, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { fetchSingleMedia } from "../../apiUtils";

function DesktopOriginStory() {
  const { desktopOurStory } = useContext(UserContext);
  const [bannerImageUrl, setBannerImageUrl] = useState("");

  useEffect(() => {
    if (desktopOurStory) {
      const fetchBannerImageUrl = async () => {
        try {
          const { banner_image } =
            desktopOurStory.our_journey_began_with_a__chance_encounter;

          const imageResponse = await fetchSingleMedia(banner_image);

          setBannerImageUrl(imageResponse.source_url);
        } catch (error) {
          console.error("errro fetching banner image url:", error);
        }
      };

      fetchBannerImageUrl();
    }
  }, [desktopOurStory]);

  if (!desktopOurStory) {
    return null;
  }

  const { our_journey_began_with_a__chance_encounter } = desktopOurStory;

  const isDataEmpty = !our_journey_began_with_a__chance_encounter || 
  !our_journey_began_with_a__chance_encounter?.title_1 &&
  !our_journey_began_with_a__chance_encounter?.title_2 &&
  !our_journey_began_with_a__chance_encounter?.description;

  if (isDataEmpty) {
    return null;
  }

  return (
    <div className="desktop-origin-story-section">
      <Row className="d-flex justify-content-center align-items-center gx-0">
        <Col
          lg={6}
          md={5}
          sm={5}
          className="text-center "
          style={{ marginTop: "120px" }}
        >
          {bannerImageUrl && <Image src={bannerImageUrl} />}
        </Col>
        <Col lg={6} md={7} sm={7} className="text-content">
          <p>{our_journey_began_with_a__chance_encounter.title_1}</p>
          <p>{our_journey_began_with_a__chance_encounter.title_2}</p>
          <p>{our_journey_began_with_a__chance_encounter.description}</p>
        </Col>
      </Row>
    </div>
  );
}

export default DesktopOriginStory;
