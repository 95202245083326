import React from "react";
import "../style/events.scss";
import { Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { useContext } from "react";
import axios from "axios";
import { fetchOurStoryData, fetchSingleMedia } from "../../apiUtils";


function OurStoryOrigin() {
  const { ourStoryData } = useContext(UserContext);
  const [originStoryImg, setOriginStoryImg] = useState(null);

  useEffect(() => {
    const fetchLogoData = async () => {
      if (!ourStoryData) return;

      try {

        const ImgId =
          ourStoryData.our_journey_began_with_a__chance_encounter?.banner_image;

        if (ImgId) {
          const mediaResponse = await fetchSingleMedia(ImgId);

          const descriptionHtml = mediaResponse?.description?.rendered || "";

          const parser = new DOMParser();
          const doc = parser.parseFromString(descriptionHtml, "text/html");
          const imgElement = doc.querySelector("img");
          const imgSrc = imgElement ? imgElement.src : null;

          setOriginStoryImg(imgSrc);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLogoData();
  }, [ourStoryData]);

   if (!ourStoryData || !originStoryImg) {
    return null; 
  }

  return (
    <div className="our-story-origin-section">
      <div>{originStoryImg && <Image src={originStoryImg} />}</div>
      <div className="origin-story-title ">
        <p>
          {ourStoryData?.our_journey_began_with_a__chance_encounter?.title_1}
        </p>
        <p>
          {ourStoryData?.our_journey_began_with_a__chance_encounter?.title_2}
        </p>
        <p>
          {ourStoryData?.our_journey_began_with_a__chance_encounter
            ?.description}
        </p>

      </div>
    </div>
  );
}

export default OurStoryOrigin;
