import React from "react";
import DesktopEventsView from "../desktop-events/desktop-events-view";
import DesktopCorporateEvents from "../desktop-events/desktop-corporate-events";

function DesktopEvents() {
  return (
    <div>
      <div style={{ minHeight: "700px" }}>
        <DesktopEventsView />
        <DesktopCorporateEvents />
      </div>
    </div>
  );
}

export default DesktopEvents;
