import DesktopBlogsView from "./desktop-blogs-view";
import React from "react";

function DesktopBlogs() {
  return (
    <div>
      <DesktopBlogsView />
    </div>
  );
}

export default DesktopBlogs;
