import "bootstrap/dist/css/bootstrap.min.css";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import { UserContext } from "../../UserContext";
import { useParams } from "react-router-dom";
import { fetchDesktopEventData } from "../../apiUtils";

function DesktopEventsDetailsAbout() {
  const [desktopEventsAbout, setDesktopEventsABout] = useState(null);
  const { desktopEvent } = useContext(UserContext);
  const { cardName } = useParams();


  useEffect(() => {
    const fetchEventsAboutData = async () => {
      try {
        const apiData = await fetchDesktopEventData();
        // console.log('api data:', apiData);
        
        if (apiData) {
          // console.log('card name:', cardName);
          
          const aboutData = apiData[cardName]?.events_details?.about;
          // console.log(aboutData);
          setDesktopEventsABout(aboutData);
        }
      } catch (error) {
        console.error("error fetching data:", error);

        if (desktopEvent) {
          const aboutData = desktopEvent[cardName]?.events_details?.about;
          setDesktopEventsABout(aboutData)
        }
      }
    };
    fetchEventsAboutData();
  }, [desktopEvent, cardName]);

//   return (
//     <div className="desktop-events-details-about pl-120 pr-120 ">
//       <h1>{desktopEventsAbout.title}</h1>

//       <Row className="gx-0">
//         <Col>
//           <div className="desktop-events-details-about-desc">
//             <p>
//             {desktopEventsAbout.description?.map((item, index) => (
//               <p key={index}>{item.text}</p>
//             ))}
//             </p>
//           </div>

//           <div className="desktop-events-details-pleasant-retreat">
//             <h2>Why Choose Moonstone Hammock for Your Corporate Outing:</h2>
//             <p>
//               Moonstone Hammock offers a unique blend of adventure, relaxation,
//               and skill enhancement in Nature’s tranquil surroundings. Our
//               expertly curated experiences foster team spirit and personal
//               growth, making your corporate outing not just a retreat, but a
//               transformative journey towards enhanced teamwork and productivity.
//             </p>
//             <p>
//               Feel free to connect to make a memorable trip that is fruitful and
//               most of all - FUN and Relaxing
//             </p>
//           </div>
//         </Col>
//         <Col className="desktop-instructions-col">
//           <div className="desktop-exclusions">
//             <h2>Inclusions:</h2>
//             <ul>
//               <li>
//                 <span>Customized Team-Building Activities:</span>
//                 <span>Tailored to enhance collaboration and morale.</span>
//               </li>
//               <li>
//                 <span>Scenic Accommodations: </span>
//                 <span>Hammocks and tents amid nature’s lush greenery.</span>
//               </li>
//               <li>
//                 <span>Selected Dining: </span>
//                 <span>Catered meals showcasing local flavors.</span>
//               </li>
//               <li>
//                 <span>Facilitated Workshops:</span>
//                 <span> Focused on skill enhancement and team cohesion.</span>
//               </li>
//               <li>
//                 <span>Adventure Sports:</span>
//                 <span>
//                   {" "}
//                   Options like zip-lining and nature walks for thrill seekers.
//                 </span>
//               </li>
//             </ul>
//           </div>

//           <div className="desktop-please-note">
//             <h2>Exclusions:</h2>
//             <ul>
//               <li>
//                 <span>Transportation:</span>
//                 <span>
//                   {" "}
//                   Participants must arrange their travel to the venue. (we also
//                   have a vendor onboard, we can share the contact if required)
//                 </span>
//               </li>
//               <li>
//                 <span>Alcoholic Beverages:</span>
//                 <span>
//                   {" "}
//                   (BYOB). Yes we do not mark up any alcohol. You can BYOB,
//                   notice that you carry alcohol and are legally allowed to do
//                   so.
//                 </span>
//               </li>
//             </ul>
//           </div>
//         </Col>
//       </Row>
//     </div>
//   );
// }


if (!desktopEventsAbout) {
  return null; // Render nothing if no data is available
}

return (
  <div className="desktop-events-details-about pl-120 pr-120">
    <h1>{desktopEventsAbout.title }</h1>

    <Row className="gx-0">
      <Col>
        <div className="desktop-events-details-about-desc">
          {desktopEventsAbout.description?.map((item, index) => (
            <p key={index}>{item.text}</p>
          ))}
        </div>

        {/* {desktopEventsAbout.title_1 && (
          <div className="desktop-exclusions">
            <h2>{desktopEventsAbout.title_1.title}</h2>
            <ul>
              {desktopEventsAbout.title_1.points?.map((item, index) => (
                <li key={index}>
                  <span>{item.points}</span>
                </li>
              ))}
            </ul>
          </div>
        )} */}
      </Col>

      <Col className="desktop-instructions-col">
      {desktopEventsAbout.title_1 && (
          <div className="desktop-events-details-Inclusions">
            <h2>{desktopEventsAbout.title_1.title}</h2>
            <ul>
              {desktopEventsAbout.title_1.points?.map((item, index) => (
                <li key={index}>
                  <span>{item.points}</span>
                </li>
              ))}
            </ul>
          </div>
        )}


        {desktopEventsAbout.title_2 && (
          <div className="desktop-events-details-Inclusions">
            <h2>{desktopEventsAbout.title_2.title}</h2>
            <ul>
              {desktopEventsAbout.title_2.points?.map((item, index) => (
                <li key={index}>
                  <span>{item.points}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {desktopEventsAbout.title_3?.points && (
          <div className="desktop-events-details-Inclusions">
            <h2>{desktopEventsAbout.title_3.title}</h2>
            <ul>
              {desktopEventsAbout.title_3.points.map((item, index) => (
                <li key={index}>
                  <span>{item.points}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Col>
    </Row>
  </div>
);
}

export default DesktopEventsDetailsAbout;