import React, { useContext } from "react";
import { Button, Container } from "react-bootstrap";
import { UserContext } from "../../UserContext";
import { useParams } from "react-router-dom";

function Location() {
  const { destinationData } = useContext(UserContext);
  const { destination } = useParams();

  const selectedDestination = destinationData?.[destination]?.[0] || null;
  const mapLocation = selectedDestination?.map_location;
  const embedMapLocation = selectedDestination?.embed_map_location;
  const locationTitle = selectedDestination?.location_title;

  if (!selectedDestination || (!mapLocation && !embedMapLocation)) {
    return null;
  }

  return (
    <div className="location-section">
      <span>Location</span>
      {selectedDestination ? (
        <div>
          <p>{locationTitle}</p>
          {embedMapLocation && (
            <Container className="gx-0" fluid>
              <iframe
                title="Google Map"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                src={embedMapLocation}
                allowFullScreen
              ></iframe>
            </Container>
          )}
          {mapLocation && (
            <div className="text-center mt-3">
              <a href={mapLocation} target="_blank" rel="noopener noreferrer">
                <Button className="get-direction-btn">
                  <span>Get Directions</span>
                </Button>
              </a>
            </div>
          )}
        </div>
      ) : (
        <p>Destination not found</p>
      )}
    </div>
  );
}

export default Location;


