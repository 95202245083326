import React from "react";
import { Button } from "react-bootstrap";

function GradientBtn({ className, text }) {
  return (
    <Button type="submit" className={className}>
      <span className="p-0">{text}</span>
    </Button>
  );
}

export default GradientBtn;
