import React from "react";
import HomeView from "./homepage-view";
import AboutUs from "./about-us";
import FAQ from "./FAQ-section";

function HomePage() {
  return (
    <div>
      <HomeView />
      <AboutUs />
      <FAQ />
    </div>
  );
}

export default HomePage;
