import React, { useEffect, useState, useContext } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import Shape from '../assets/images/shape.png';
import HeaderBtn from '../assets/images/Rectangle.svg';
import { fetchHomePageData, fetchSingleMedia } from '../../apiUtils';

function Header() {
  const location = useLocation();
  const [showBgShape, setShowBgShape] = useState(true);
  const [logoUrl, setLogoUrl] = useState(null);
  const { homeData, setHomeData } = useContext(UserContext);

  const pathsWithoutBgShape = [
    '/destination',
    '/blogs',
    '/events',
    '/destination-details',
    '/events-details',
    '/blogs-details',
  ];

  useEffect(() => {
    const pathMatch = pathsWithoutBgShape.some(path => matchPath({ path, end: false }, location.pathname));
    setShowBgShape(!pathMatch);
  }, [location.pathname]);

  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const homedata = await fetchHomePageData();
        setHomeData(homedata);

        const logoId = homedata.logo;
        const logoData = await fetchSingleMedia(logoId);

        setLogoUrl(logoData?.source_url || null);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchLogoData();
  }, [setHomeData]);

  return (
    <div>
      <header>
        <Row className='gx-0'>
          <Col className='header-logo d-flex justify-content-start'>
            <Link to='/home'>
              <Image src={Shape} className='logo-bg-shape' />
              {logoUrl && <Image src={logoUrl} className={`logo-img ${showBgShape ? 'mob-top-8' : 'mob-top-5'}`} />}
            </Link>
          </Col>

          <Col className='header-btn d-flex justify-content-end'>
            <a className='d-flex align-items-center text-decoration-none'>
              <div className='btn-text'>
                <Image src={HeaderBtn} className='header-book-now-button' />
                <p className='mb-0 header-book-now-button'>Book Now</p>
              </div>
            </a>
          </Col>
        </Row>
      </header>
    </div>
  );
}

export default Header;
