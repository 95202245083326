import 'bootstrap/dist/css/bootstrap.min.css';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Calender from '../desktop-assets/images/Group 36997 (1).svg';
import GradientBtn from '../gradient-btn';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../UserContext';
import { Spinner } from 'react-bootstrap';
import { fetchDesktopEventData, fetchSingleMedia } from '../../apiUtils';
import BookNowForm from './book-now-form';

const containerVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const itemVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

function DesktopEventsDetailsView() {
  const { cardName } = useParams();
  const [imageUrl, setImageUrl] = useState(null);
  const [heroSection, setHeroSection] = useState(null);
  const { desktopEvent, setDesktopEvent } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [showBookNowForm, setShowBookNowForm] = useState(false);

  const handleShowBookNowForm = () => setShowBookNowForm(true);
  const handleCloseBookNowForm = () => setShowBookNowForm(false);

  useEffect(() => {
    const fetchDeskEventData = async () => {
      setLoading(true);
      try {
        if (desktopEvent && desktopEvent[cardName]) {
          const eventDetails = desktopEvent[cardName]?.events_details;
          if (eventDetails) {
            setHeroSection(eventDetails);
            const imageID = eventDetails.hero_section?.image;
            if (imageID) {
              try {
                const deskEventmediaResponse = await fetchSingleMedia(imageID);

                const fullImageUrl = deskEventmediaResponse.media_details?.sizes?.full?.source_url;

                setImageUrl(fullImageUrl);
              } catch (error) {
                console.error('Error fetching media:', error);
              }
            }
            setLoading(false);
            return;
          }
        }

        const deskEventResponse = await fetchDesktopEventData();
        const eventDetails = deskEventResponse[cardName]?.events_details;
        if (eventDetails) {
          setHeroSection(eventDetails);
          setDesktopEvent(prev => ({
            ...prev,
            [cardName]: { events_details: eventDetails },
          }));

          const imageID = eventDetails.hero_section?.image;
          if (imageID) {
            try {
              const deskEventmediaResponse = await fetchSingleMedia(imageID);

              const fullImageUrl = deskEventmediaResponse.media_details?.sizes?.full?.source_url;

              setImageUrl(fullImageUrl);
            } catch (error) {
              console.error('Error fetching media:', error);
            }
          }
        } else {
          console.log('No event details found for the given cardId.');
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDeskEventData();
  }, [cardName, desktopEvent, setDesktopEvent]);

  if (loading) {
    return (
      <div className='loading-container d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    );
  }

  // if (!heroSection || !heroSection.hero_section) {
  //   return null;
  // }

  return (
    <div className='desktop-events-details-view '>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
        {imageUrl && <img src={imageUrl} alt='Event' />}
      </motion.div>

      <motion.div
        className='desktop-events-details-desc pl-120 pr-120 '
        variants={containerVariants}
        initial='hidden'
        animate='visible'
      >
        <div className='d-flex justify-content-between '>
          <div className='d-flex'>
            <span>{heroSection.hero_section?.title}</span>
          </div>

          <div className='d-flex'>
            <motion.div className='d-flex align-items-center mt-3 ' variants={itemVariants}>
              {/* <Image src={Calender} /> */}
              {/* <h4>{heroSection.hero_section?.date_and_time}</h4> */}
            </motion.div>
          </div>
          {/* <motion.div
            className="d-flex align-items-center mt-3 ms-5"
            variants={itemVariants}
          > */}
          {/* <Image src={Location} />
            <h4>{heroSection.hero_section?.location_text || 'Loacation not available'}</h4> */}
          {/* </motion.div> */}
        </div>
        <motion.div
          className='desktop-events-details-fees-section d-flex justify-content-between'
          variants={containerVariants}
        >
          <div>
            <span>Fees</span>
            <span>{heroSection.hero_section?.price}</span>
            <span>Onwards</span>
          </div>
          {cardName === 'card_6' ? (
            <a
              href='https://insider.in/alibaugs-first-oktober-fest-oct26-2024/event'
              target='_blank'
              rel='noopener noreferrer'
              className='d-flex align-items-center text-decoration-none'
            >
              <div className='d-flex align-items-center'>
                <GradientBtn text='Book Now' className='desktop-events-details-book-now-btn' />
              </div>
            </a>
          ) : (
            <a onClick={handleShowBookNowForm} className='d-flex align-items-center text-decoration-none'>
              <div className='d-flex align-items-center'>
                <GradientBtn text='Inquire Now' className='desktop-events-details-book-now-btn' />
              </div>
            </a>
          )}
          <BookNowForm show={showBookNowForm} handleClose={handleCloseBookNowForm} />
        </motion.div>
      </motion.div>
    </div>
  );
}

export default DesktopEventsDetailsView;
