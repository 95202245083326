import "bootstrap/dist/css/bootstrap.min.css";
import { Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardsMail from "../desktop-assets/images/dark-mail.svg";
import CardsLinkedin from "../desktop-assets/images/linkedin (1).svg";
import knowMoreArrow from "../desktop-assets/images/blue-arrow.svg";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { fetchMediaData } from "../../apiUtils";
import OurTeamImg from '../desktop-assets/images/MSH team.jpg';


function DesktopOurTeam() {
  const [teamData, setTeamData] = useState(null);
  const [teamImages, setTeamImages] = useState({});
  const { desktopOurStory } = useContext(UserContext);

  useEffect(() => {
    if (desktopOurStory) {
      const fetchTeamImageUrl = async () => {
        try {
          const teamData = desktopOurStory.know__our_team;

          const imageIds = teamData.team_card.map(card => card.image).filter(Boolean);

          const fetchedImages = await fetchMediaData(imageIds);

          const images = fetchedImages.reduce((acc, img) => {
            acc[img.id] = img.source_url;
            return acc;
          }, {});

          setTeamData(teamData);
          setTeamImages(images);
        } catch (error) {
          console.error('Error fetching team images:', error);
        }
      };

      fetchTeamImageUrl();
    }
  }, [desktopOurStory]);


  if (!desktopOurStory || !teamData) {
    return <div>Loading...</div>;
  }

  // if (!desktopOurStory?.know__our_team?.team_card?.length) {
  //   return null; 
  // }


  return (

    <div className="desktop-our-team-section">
      <div className="desktop-our-team-title ">
        <p>{teamData.title_1}</p>
        <p>{teamData.title_2}</p>
      </div>
      <div className="desktop-our-team-cards d-flex pl-120 pr-120">
        <Image src={OurTeamImg}/>
        {/* {teamData.team_card.map((teamMember, index) => (
          <Card style={{ width: "19rem" }} className="me-4" key={index}>
            <Card.Img
              variant="top"
              src={teamImages[teamMember.image]}
              className="desktop-passport-photo" />
            <Card.Body>
              <Card.Title>{teamMember.name}</Card.Title>
              <div className="desktop-our-team-card-text-images">
                <h2>{teamMember.post}</h2>

                <div className="desktop-our-team-card-image">
                  <Image src={CardsMail} alt="email Image 1" />
                  <Image src={CardsLinkedin} alt="linkedin Image 1" className="p-0" />
                </div>
              </div>
              <div className="desktop-our-team-cards-hr-line"></div>
              <div className="desktop-our-team-cards-btn">
                <span>{teamMember.know_more}</span>
                <Image src={knowMoreArrow}></Image>
              </div>
            </Card.Body>
          </Card>
        ))} */}
      </div >
    </div>
  );
}

export default DesktopOurTeam;

