import React from 'react';
import { Image, Spinner } from 'react-bootstrap';
import ArrowBtn from '../homepage/btn';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import { useState, useContext, useEffect } from 'react';
import { fetchMobileEventsData, fetchMediaData } from '../../apiUtils';
import BookNowMobile from './book-now';

function EventsDetailsView() {
  const { eventsData, setEventsData } = useContext(UserContext);
  const [heroImg, setHeroImg] = useState(null);
  const [iconImages, setIconImages] = useState([]);
  const { cardName } = useParams();
  const [loading, setLoading] = useState(true);
  const [showBookNowForm, setShowBookNowForm] = useState(false);

  const handleShowBookNowForm = () => setShowBookNowForm(true);
  const handleCloseBookNowForm = () => setShowBookNowForm(false);

  useEffect(() => {
    if (eventsData && eventsData.length > 0) {
      handleDataFromContext();
    } else {
      fetchData();
    }
  }, [cardName]);

  const handleDataFromContext = async () => {
    setLoading(true);

    try {
      const eventDetailsData = eventsData?.[cardName]?.events_details_;

      if (eventDetailsData) {
        const heroImgId = eventDetailsData.hero_section?.image;
        const iconImageIds = [eventDetailsData.hero_section?.icon, eventDetailsData.hero_section?.icon_2].filter(
          Boolean
        );

        const allImageIds = [heroImgId, ...iconImageIds].filter(Boolean);

        if (allImageIds.length > 0) {
          const mediaData = await fetchMediaData(allImageIds);

          const heroMedia = mediaData.find(media => media.id === heroImgId);
          if (heroMedia) {
            setHeroImg(heroMedia.source_url);
          }

          const iconImagesUrls = iconImageIds
            .map(id => {
              const media = mediaData.find(media => media.id === id);
              return media ? media.source_url : null;
            })
            .filter(Boolean);

          setIconImages(iconImagesUrls);
        }
      } else {
        console.error('No event details found for the specified cardName');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const fetchedData = await fetchMobileEventsData();
      setEventsData(fetchedData);

      const eventDetailsData = fetchedData?.[cardName]?.events_details_;

      if (eventDetailsData) {
        const heroImgId = eventDetailsData.hero_section?.image;
        const iconImageIds = [eventDetailsData.hero_section?.icon, eventDetailsData.hero_section?.icon_2].filter(
          Boolean
        );

        const allImageIds = [heroImgId, ...iconImageIds].filter(Boolean);

        if (allImageIds.length > 0) {
          const mediaData = await fetchMediaData(allImageIds);

          const heroMedia = mediaData.find(media => media.id === heroImgId);
          if (heroMedia) {
            setHeroImg(heroMedia.source_url);
          }

          const iconImagesUrls = iconImageIds
            .map(id => {
              const media = mediaData.find(media => media.id === id);
              return media ? media.source_url : null;
            })
            .filter(Boolean);

          setIconImages(iconImagesUrls);
        }
      } else {
        console.error('No event details found for the specified cardName');
      }
    } catch (error) {
      console.error('Error fetching page data:', error);
    } finally {
      setLoading(false);
    }
  };

  const eventDetails = eventsData?.[cardName]?.events_details_;
  const heroSection = eventDetails?.hero_section;

  if (loading) {
    return (
      <div className='loading-container d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading</span>
        </Spinner>
      </div>
    );
  }

  if (loading || !heroSection || !heroImg || iconImages.length === 0) {
    return null;
  }

  return (
    <div>
      <div className='events-details-section'>
        {heroImg && <Image src={heroImg} />}

        <div className='details-desc'>
          <p>{heroSection?.title}</p>

          <div className='d-flex align-items-center mt-3'>
            {/* {iconImages && <Image src={iconImages} />} */}
            {/* <h4 className='mt-1'>{heroSection?.date_and_time}</h4> */}
          </div>

          <div className='fees-section d-flex justify-content-between'>
            <div>
              <span>Fees </span>
              <span> {heroSection?.price}</span>
              <span>{heroSection?.text}</span>
            </div>
            {cardName === 'card_6' ? (
              <a
                href='https://insider.in/alibaugs-first-oktober-fest-oct26-2024/event'
                target='_blank'
                rel='noopener noreferrer'
                className='d-flex align-items-center text-decoration-none'
              >
                <div className='d-flex align-items-center'>
                  <ArrowBtn text='Book Now' className='book-now-btn' />
                </div>
              </a>
            ) : (
              <a
                // href='https://www.secure-booking-engine.com/accounts/9kDOsNdFsEDmbUZgpvacvg/properties/fl7QAdZBBDMGAm8wZdDwmA/booking-engine/web/source/4wsctBw6Oq6j-g9XuxeRzQ/'
                // target='_blank'
                // rel='noopener noreferrer'
                onClick={handleShowBookNowForm}
                className='d-flex align-items-center text-decoration-none'
              >
                <div className='d-flex align-items-center'>
                  <ArrowBtn text='Inquire Now' className='book-now-btn' />
                </div>
              </a>
            )}
            <BookNowMobile show={showBookNowForm} handleClose={handleCloseBookNowForm} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsDetailsView;
