import React, { useEffect, useState, useContext } from "react";
import { Image, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { UserContext } from "../../UserContext";
import { fetchDestinationData, fetchMediaData } from "../../apiUtils";

function DestinationView() {
  const { destinationData, setDestinationData, setSelectedDestination } = useContext(UserContext);
  const [images, setImages] = useState({});
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const fetchedData = await fetchDestinationData();

        if (fetchedData) {
          setDestinationData(fetchedData);
        } else {
          console.error("No data found in response");
        }

        const destinations = Object.keys(fetchedData).filter(
          (key) => key !== "title_1" && key !== "title_2"
        );

        const bannerImageIds = destinations
          .map((destination) => fetchedData[destination]?.[0]?.mobile_banner_img)
          .filter((id) => id);

        const mediaData = await fetchMediaData(bannerImageIds);

        const fetchedImages = {};
        bannerImageIds.forEach((id) => {
          const media = mediaData.find((media) => media.id === id);
          if (media) {
            const destination = destinations.find((dest) => fetchedData[dest]?.[0]?.mobile_banner_img === id);
            fetchedImages[destination] = media.source_url;
          }
        });

        setImages(fetchedImages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [setDestinationData]);

  const handleDestinationClick = (destination) => {
    setSelectedDestination(destination);
  };


  if (loading) {
    return (
      <div className="loading-container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading</span>
        </Spinner>
      </div>
    );
  }

  const hasData = destinationData && (destinationData.title_1 || destinationData.title_2 || Object.keys(images).length > 0);

  if (!hasData) {
    return null;
  }

  return (
    <div>
      <div>
        <motion.div
          className="destination-title"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <motion.span
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2 }}
          >
            {destinationData?.title_1}
          </motion.span>
          {" "}
          <motion.span
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.4 }}
          >
            {destinationData?.title_2}
          </motion.span>
        </motion.div>
      </div>
      <div className="destination-view">
        {Object.keys(images).map((destination) => (
          <motion.div
            key={destination}
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Link to={`/destination-details/${destination}`}>
              <div
                className="destination-images"
                onClick={() => handleDestinationClick(destination)}
              >
                <div className="image-overlay"></div>
                {images[destination] && <Image src={images[destination]} />}
                <div className="image-text">
                  {destinationData?.[destination]?.[0]?.title}
                </div>
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default DestinationView;
