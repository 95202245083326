import DesktopBlogsDetailsView from "../desktop-blogs-details/desktop-blogs-details-view";
import DesktopBlogsDetailsDesc from "../desktop-blogs-details/desktop-blogs-details-desc";
import React from "react";

function DesktopBlogsDetails() {

  return (
    <div>
      <DesktopBlogsDetailsView />
      <DesktopBlogsDetailsDesc />
    </div>
  );
}

export default DesktopBlogsDetails;
