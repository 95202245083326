import React, { useEffect, useState } from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import GradientBtn from '../gradient-btn';
import { Link, useLocation } from 'react-router-dom';
import { fetchHomePageData, fetchSingleMedia } from '../../apiUtils';
import DesktopShape from '../desktop-assets/images/shape.png';

function DesktopHeader() {
  const location = useLocation();
  const [showBgShape, setShowBgShape] = useState(true);
  const [activeItem, setActiveItem] = useState('home');
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    const pathsWithoutBgShape = [
      '/destination',
      '/blogs',
      '/events',
      '/destination-details',
      '/events-details',
      '/blogs-details',
    ];

    setShowBgShape(!pathsWithoutBgShape.includes(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath === '/' || currentPath === '/home') {
      setActiveItem('home');
    } else if (currentPath.startsWith('/destination')) {
      setActiveItem('destination');
    } else if (currentPath.startsWith('/events')) {
      setActiveItem('events');
    } else if (currentPath.startsWith('/blogs')) {
      setActiveItem('blogs');
    } else if (currentPath === '/our-story') {
      setActiveItem('our-story');
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const homedata = await fetchHomePageData();
        const logoId = homedata.logo;
        const mediaData = await fetchSingleMedia(logoId);
        const parser = new DOMParser();
        const doc = parser.parseFromString(mediaData.description.rendered, 'text/html');
        const imgElement = doc.querySelector('img');
        const logoUrl = imgElement ? imgElement.src : null;

        setLogoUrl(logoUrl);
      } catch (error) {
        console.error('Error fetching logo data:', error);
      }
    };

    fetchLogoData();
  }, []);

  return (
    <div>
      <Row className='gx-0 desktop-header sticky-header'>
        <Col lg={3} md={4} sm={4} className='desktop-header-logo'>
          <Link to='/'>
            {logoUrl && (
              <>
                <Image src={logoUrl} className='desktop-logo-img' />
                <Image src={DesktopShape} className='desktop-logo-shape' />
              </>
            )}
          </Link>
        </Col>

        <Col lg={6} md={4} sm={4} className='desktop-header-content'>
          <Link
            to='/'
            className={`text-decoration-none ${activeItem === 'home' ? 'active-link' : ''}`}
            onClick={() => setActiveItem('home')}
          >
            <p>Home</p>
          </Link>
          <Link
            to='/destination'
            className={`text-decoration-none ${activeItem === 'destination' ? 'active-link' : ''}`}
            onClick={() => setActiveItem('destination')}
          >
            <p>Destination</p>
          </Link>
          <Link
            to='/events'
            className={`text-decoration-none ${activeItem === 'events' ? 'active-link' : ''}`}
            onClick={() => setActiveItem('events')}
          >
            <p>Events</p>
          </Link>
          <Link
            to='/blogs'
            className={`text-decoration-none ${activeItem === 'blogs' ? 'active-link' : ''}`}
            onClick={() => setActiveItem('blogs')}
          >
            <p>Blogs</p>
          </Link>
          <Link
            to='/our-story'
            className={`text-decoration-none ${activeItem === 'our-story' ? 'active-link' : ''}`}
            onClick={() => setActiveItem('our-story')}
          >
            <p>Our Story</p>
          </Link>
        </Col>
        <Col lg={3} md={4} sm={4} className='d-flex justify-content-end'>
          {/* <a className=" text-decoration-none">
            <GradientBtn className="desktop-header-btn" text="Book Now" />
          </a> */}
        </Col>
      </Row>
    </div>
  );
}

export default DesktopHeader;
