import "bootstrap/dist/css/bootstrap.min.css";
import { Image, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import BlogsCardArrow from "../desktop-assets/images/blogs-cards-arrow.png"
import { UserContext } from "../../UserContext";
import React, { useContext, useEffect, useState } from "react";
import { fetchBlogsData, fetchMediaData } from "../../apiUtils";

function DesktopBlogsView() {
  const {
    desktopBlogsData,
    setDesktopBlogsData,
    setDesktopSelectedBlogs,
  } = useContext(UserContext);
  const [images, setImages] = useState({});
  const [title1, setTitle1] = useState();
  const [title2, setTitle2] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchLogoData = async () => {
      try {
        const fetchedData = await fetchBlogsData();

        if (fetchedData) {
          setTitle1(fetchedData.title_1);
          setTitle2(fetchedData.title_2);

          const blogsData = Object.keys(fetchedData)
            .filter((key) => key.startsWith("card_"))
            .map((key) => ({
              ...fetchedData[key].card_,
              blog_details: fetchedData[key].blog_details,
              id: key,
            }))


          setDesktopBlogsData(blogsData);

          const mediaIds = blogsData.flatMap((card) => [card.image, card.icon]).filter((id) => id);

          if (mediaIds.length > 0) {
            const fetchedImages = await fetchMediaData(mediaIds);
            const imagesMap = fetchedImages.reduce((acc, img) => {
              acc[img.id] = img.source_url;
              return acc;
            }, {});
            setImages(imagesMap);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchLogoData();
  }, [setDesktopBlogsData]);


  const handleBlogClick = (blog) => {
    setDesktopSelectedBlogs(blog);
  };


  if (loading) {
    return (
      <div className="loading-container d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <div className="desktop-blogs-section">
      <div className="desktop-blogs-title">
        <p className="d-flex justify-content-center mb-0">{title1}</p>
        <p className="d-flex justify-content-center mb-0">{title2}</p>
      </div>

      <div className="desktop-blogs-cards-section">
        {desktopBlogsData && desktopBlogsData.length > 0 ? (

          desktopBlogsData.map((card, index) => {
            if (
              card &&
              !card.image &&
              !card.icon &&
              !card.title &&
              !card.date_and_time &&
              !card.text
            ) {
              return null;
            }
            return (
              <Link
                to={`/blogs-details/${card.id}`}
                className="text-decoration-none"
                key={index}
                onClick={() => handleBlogClick(card)}
              >
                <Card className="desktop-blogs-card-hover-effect">
                  <Card.Img src={images[card.image]} />
                  <Card.Body>
                    <Card.Text className="d-flex">
                      <div className="desktop-blogs-location-box d-flex justify-content-flex">
                        <p className="mb-0 d-flex justify-content-center">
                          {card.text}
                        </p>
                      </div>
                      {/* <div className="desktop-blogs-calender d-flex align-items-center">
                        <Image src={images[card.icon]} />
                        <h4>{card.date_and_time}</h4>
                      </div> */}
                    </Card.Text>
                    <Card.Title>{card.title}</Card.Title>
                    <div className="text-left">
                      <Button className="desktop-blogs-read-more-btn">
                        <span>Read More</span>
                        <Image src={BlogsCardArrow} />
                      </Button>
                    </div>
                    <Card.Text>
                      <div className="desktop-blogs-cards-btn"></div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            );
          })
        ) : (
          <p className="d-flex justify-content-center">No blogs available</p>
        )}
      </div>
    </div>
  );
}

export default DesktopBlogsView;
