import React from "react";
import DestinationView from "./destination-view";


function DestinationPage() {
  return (
    <div>
      <DestinationView />
    </div>
  );
}

export default DestinationPage;
