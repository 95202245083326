import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";
import ItineraryPoint from "../desktop-assets/images/point (1).svg";
import { UserContext } from "../../UserContext";
import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";

function DesktopDestinationDetailsItinerary() {
  const { desktopDestinationDetailsData } = useContext(UserContext);
  const { destination } = useParams();
  const [itineraryTitle, setItineraryTitle] = useState("");
  const [itineraryData, setItineraryData] = useState([]);

  useEffect(() => {
    const fetchAccommodationData = async () => {

      try {
        if (!desktopDestinationDetailsData || !destination) {
          // console.error("Invalid destinationData or selectedDestination.");
          return;
        }

        const destinationDetails =
          desktopDestinationDetailsData[destination]?.[0]?.destination_details;

        if (!destinationDetails || !destinationDetails.itinerary) {
          console.error("Invalid destination details structure.");
          return;
        }

        const itinerary = destinationDetails.itinerary;
        setItineraryTitle(itinerary.title);
        setItineraryData(itinerary.options);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAccommodationData();
  }, [desktopDestinationDetailsData, destination]);


  if (!itineraryTitle || itineraryData.length === 0) {
    return null;
  }

  const firstHalf = itineraryData.slice(0, 4);
  const secondHalf = itineraryData.slice(4);

  return (
    <div className="desktop-itinerary-section ">
      <Row className="desktop-itinerary-desc pl-120 d-flex  gx-0">
        <Col
          lg={4}
          className="desktop-itinerary-title  d-flex justify-content-start align-items-center"
        >
          <h1>{itineraryTitle}</h1>
        </Col>
        <Col
          lg={4}
          className=" d-flex justify-content-start align-items-center"
        >
          <Container className="timeline-container">
            {firstHalf.map((itinerary, idx) => (
              <Row className="timeline-row gx-0" key={idx}>
                <Col xs={1} className="timeline-icon">
                  <img
                    src={ItineraryPoint}
                    alt="icon"
                    className="timeline-image"
                  />
                </Col>
                <Col xs={11} className="timeline-content">
                  <h5>{itinerary.title}</h5>
                  <p>
                    {itinerary.time} <span>{itinerary.details}</span>
                  </p>
                </Col>
              </Row>
            ))}
          </Container>
        </Col>
        <Col
          lg={4}
          className="desktop-itinerary-content  d-flex justify-content-start align-items-start "
        >
          <Container className="timeline-container">
            {secondHalf.map((itinerary, idx) => (
              <Row className="timeline-row gx-0" key={idx}>
                <Col xs={1} className="timeline-icon">
                  <img
                    src={ItineraryPoint}
                    alt="icon"
                    className="timeline-image"
                  />
                </Col>
                <Col xs={11} className="timeline-content">
                  <h5>{itinerary.title}</h5>
                  <p>
                    {itinerary.time} <span>{itinerary.details}</span>
                  </p>
                </Col>
              </Row>
            ))}
          </Container>
        </Col>
      </Row>
    </div>
  );
}

export default DesktopDestinationDetailsItinerary;
