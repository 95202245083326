import React from "react";
import "../style/events.scss";
import BlogsView from "./blogs-view";

function Blogs() {
  return (
    <div>
      <BlogsView />
    </div>
  );
}

export default Blogs;
