import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DesktopOurStoryView from "./desktop-our-story-view";
import DesktopHistoryTimeline from "../desktop-our-story/desktop-history-timeline";
import DesktopOriginStory from "../desktop-our-story/desktop-origin-story";
import DesktopOurTeam from "./desktop-our-team";
import DesktopJoinDream from "./desktop-join-dream";
import React, { useState, useEffect } from "react";

function DesktopOurStory() {
  return (
    <div>
      <DesktopOurStoryView />
      <DesktopHistoryTimeline />
      <DesktopOriginStory />
      <DesktopOurTeam />
      <DesktopJoinDream />
    </div>
  );
}

export default DesktopOurStory;
