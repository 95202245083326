import "bootstrap/dist/css/bootstrap.min.css";
import DesktopDestinationView from "./desktop-destination-view";

function DesktopDestination() {

  return (
    <div>
      <DesktopDestinationView />
    </div>
  );
}

export default DesktopDestination;
