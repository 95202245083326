import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { UserContext } from '../../UserContext';
import { useContext, useEffect } from 'react';
import { fetchSingleMedia } from '../../apiUtils';

function OurStoryJoinDream() {
  const { ourStoryData, setOurStoryData } = useContext(UserContext);
  const [joinDreamImg, setJoinDreamImg] = useState([]);
  useEffect(() => {
    const fetchLogoData = async () => {
      if (!ourStoryData) return;

      try {
        const logoId = ourStoryData.join_the__dream?.banner_image;

        if (logoId) {
          const mediaResponse = await fetchSingleMedia(logoId);

          const descriptionHtml = mediaResponse?.description?.rendered || '';

          const parser = new DOMParser();
          const doc = parser.parseFromString(descriptionHtml, 'text/html');
          const imgElement = doc.querySelector('img');
          const imgSrc = imgElement ? imgElement.src : null;

          setJoinDreamImg(imgSrc);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchLogoData();
  }, [ourStoryData]);

  return (
    <div className='our-story-join-dream'>
      {joinDreamImg && <Image src={joinDreamImg} />}
      <div className='join-dream-img-overlay'></div>
      <div className='join-dream-img-text'>
        <p> {ourStoryData?.join_the__dream?.title_1}</p>
        <p>{ourStoryData?.join_the__dream?.title_2}</p>
        <p>
          {ourStoryData?.join_the__dream?.description}

          <span>
            <a href={`mailto:${ourStoryData?.join_the__dream?.email}`} className='email-link'>
              {ourStoryData?.join_the__dream?.email}
            </a>
          </span>
        </p>
      </div>
    </div>
  );
}

export default OurStoryJoinDream;
