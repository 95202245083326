import React from 'react';
import DetailsView from './details-view';
import DetailsAccomodation from './details-accomodation';
import DetailsItinerary from '../destinations-details/Itinerary';
import Amenities from '../destinations-details/amenities';
import Location from '../destinations-details/location';

function DestinationsDetails() {
  return (
    <div>
      <DetailsView />
      <DetailsItinerary />
      <DetailsAccomodation />
      {/* <Amenities /> */}
      <Location />
    </div>
  );
}

export default DestinationsDetails;
