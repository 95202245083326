import React, { useState, useContext, useEffect, useRef } from "react";
import { Image, Modal, Button, Spinner } from "react-bootstrap";
import TriangleShape from "../assets/images/triangle-shape.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import LeftArrow from "../assets/images/arrow-left.svg";
import RightArrow from "../assets/images/arrow-right.svg";
import { motion } from "framer-motion";
import { UserContext } from "../../UserContext";
import { useParams } from "react-router-dom";
import { fetchDestinationData, fetchMediaData } from "../../apiUtils";

function DetailsView() {
  const { destinationData, setDestinationData } = useContext(UserContext);
  const [heroImg, setHeroImg] = useState(null);
  const [snapImages, setSnapImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const { destination } = useParams();
  const [loading, setLoading] = useState(true);
  const [startTouch, setStartTouch] = useState(null);
  const [endTouch, setEndTouch] = useState(null);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const fetchedData = await fetchDestinationData();
        if (fetchedData) {
          setDestinationData(fetchedData);

          const destinationData =
            fetchedData[destination][0]?.destination_details;

          if (destinationData) {
            const heroImgId = destinationData.hero_section.mobile_banner_image;
            // console.log(heroImgId)
            const snapImageIds = destinationData.snaps?.snaps_images.map(
              (img) => img.image
            );
            // console.log(snapImageIds);
            const allImageIds = [heroImgId, ...snapImageIds].filter(Boolean);

            if (allImageIds) {
              const mediaData = await fetchMediaData(allImageIds);

              const heroMedia = mediaData.find(
                (media) => media.id === heroImgId
              );

              if (
                heroMedia &&
                heroMedia.media_details &&
                heroMedia.media_details.sizes &&
                heroMedia.media_details.sizes.full
              ) {
                setHeroImg(heroMedia.media_details.sizes.full.source_url);
              }

              const snapImagesUrls = snapImageIds
                .map((id) => {
                  const media = mediaData.find((media) => media.id === id);
                  return media ? media.source_url : null;
                })
                .filter(Boolean);

              setSnapImages(snapImagesUrls);
            }
          }
        } else {
          console.error("No data found in response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [destination, setDestinationData]);

  const showImage = (index) => setSelectedImageIndex(index);
  const closeImage = () => setSelectedImageIndex(null);
  const goToNextImage = () =>
    setSelectedImageIndex((prev) =>
      prev < snapImages.length - 1 ? prev + 1 : prev
    );
  const goToPreviousImage = () =>
    setSelectedImageIndex((prev) => (prev > 0 ? prev - 1 : prev));

  const handleTouchStart = (e) => {
    setStartTouch(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setEndTouch(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (startTouch !== null && endTouch !== null) {
      const distance = endTouch - startTouch;
      if (distance > 50) {
        goToPreviousImage(); 
      } else if (distance < -50) {
        goToNextImage(); 
      }
    }
    setStartTouch(null);
    setEndTouch(null);
  };

  const [showFullText, setShowFullText] = useState(false);
  const toggleReadMore = () => setShowFullText(!showFullText);

  const shortText = `
  ${
    destinationData?.[destination]?.[0]?.destination_details?.hero_section
      ?.about?.description || ""
  }
  ${
    destinationData?.[destination]?.[0]?.destination_details?.hero_section
      ?.about?.description_1
      ? "<br /><br />" +
        destinationData?.[destination]?.[0]?.destination_details?.hero_section
          ?.about?.description_1
      : ""
  }
  ${
    destinationData?.[destination]?.[0]?.destination_details?.hero_section
      ?.about?.description_2
      ? "<br /><br />" +
        destinationData?.[destination]?.[0]?.destination_details?.hero_section
          ?.about?.description_2
      : ""
  }

 ${
   destinationData?.[destination]?.[0]?.destination_details?.hero_section?.about
     ?.title_2
     ? "<br /><br /> <span >" +
       destinationData?.[destination]?.[0]?.destination_details?.hero_section
         ?.about?.title_2 +
       "</span>"
     : ""
 }
  ${
    destinationData?.[destination]?.[0]?.destination_details?.hero_section
      ?.about?.description_3
      ? "<br /><br />" +
        destinationData?.[destination]?.[0]?.destination_details?.hero_section
          ?.about?.description_3
      : ""
  }
  ${
    destinationData?.[destination]?.[0]?.destination_details?.hero_section
      ?.about?.description_4
      ? "<br /><br />" +
        destinationData?.[destination]?.[0]?.destination_details?.hero_section
          ?.about?.description_4
      : ""
  }
  `.trim();

  const longText = shortText;
  const highlights =
    destinationData?.[destination]?.[0]?.destination_details?.hero_section
      ?.highlights || [];

  const hasData =
    destinationData && destinationData[destination]?.[0]?.destination_details;

  if (loading) {
    return (
      <div
        className="loading-container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading</span>
        </Spinner>
      </div>
    );
  }

  if (!hasData) {
    return null;
  }
  return (
    <div>
      <motion.div
        className="destinaion-details-hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        {heroImg && <img src={heroImg} alt="Hero Banner" />}
        <div className="triangle-container">
          <Image src={TriangleShape} className="triangle-shape" />
          <div className="triangle-text">
            {
              destinationData?.[destination]?.[0]?.destination_details
                ?.hero_section?.banner_title_1
            }
          </div>
        </div>
      </motion.div>
      <motion.div
        className="about-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <span>
          {
            destinationData?.[destination]?.[0]?.destination_details
              ?.hero_section?.banner_title_2
          }
        </span>
        <h3>
          {
            destinationData?.[destination]?.[0]?.destination_details
              ?.hero_section?.about?.title
          }
        </h3>

        <p>
          {showFullText ? (
            <p dangerouslySetInnerHTML={{ __html: longText }} />
          ) : (
            <p dangerouslySetInnerHTML={{ __html: shortText }} />
          )}
          {showFullText && highlights && (
            <div className="highlights-section">
              <h4>
                {
                  destinationData?.[destination]?.[0]?.destination_details
                    ?.hero_section?.banner_title_3
                }
              </h4>
              <ul>
                {highlights.map((highlight, index) => (
                  <li key={index}>{highlight.points}</li>
                ))}
              </ul>
            </div>
          )}

          <span onClick={toggleReadMore} className="pb-5">
            {showFullText ? " Read Less" : " Read More"}
          </span>
        </p>
      </motion.div>

      <motion.div
        className="snap"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h3>Snaps</h3>
        <div className="snap-grid">
          {snapImages.map((snap, index) => (
            <motion.img
              key={index}
              src={snap}
              alt={`Image ${index + 1}`}
              onClick={() => showImage(index)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            />
          ))}
        </div>
        <Modal
          show={selectedImageIndex !== null}
          onHide={closeImage}
          centered
          dialogClassName="custom-modal m-0"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <Modal.Header className="modal-close-button">
            <Button variant="link" onClick={closeImage}>
              <FontAwesomeIcon icon={faXmark} size="xl" />
            </Button>
          </Modal.Header>
          <Modal.Body className="text-center">
            {selectedImageIndex !== null && (
              <Image
                src={snapImages[selectedImageIndex]}
                alt={`Image ${selectedImageIndex + 1}`}
              />
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={goToPreviousImage}
              disabled={selectedImageIndex === 0}
            >
              <Image src={LeftArrow} />
            </Button>
            <Button
              variant="primary"
              onClick={goToNextImage}
              disabled={selectedImageIndex === snapImages.length - 1}
            >
              <Image src={RightArrow} />
            </Button>
          </Modal.Footer>
        </Modal>
      </motion.div>
    </div>
  );
}

export default DetailsView;
