import React from "react";
import "../style/events.scss";
import BlogsDetailsView from './blogs-details-view';
import BlogsDetailsRecentSearch from './blogs-details-recent-search';

function BlogsDetails() {
  return (
    <div>
     <BlogsDetailsView/>
     <BlogsDetailsRecentSearch/>
    </div>
  );
}

export default BlogsDetails;
