
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { fetchSingleMedia } from "../../apiUtils";

function DesktopEventsDetailsGallery() {
  const [galleryImages, setGalleryImages] = useState([]);
  const { desktopEvent } = useContext(UserContext);
  const { cardName } = useParams();

  useEffect(() => {
    const fetchGalleryData = async () => {
      if (desktopEvent && cardName) {
        const galleryData = desktopEvent[cardName]?.events_details?.gallery?.images;

        if (galleryData) {
          const imagePromises = galleryData.map(async (item) => {
            const id = item.image;

            try {
              const mediaResponse = await fetchSingleMedia(id);

              // get the full image URL first
              const  fullImageUrl = mediaResponse.media_details.sizes?.full?.source_url;

              if(fullImageUrl){
                return {id, imageUrl: fullImageUrl}
              } else {

              const descriptionGuideHtml = mediaResponse.description.rendered;
              const parser = new DOMParser();
              const doc = parser.parseFromString(descriptionGuideHtml, "text/html");
              const imgElement = doc.querySelector("img");
              return imgElement ? { id, imageUrl: imgElement.src } : null;
              }

            } catch (fetchError) {
              console.error('Error fetching media:', fetchError);
              return null;
            }
          });

          const fetchedImages = await Promise.all(imagePromises);

          setGalleryImages(fetchedImages.filter((item) => item !== null));
        } else {
          console.error('Gallery data is not available for the given cardId');
        }
      } else {
        console.log('desktopEvent or cardId is not available');
      }
    };

    fetchGalleryData();
  }, [desktopEvent, cardName]);

  if (galleryImages.length === 0) {
    return null;
  }

  return (
    <div className="desktop-destination-details-gallery pl-120 pr-120 ">
      <div className="d-flex justify-content-between">
        <div>
          <h3>{(desktopEvent && desktopEvent[cardName]?.events_details?.gallery?.title) || 'gallery not available'}</h3>
        </div>
      </div>
      <div className="desktop-destination-details-gallery-slider">
        {galleryImages.map((item, index) => (
          <Image src={item.imageUrl} key={index} className="slider-image" />
        ))}
      </div>
    </div>
  );
}

export default DesktopEventsDetailsGallery;
