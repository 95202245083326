import "bootstrap/dist/css/bootstrap.min.css";
import { Image } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { fetchSingleMedia } from "../../apiUtils";

function DesktopJoinDream() {
  const [data, setData] = useState(null);
  const [bannerImgUrl, setBannerImgUrl] = useState("");
  const { desktopOurStory } = useContext(UserContext);

  useEffect(() => {
    if (desktopOurStory) {

      const fetchBannerImgUrl = async () => {
        try {
          const { banner_image } = desktopOurStory?.join_the__dream;

          const imageResponse = await fetchSingleMedia(banner_image);

          setBannerImgUrl(imageResponse.source_url);
        } catch (error) {
          console.error("error fetching banner image url:", error);
        }
      };
      fetchBannerImgUrl();
    }
  }, [desktopOurStory]);


  if (!desktopOurStory?.join_the__dream) {
    return null; 
  }
  
  const { title_1, title_2, description, email } =
    desktopOurStory?.join_the__dream;

  const isDataEmpty = !title_1 && !title_2 && !description && !email;

  if (isDataEmpty) {
    return null; 
  }


  return (
    <div className="desktop-join-dream-section">

      <Image src={bannerImgUrl} className="desktop-join-dream-section-img" />

      <div className="desktop-join-dream-image-overlay"></div>

      <div className="desktop-join-dream-img-text">
        <p>{title_1}</p>
        <p>{title_2}</p>
        <p>
          {description}
          <br />
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      </div>
    </div>
  );
}

export default DesktopJoinDream;
