import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { UserContext } from "../../UserContext";
import { useContext } from "react";
import { Link } from "react-router-dom";

function DesktopFAQ() {
  const { desktopHomeData } = useContext(UserContext);

  return (
    <div className="desktop-FAQ-section pl-120 pr-120">
      <span>{desktopHomeData?.faq?.faqs_title}</span>

      <div>
        <Accordion defaultActiveKey="0" className="desktop-custom-accordion">
          {(desktopHomeData?.faq?.faqs || []).slice(0, 5).map((faq, index) => (
            <div key={index}>
              <Accordion.Item eventKey={index.toString()}>
                <Accordion.Header>{faq.faq_}</Accordion.Header>
                <Accordion.Body>{faq.faq_description}</Accordion.Body>
              </Accordion.Item>
              {index < desktopHomeData.faq.faqs.length - 1 && (
                <hr className="desktop-hr-line" />
              )}
            </div>
          ))}
        </Accordion>

        <div className="text-center">
          <Link to="/all-FAQs-page" className="text-decoration-none">
            <Button className="desktop-view-all-btn">
              <span>View All</span>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DesktopFAQ;
