import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Image, Row } from 'react-bootstrap';
import LeftArrow from '../desktop-assets/images/arrow-left (2).svg';
import RightArrow from '../desktop-assets/images/arrow-right (1).svg';
import axios from 'axios';
import { UserContext } from '../../UserContext';
import { useEffect, useState, useContext } from 'react';
import { fetchSingleMedia } from '../../apiUtils';

function DesktopMediaLogos() {
  const { desktopHomeData } = useContext(UserContext);
  const [imageSrcs, setImageSrcs] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const visibleLogosCount = 6;

  useEffect(() => {
    const fetchLogos = async () => {
      if (!desktopHomeData?.seen_on?.seen_on_images) {
        return;
      }

      try {
        const seenOnImages = desktopHomeData.seen_on.seen_on_images;
        const imagePromises = seenOnImages.map(async item => {
          const mediaData = await fetchSingleMedia(item.image);
          const parser = new DOMParser();
          const doc = parser.parseFromString(mediaData.description.rendered, 'text/html');
          const imgElement = doc.querySelector('img');
          return imgElement ? imgElement.src : null;
        });

        const imageSrcs = await Promise.all(imagePromises);
        setImageSrcs(imageSrcs.filter(url => url !== null));
      } catch (error) {
        console.error('Error fetching media logos:', error);
      }
    };

    fetchLogos();
  }, [desktopHomeData]);

  const isDataEmpty =
    !desktopHomeData?.seen_on?.title_1 && !desktopHomeData?.seen_on?.title_2 && imageSrcs.length === 0;

  if (isDataEmpty) {
    return null;
  }

  const handleLeftClick = () => {
    setStartIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : imageSrcs.length - visibleLogosCount));
  };

  const handleRightClick = () => {
    setStartIndex(prevIndex => (prevIndex < imageSrcs.length - visibleLogosCount ? prevIndex + 1 : 0));
  };

  return (
    <div className='desktop-media-logos-section'>
      <span>{desktopHomeData?.seen_on?.title_1}</span>

      <div className='d-flex justify-content-between align-items-center'>
        <div className='desktop-seen-on'>
          <span>{desktopHomeData?.seen_on?.title_2}</span>
        </div>
        <div className='d-flex seen-on-arrows'>
          <Image src={LeftArrow} className='me-4 left-arrow-img' onClick={handleLeftClick} />
          <Image src={RightArrow} className=' right-arrow-img' onClick={handleRightClick} />
        </div>
      </div>

      <div className='desktop-media-logos-img-wrapper '>
        <div
          className='desktop-media-logos-img'
          style={{ transform: `translateX(-${(startIndex * 100) / visibleLogosCount}%)` }}
        >
          {imageSrcs.map((src, index) => (
            <Col xs={4} md={2} key={index}>
              <img src={src} alt={`Logo ${index + 1}`} className='img-fluid' />
            </Col>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DesktopMediaLogos;
