// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Col, Container, Image, Row, Button, FormControl } from 'react-bootstrap';
// import DesktopLogo from '../desktop-assets/images/Asset-3.png';
// import DesktopShape from '../desktop-assets/images/shape.png';
// import DesktopCallIcon from '../desktop-assets/images/call (1).svg';
// import DesktopMailIcon from '../desktop-assets/images/mail (1).svg';
// import InputGroup from 'react-bootstrap/InputGroup';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
// import FooterFb from '../desktop-assets/images/footer-fb.svg';
// import FooterInsta from '../desktop-assets/images/footer-insta.svg';
// import FooterYoutube from '../desktop-assets/images/footer-youtube.svg';
// import 'react-phone-input-2/lib/style.css';
// import { Link, useLocation } from 'react-router-dom';
// import { useForm } from 'react-hook-form';
// import emailjs from 'emailjs-com';
// import GiftCardForm from './desktop-gift-card-form';
// import { useContext } from 'react';
// import { UserContext } from '../../UserContext';
// import { fetchAcfMessageData } from '../../apiUtils';

// import React, { useState, useEffect } from 'react';

// function DesktopFooter() {
//   const [isVisible, setIsVisible] = useState(false);
//   const [email, setEmail] = useState('');
//   const [emailError, setEmailError] = useState('');
//   const [acfMessage, setAcfMessage] = useState('');
//   const [message, setMessage] = useState('');
//   const { giftMessage, setGiftMessage } = useContext(UserContext);

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm();

//   fetchAcfMessageData()
//     .then(acf => {
//       if (acf?.message) {
//         setAcfMessage(acf.message);
//       }
//     })
//     .catch(error => {
//       console.error('Error fetching the ACF message:', error);
//     });

//   const sendEmail = async data => {
//     const templateParams = {
//       to_email: data.email,
//       reply_to: data.email,
//       message: `New subscription from ${data.email}`,
//     };

//     try {
//       const response = await emailjs.send('service_iu5bpb9', 'template_4a5mnhm', templateParams, 'tqhL4V0KKfdUsFYgU');
//       console.log('Email sent successfully:', response.status, response.text);
//       setMessage('Thank you for subscribing!');
//     } catch (error) {
//       console.error('Failed to send email:', error);
//       setMessage('Failed to send your request. Please try again later.');
//     }
//   };

//   const onSubmit = data => {
//     sendEmail(data);
//   };

//   const handleScroll = () => {
//     if (window.pageYOffset > 300) {
//       setIsVisible(true);
//     } else {
//       setIsVisible(false);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth',
//     });
//   };

//   const openInNewTab = url => {
//     window.open(url, '_blank', 'noopener,noreferrer');
//   };

//   const [showGiftCardForm, setShowGiftCardForm] = useState(false);

//   const handleOpenGiftCardForm = () => {
//     setShowGiftCardForm(true);
//   };

//   const handleCloseGiftCardForm = () => {
//     setShowGiftCardForm(false);
//   };

//   return (
//     <div className='desktop-footer-section'>
//       {isVisible && (
//         <div className='scroll-to-top' onClick={scrollToTop}>
//           <FontAwesomeIcon icon={faAngleUp} className='desktop-scroll-to-top-icon' />
//         </div>
//       )}
//       <div className='desktop-footer-logo'>
//         <Image src={DesktopShape} className='desktop-footer-logo-shape' />
//         <Image src={DesktopLogo} className='desktop-footer-logo-img' />

//         <div className='desktop-footer-content'>
//           <Row className='gx-0 desktop-footer-desc pl-120 '>
//             <Col lg={4} className='desktop-footer-para d-inline-block justify-content-center '>
//               <span>
//                 Started by five friends, we at Moonstone Hammock provide a one-of-a-kind adventure tourism experience to
//                 campers across India. Everyone from Six-year-olds, 60-year-olds, solo campers, couples, groups,
//                 corporates and families, their clients are as varied as the adventures at their bespoke campsites.
//               </span>
//             </Col>
//             <Col lg={2} className='desktop-footer-quick-links d-flex justify-content-center'>
//               <div>
//                 <h2 className='pt-0 desktop-footer-title'>Quick Links</h2>
//                 <div>
//                   <Link to='/' className='text-decoration-none'>
//                     <p>Home</p>
//                   </Link>
//                   <Link to='/destination' className='text-decoration-none'>
//                     <p>Destination</p>
//                   </Link>
//                   <Link to='/events' className='text-decoration-none'>
//                     <p>Events</p>
//                   </Link>
//                   <Link to='/blogs' className='text-decoration-none'>
//                     <p>Blogs</p>
//                   </Link>
//                   <Link to='/our-story' className='text-decoration-none'>
//                     <p>Our Story</p>
//                   </Link>
//                   <p onClick={handleOpenGiftCardForm} style={{ cursor: 'pointer' }}>
//                     Gift Card
//                   </p>

//                   <GiftCardForm show={showGiftCardForm} handleClose={handleCloseGiftCardForm} />
//                 </div>
//               </div>
//             </Col>
//             <Col lg={3} className='desktop-footer-contact d-flex justify-content-center'>
//               <div>
//                 <h2 className='pt-0 desktop-footer-title d-flex'>Contact</h2>
//                 <div>
//                   <div className='desktop-footer-contact-content d-flex align-items-center'>
//                     <Image src={DesktopCallIcon} alt='Call Icon' />
//                     <a href='tel:+919321992712' className='text-decoration-none'>
//                       +91 93219 92712
//                     </a>
//                   </div>
//                   <div className=' desktop-footer-contact-content d-flex align-items-center'>
//                     <img src={DesktopMailIcon} />
//                     <a href='mailto:hello@moonstonehammock.com' className='text-decoration-none'>
//                       hello@moonstonehammock.com
//                     </a>
//                   </div>
//                 </div>
//                 <div className='desktop-footer-social-media-logo d-flex '>
//                   <Image
//                     src={FooterFb}
//                     onClick={() =>
//                       openInNewTab(
//                         'https://www.facebook.com/moonstonehammockofficial/?paipv=0&eav=AfYOfPccoUERbaiSrRZe4qndCe7yv_DEzQk8n2CfEKx6yPPYHQX7pdYuQEWBdhczpP0&_rdr'
//                       )
//                     }
//                     style={{ cursor: 'pointer' }}
//                   />
//                   <Image
//                     src={FooterInsta}
//                     onClick={() =>
//                       openInNewTab('https://www.instagram.com/moonstonehammockofficial/?igshid=16eufez01vikz')
//                     }
//                     style={{ cursor: 'pointer' }}
//                   />
//                   <Image
//                     src={FooterYoutube}
//                     onClick={() => openInNewTab('https://www.youtube.com/channel/UCTe1HEWaBWFTrbwrZd9AK_w/videos')}
//                     style={{ cursor: 'pointer' }}
//                   />
//                 </div>
//               </div>
//             </Col>
//             <Col lg={3} className='desktop-footer-newsletter  d-flex flex-column  align-items-center'>
//               <div>
//                 <h2 className='pt-0 desktop-footer-title d-flex'>Newsletter</h2>
//                 <div className='newsletter-container'>
//                   <form onSubmit={handleSubmit(onSubmit)} className='newsletter-form'>
//                     <InputGroup className='email-input-group'>
//                       <FormControl
//                         type='email'
//                         placeholder='Your Email Address'
//                         {...register('email', {
//                           required: 'Your Email Address is required',
//                         })}
//                         className='email-input'
//                       />
//                       <Button type='submit' className='newsletter-submit email-button'>
//                         <span className='arrow-icon'>
//                           <FontAwesomeIcon icon={faArrowRight} />
//                         </span>
//                       </Button>
//                     </InputGroup>
//                     {errors.email && <p className='text-danger mt-2 error-message'>{errors.email.message}</p>}
//                     {message && <p className='response-message'>{message}</p>}
//                   </form>
//                 </div>
//               </div>
//             </Col>
//           </Row>

//           <div className='desktop-hr-line'></div>
//           <div className='desktop-footer-copyrights d-flex justify-content-between align-items-center'>
//             <div>
//               <p>Moonstone Hammock © 2024. All Rights Reserved.</p>
//             </div>
//             <div className='desktop-footer-policy d-flex align-items-center'>
//               <Link to='/privacy-policy' className='text-decoration-none'>
//                 <span>Privacy Policy</span>
//               </Link>
//               <span className='separator mx-2'></span>
//               <Link to='/terms-and-conditions' className='text-decoration-none'>
//                 <span>Terms & Conditions</span>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DesktopFooter;

import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Button, FormControl, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import React, { useState, useEffect, useContext } from 'react';
import GiftCardForm from './desktop-gift-card-form';
import { UserContext } from '../../UserContext';
import { fetchAcfMessageData } from '../../apiUtils';
import { Link } from 'react-router-dom';
import DesktopLogo from '../desktop-assets/images/logo.png';
import DesktopShape from '../desktop-assets/images/shape.png';
import DesktopCallIcon from '../desktop-assets/images/call (1).svg';
import DesktopMailIcon from '../desktop-assets/images/mail (1).svg';
import FooterFb from '../desktop-assets/images/footer-fb.svg';
import FooterInsta from '../desktop-assets/images/footer-insta.svg';
import FooterYoutube from '../desktop-assets/images/footer-youtube.svg';

function DesktopFooter() {
  const [isVisible, setIsVisible] = useState(false);
  const [acfMessage, setAcfMessage] = useState('');
  const [message, setMessage] = useState('');
  const { giftMessage, setGiftMessage } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchAcfMessageData()
      .then(acf => {
        if (acf?.message) {
          setAcfMessage(acf.message);
        }
      })
      .catch(error => {
        console.error('Error fetching the ACF message:', error);
      });
  }, []);

  const sendEmailToOwners = data => {
    const templateParams = {
      message: `New subscription from ${data.email}`,
    };

    emailjs
      .send('service_r68krna', 'template_umh5nnc', templateParams, 'Q_bSr6P2XoBECSp8E')
      .then(response => {
        console.log('Notification email sent to owners.');
        setMessage('Thank you for subscribing!');
      })
      .catch(err => {
        console.error('Failed to send notification to owners.', err);
        setMessage('Failed to send your request. Please try again later.');
      });
  };

  const onSubmit = data => {
    sendEmailToOwners(data);
  };

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const [showGiftCardForm, setShowGiftCardForm] = useState(false);

  const handleOpenGiftCardForm = () => {
    setShowGiftCardForm(true);
  };

  const handleCloseGiftCardForm = () => {
    setShowGiftCardForm(false);
  };

  return (
    <div className='desktop-footer-section'>
      {isVisible && (
        <div className='scroll-to-top' onClick={scrollToTop}>
          <FontAwesomeIcon icon={faAngleUp} className='desktop-scroll-to-top-icon' />
        </div>
      )}
      <div className='desktop-footer-logo'>
        <Image src={DesktopShape} className='desktop-footer-logo-shape' />
        <Image src={DesktopLogo} className='desktop-footer-logo-img' />
        <div className='desktop-footer-content'>
          <Row className='gx-0 desktop-footer-desc pl-120 '>
            <Col lg={4} className='desktop-footer-para d-inline-block justify-content-center '>
              <span>
                Started by five friends, we at Moonstone Hammock provide a one-of-a-kind adventure tourism experience to
                campers across India. Everyone from Six-year-olds, 60-year-olds, solo campers, couples, groups,
                corporates and families, their clients are as varied as the adventures at their bespoke campsites.
              </span>
            </Col>
            <Col lg={2} className='desktop-footer-quick-links d-flex justify-content-center'>
              <div>
                <h2 className='pt-0 desktop-footer-title'>Quick Links</h2>
                <div>
                  <Link to='/' className='text-decoration-none'>
                    <p>Home</p>
                  </Link>
                  <Link to='/destination' className='text-decoration-none'>
                    <p>Destination</p>
                  </Link>
                  <Link to='/events' className='text-decoration-none'>
                    <p>Events</p>
                  </Link>
                  <Link to='/blogs' className='text-decoration-none'>
                    <p>Blogs</p>
                  </Link>
                  <Link to='/our-story' className='text-decoration-none'>
                    <p>Our Story</p>
                  </Link>
                  <p onClick={handleOpenGiftCardForm} style={{ cursor: 'pointer' }}>
                    Gift Card
                  </p>

                  <GiftCardForm show={showGiftCardForm} handleClose={handleCloseGiftCardForm} />
                </div>
              </div>
            </Col>
            <Col lg={3} className='desktop-footer-contact d-flex justify-content-center'>
              <div>
                <h2 className='pt-0 desktop-footer-title d-flex'>Contact</h2>
                <div>
                  <div className='desktop-footer-contact-content d-flex align-items-center'>
                    <Image src={DesktopCallIcon} alt='Call Icon' />
                    <a href='tel:+919321992712' className='text-decoration-none'>
                      +91 93219 92712
                    </a>
                  </div>
                  <div className=' desktop-footer-contact-content d-flex align-items-center'>
                    <img src={DesktopMailIcon} />
                    <a href='mailto:hello@moonstonehammock.com' className='text-decoration-none'>
                      hello@moonstonehammock.com
                    </a>
                  </div>
                </div>
                <div className='desktop-footer-social-media-logo d-flex '>
                  <Image
                    src={FooterFb}
                    onClick={() =>
                      openInNewTab(
                        'https://www.facebook.com/moonstonehammockofficial/?paipv=0&eav=AfYOfPccoUERbaiSrRZe4qndCe7yv_DEzQk8n2CfEKx6yPPYHQX7pdYuQEWBdhczpP0&_rdr'
                      )
                    }
                    style={{ cursor: 'pointer' }}
                  />
                  <Image
                    src={FooterInsta}
                    onClick={() =>
                      openInNewTab('https://www.instagram.com/moonstonehammockofficial/?igshid=16eufez01vikz')
                    }
                    style={{ cursor: 'pointer' }}
                  />
                  <Image
                    src={FooterYoutube}
                    onClick={() => openInNewTab('https://www.youtube.com/channel/UCTe1HEWaBWFTrbwrZd9AK_w/videos')}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            </Col>
            <Col lg={3} className='desktop-footer-newsletter d-flex flex-column align-items-center'>
              <div>
                <h2 className='pt-0 desktop-footer-title d-flex'>Newsletter</h2>
                <div className='newsletter-container'>
                  <form onSubmit={handleSubmit(onSubmit)} className='newsletter-form'>
                    <InputGroup className='email-input-group'>
                      <FormControl
                        type='email'
                        placeholder='Your Email Address'
                        {...register('email', {
                          required: 'Your Email Address is required',
                        })}
                        className='email-input'
                      />
                      <Button type='submit' className='newsletter-submit email-button'>
                        <span className='arrow-icon'>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </span>
                      </Button>
                    </InputGroup>
                    {errors.email && <p className='text-danger mt-2 error-message'>{errors.email.message}</p>}
                    {message && <p className='response-message'>{message}</p>}
                  </form>
                </div>
              </div>
            </Col>
          </Row>
          {/* Other footer content */}
          <div className='desktop-hr-line'></div>
          <div className='desktop-footer-copyrights d-flex justify-content-between align-items-center'>
            <div>
              <p>Moonstone Hammock © 2024. All Rights Reserved.</p>
            </div>
            <div className='desktop-footer-policy d-flex align-items-center'>
              <Link to='/privacy-policy' className='text-decoration-none'>
                <span>Privacy Policy</span>
              </Link>
              <span className='separator mx-2'></span>
              <Link to='/terms-and-conditions' className='text-decoration-none'>
                <span>Terms & Conditions</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesktopFooter;

// import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import emailjs from "emailjs-com";

// const DesktopFooter = () => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm();
//   const [message, setMessage] = useState("");

//   const sendEmail = (data) => {
//     const templateParams = {
//       to_email: data.email,
//       reply_to: data.email,

//       message: "Hello",
//     };

//     emailjs
//       .send(
//         "service_iu5bpb9",
//         "template_4a5mnhm",
//         templateParams,
//         "tqhL4V0KKfdUsFYgU"
//       )
//       .then((response) => {
//         setMessage("Thank you for subscribing!");
//       })
//       .catch((err) => {
//         setMessage("Failed to send your request. Please try again later.");
//       });
//   };

//   const onSubmit = (data) => {
//     sendEmail(data);
//   };

//   return (
//     <div className="newsletter-container">
//       <form onSubmit={handleSubmit(onSubmit)} className="newsletter-form">
//         <div className="newsletter-field">
//           <input
//             type="email"
//             placeholder="Your Email Address"
//             {...register("email", {
//               required: "Your Email Address is required",
//             })}
//           />
//           <button type="submit" className="newsletter-submit">
//             <span className="arrow-icon">→</span>{" "}
//             {/* Replace with your arrow icon */}
//           </button>
//         </div>
//         {errors.email && (
//           <p className="error-message">{errors.email.message}</p>
//         )}
//         {message && <p className="response-message">{message}</p>}
//       </form>
//     </div>
//   );
// };

// export default DesktopFooter;

{
  /* <div>
//                 <h2 className="pt-0 desktop-footer-title d-flex">Newsletter</h2>
//                 <div>
//                   <InputGroup className="email-input-group">
//                     <FormControl
//                       placeholder="Email Address"
//                       aria-label="Email Address"
//                       className="email-input"
//                       value={email}
//                       onChange={handleEmailChange}
//                     />
//                     <Button
//                       variant="outline-secondary"
//                       className="email-button"
//                       onClick={handleSendClick}
//                     >
//                       <FontAwesomeIcon icon={faArrowRight} />
//                     </Button>
//                   </InputGroup>
//                   {emailError && <p className="text-danger mt-2">{emailError}</p>}
//                 </div>
//               </div> */
}
