import React from "react";
import "../style/events.scss";
import { Image, Spinner } from "react-bootstrap";
import Calender from "../assets/images/events-calender.svg";
import { useParams } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { useState, useContext, useEffect } from "react";
import { fetchMobileBlogsData, fetchMediaData } from "../../apiUtils";

function BlogsDetailsView() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const { blogsData, setBlogsData } = useContext(UserContext);
  const { cardName } = useParams();
  const [heroImg, setHeroImg] = useState(null);
  const [iconImages, setIconImages] = useState([]);
  const [socialMediaIcon, setSocialMediaIcon] = useState([]);
  const [descriptionImg1, setDescriptionImg1] = useState([]);
  const [descriptionImg2, setDescriptionImg2] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (blogsData && blogsData[cardName]?.blog_details) {
      handleDataFromContext();
    } else {
      fetchData();
    }
  }, [cardName, blogsData]);

  const handleDataFromContext = async () => {
    setLoading(true);

    try {
      const blogDetails = blogsData[cardName]?.blog_details;

      if (blogDetails) {
        const heroImgId = blogDetails.banner_image;

        const iconImageIds = blogDetails.date_and_time
          ? blogDetails.date_and_time.map((item) => item.icon).filter(Boolean)
          : [];

  
        const socialMediaIconsData = blogDetails.social_media?.social_media_icons || [];
        const socialMediaIds = socialMediaIconsData
          .map((item) => item.icon)
          .filter(Boolean);

        const descriptionImg1Ids = Object.values(blogDetails.social_media?.description_images_1 || {})
          .filter(Boolean);


        const descriptionImg2Ids = Object.values(blogDetails.social_media?.description_images_2 || {})
          .filter(Boolean);

        const allImageIds = [
          heroImgId,
          ...iconImageIds,
          ...socialMediaIds,
          ...descriptionImg1Ids,
          ...descriptionImg2Ids,
        ].filter(Boolean);

        if (allImageIds.length > 0) {
          const mediaData = await fetchMediaData(allImageIds);

          const heroMedia = mediaData.find((media) => media.id === heroImgId);
          if (heroMedia) {
            setHeroImg(heroMedia.source_url);
          }

          const iconImagesUrls = iconImageIds
            .map((id) => {
              const media = mediaData.find((media) => media.id === id);
              return media ? media.source_url : null;
            })
            .filter(Boolean);

          setIconImages(iconImagesUrls);

          const socialMediaIconUrls = socialMediaIconsData
            .map((item) => {
              const media = mediaData.find((media) => media.id === item.icon);
              return media
                ? { url: media.source_url, link: item.link.url }
                : null;
            })
            .filter(Boolean);

          setSocialMediaIcon(socialMediaIconUrls);

          const descriptionImgUrls1 = descriptionImg1Ids
            .map((id) => {
              const media = mediaData.find((media) => media.id === id);
              return media ? media.source_url : null;
            })
            .filter(Boolean);

          setDescriptionImg1(descriptionImgUrls1);

          const descriptionImgUrls2 = descriptionImg2Ids
            .map((id) => {
              const media = mediaData.find((media) => media.id === id);
              return media ? media.source_url : null;
            })
            .filter(Boolean);

          setDescriptionImg2(descriptionImgUrls2);
        }
      } else {
        console.error("No blog details found for the specified cardName");
      }
    } catch (error) {
      console.error("Error handling data from context:", error);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const pageResponse = await fetchMobileBlogsData();
      const fetchedData = pageResponse;
      setBlogsData(fetchedData);

      const blogDetails = fetchedData[cardName]?.blog_details;

      if (blogDetails) {
        const heroImgId = blogDetails.banner_image;
        const iconImageIds = blogDetails.date_and_time
          ? blogDetails.date_and_time.map((item) => item.icon).filter(Boolean)
          : [];

        const socialMediaIconsData = blogDetails.social_media?.social_media_icons || [];
        const socialMediaIds = socialMediaIconsData
          .map((item) => item.icon)
          .filter(Boolean);

        const descriptionImg1Ids = Object.values(blogDetails.social_media?.description_images_1 || [])
          .filter(Boolean);


        const descriptionImg2Ids = Object.values(blogDetails.social_media?.description_images_2 || {})
          .filter(Boolean);

        const allImageIds = [
          heroImgId,
          ...iconImageIds,
          ...socialMediaIds,
          ...descriptionImg1Ids,
          ...descriptionImg2Ids,
        ].filter(Boolean);

        if (allImageIds.length > 0) {
          const mediaData = await fetchMediaData(allImageIds);

          const heroMedia = mediaData.find((media) => media.id === heroImgId);
          if (heroMedia) {
            setHeroImg(heroMedia.source_url);
          }

          const iconImagesUrls = iconImageIds
            .map((id) => {
              const media = mediaData.find((media) => media.id === id);
              return media ? media.source_url : null;
            })
            .filter(Boolean);

          setIconImages(iconImagesUrls);

          const socialMediaIconUrls = socialMediaIconsData
            .map((item) => {
              const media = mediaData.find((media) => media.id === item.icon);
              return media
                ? { url: media.source_url, link: item.link.url }
                : null;
            })
            .filter(Boolean);

          setSocialMediaIcon(socialMediaIconUrls);

          const descriptionImgUrls1 = descriptionImg1Ids
            .map((id) => {
              const media = mediaData.find((media) => media.id === id);
              return media ? media.source_url : null;
            })
            .filter(Boolean);

          setDescriptionImg1(descriptionImgUrls1);

          const descriptionImgUrls2 = descriptionImg2Ids
            .map((id) => {
              const media = mediaData.find((media) => media.id === id);
              return media ? media.source_url : null;
            })
            .filter(Boolean);

          setDescriptionImg2(descriptionImgUrls2);
        }
      } else {
        console.error("No blog details found for the specified cardName");
      }
    } catch (error) {
      console.error("Error fetching page data:", error);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div
        className="loading-container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="blogs-details-section">
        <Image src={heroImg} className="blogs-details-img" />
      <div className="blogs-details-desc">
          <h2>{blogsData?.[cardName]?.blog_details?.title}</h2>
          {/* <div className="d-flex">
            {blogsData?.[cardName]?.blog_details?.date_and_time?.map(
              (item, index) => (
                <div className="d-flex" key={index}>
                  <Image src={iconImages[index]} />
                  <h4>{item.text}</h4>
                </div>
              )
            )}
          </div> */}

          <div className="d-flex blogs-details-social-media-logos align-items-center">
            <p>{blogsData?.[cardName]?.blog_details?.social_media?.text}</p>
            {socialMediaIcon.map((item, index) => (
              <Image
                key={index}
                src={item.url}
                onClick={() => openInNewTab(item.link)}
                style={{ cursor: "pointer" }}
              />
            ))}
          </div>
            <div className="blogs-details-desc-para">
          <p>
            {blogsData?.[cardName]?.blog_details?.social_media?.description_1}
          </p>
          <p>
            {blogsData?.[cardName]?.blog_details?.social_media?.description_2}
          </p>

          <div className="blogs-details-img-grid">
            {descriptionImg1[0] &&  (
              <Image
                src={descriptionImg1[0]}
                className="d-flex justify-content-start"
                alt="Description Image 1"
              />
            )}

            {descriptionImg1[1] && (
              <Image
                src={descriptionImg1[1]}
                className="d-flex justify-content-end"
                alt="Description Image 1"
              />
            )}
          </div>
          {descriptionImg1[2] && (
            <Image
              src={descriptionImg1[2]}
              className="blogs-details-full-image"
              alt="Description Image 1"
            />
          )}
          <p>
            {blogsData?.[cardName]?.blog_details?.social_media?.description_3}
          </p>


          <div className="blogs-details-img-grid">
            {descriptionImg2.length > 0 &&
              descriptionImg2.map((src, index) => (
                <Image
                  key={index}
                  src={src}
                  className={
                    index === 0
                      ? "d-flex justify-content-start"
                      : "d-flex justify-content-end"
                  }
                  alt={`Description Image ${index + 1}`}
                />
              ))}
          </div>
          <p>
            {blogsData?.[cardName]?.blog_details?.social_media?.description_4}
          </p>
        </div>
        <div className="blogs-details-hr-line"></div>
      </div>
    </div>
  );
}

export default BlogsDetailsView;
