import React from "react";
import "../style/events.scss";
import { motion } from "framer-motion";
import { UserContext } from "../../UserContext";
import { useContext, useEffect, useState } from "react";
import { fetchMobileEventsData } from '../../apiUtils';
import { Spinner } from "react-bootstrap";

function FadeInOut({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

function EventView() {
  const { eventsData, setEventsData, setSelectedEvents } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchLogoData = async () => {
      try {
        const fetchedData = await fetchMobileEventsData();

        if (fetchedData) {
          setEventsData(fetchedData);
        } else {
          console.error("No data found in response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchLogoData();
  }, [setEventsData]);

  if (loading) {
    return (
      <div className="loading-container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading</span>
        </Spinner>
      </div>
    );
  }

  return (
    <FadeInOut>
      <div className="event-section">
        {(eventsData?.explore_amazing_events?.title_1 || eventsData?.explore_amazing_events?.title_2) && (
          <div className="events-title">
              <motion.span
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                {eventsData?.explore_amazing_events?.title_1}
              </motion.span>
            
              <motion.span
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                {eventsData?.explore_amazing_events?.title_2}
              </motion.span>
            
          </div>
        )}
      </div>
    </FadeInOut>
  );
}

export default EventView;




