import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../UserContext";
import { useState, useContext, useEffect } from "react";
import { fetchMobileEventsData } from "../../apiUtils";

function EventDetailsAbout() {
  const { eventsData } = useContext(UserContext);
  const { cardName } = useParams();
  const [eventsAboutData, setEventsAboutData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiData = await fetchMobileEventsData();
        // console.log("api data:", apiData);

        if (apiData) {
          // console.log("card name:", cardName);

          const aboutData = apiData[cardName]?.events_details_?.about;
          // console.log(aboutData);
          setEventsAboutData(aboutData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);

        if (eventsData) {
          const aboutData = eventsData[cardName]?.events_details_?.about;
          setEventsAboutData(aboutData);

        }
      }
    };

    fetchData();
  }, [eventsData, cardName]);

  return (

    <div className="events-details-about-section">
    {eventsAboutData && (
      <>
        <h1>{eventsAboutData.title}</h1>

        <div className="about-desc">
          {eventsAboutData.description?.map((desc, index) => (
            <p key={index}>{desc.text}</p>
          ))}

          <div>
            <h4>{eventsAboutData.title_1.title}</h4>
            <ul>
              {eventsAboutData.title_1?.points?.map((point, index) => (
                <li key={index}>
                  {/* <h5>{point.points.split(":")[0]}</h5> */}
                  {point.points}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h4>{eventsAboutData.title_2.title}</h4>
            <ul>
              {eventsAboutData.title_2?.points?.map((point, index) => (
                <li key={index}>
                  {/* <h5>{point.points.split(":")[0]}</h5> */}
                  {point.points}
                </li>
              ))}
            </ul>
          </div>

          {eventsAboutData.title_3?.points && (
            <div>
              <h4>{eventsAboutData.title_3.title}</h4>
              <ul>
                {eventsAboutData.title_3.points.map((extra, index) => (
                  <li key={index}>
                  {extra.points}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </>
    )}
  </div>
  );
}

export default EventDetailsAbout;



