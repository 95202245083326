import React from "react";
import { Button } from "react-bootstrap";

function ArrowBtn({ className, text }) {
  return (
    <Button type="submit" className={className}>
      <span>{text}</span>
    </Button>
  );
}

export default ArrowBtn;
