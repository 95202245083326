import React from "react";
import { Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { useState, useContext, useEffect } from "react";
import { fetchMediaData } from "../../apiUtils";

function EventsGuide() {
  const { eventsData } = useContext(UserContext);
  const { cardName } = useParams();
  const [eventsGuide, setEventsGuide] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!eventsData || !cardName) {
          // console.error("eventsData or cardName is not available.");
          return;
        }

        const eventDetailsData = eventsData?.[cardName]?.events_details_;

        if (eventDetailsData) {
          const guideImgIds = eventDetailsData.event_guide?.event_content?.map(
            (content) => content.image
          ) || [];

          const allImageIds = guideImgIds.filter(Boolean); 

          if (allImageIds.length > 0) {
            const mediaData = await fetchMediaData(allImageIds);

            const guideMedia = eventDetailsData.event_guide?.event_content
              .map((content) => {
                const media = mediaData.find(
                  (media) => media.id === content.image
                );
                return {
                  src: media ? media.source_url : null,
                  label: content.text_1,
                  value: content.text_2,
                };
              })
              .filter((media) => media.src);

            setEventsGuide(guideMedia);
          }
        } else {
          console.error("No event details found for the specified cardName");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [eventsData, cardName]);

    const hasData = eventsGuide.length > 0 || eventsData?.[cardName]?.events_details_?.event_guide?.title;

    if (!hasData) {
      return null;
    }

  return (
    <div className="events-guide-section">
      <span>
        {eventsData?.[cardName]?.events_details_?.event_guide?.title}
      </span>
      
      <div>
          {eventsGuide.map((event, index) => (
            <div className="d-flex mt-3" key={index}>
              <div>
                <Image
                  src={event.src}
                  style={{ height: "46px", width: "46px" }}
                />
              </div>
              <div className="text-logo">
                <span>{event.label}</span>
                <span>{event.value}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default EventsGuide;
