import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { fetchSingleMedia } from "../../apiUtils";

function DesktopEventsDetailsGuide() {
  const [deskEventGuide, setDeskEventGuide] = useState(null);
  const { desktopEvent } = useContext(UserContext);
  const { cardName } = useParams();

  useEffect(() => {
    const fetchEventGuideData = async () => {
      if (desktopEvent && cardName) {
        const eventGuideData =
          desktopEvent[cardName]?.events_details?.event_guide;

        if (eventGuideData) {
          const eventContent = eventGuideData?.event_content || [];

          const imagePromises = eventContent.map(async (item) => {
            const id = item.image;

            try {
              const deskmediaResponse = await fetchSingleMedia(id);

              const descriptionGuideHtml = deskmediaResponse.description.rendered;
              const parser = new DOMParser();
              const doc = parser.parseFromString(descriptionGuideHtml,"text/html");
              const imgElement = doc.querySelector("img");
              return imgElement ? { ...item, imageUrl: imgElement.src } : item;
            } catch (fetchError) {
              console.error("error fetching media:", fetchError);
              return item;
            }
          });

          const eventContentWithImages = await Promise.all(imagePromises);

          setDeskEventGuide({
            ...eventGuideData,
            event_content: eventContentWithImages.filter(
              (item) => item.imageUrl !== null
            ),
          });
        } else {
          console.log("event data is not available for the given cardId");
        }
      } else {
        console.log("desktopEvent or cardId is not available");
      }
    };
    fetchEventGuideData();
  }, [desktopEvent, cardName]);

  if (!deskEventGuide || !deskEventGuide.event_content.length) {
    return null; 
  }

  return (
    <div className="desktop-events-details-guide pl-120 pr-120">
      {desktopEvent[cardName]?.events_details?.event_guide?.title ? (
        <h1>{desktopEvent[cardName].events_details.event_guide.title}</h1>
      ) : (
        <h1>Event guide is not available</h1>
      )}

      <div className="abc justify-content-between">
        {deskEventGuide.event_content &&
          deskEventGuide.event_content.map((item, index) => (
            <div className="d-flex mt-3" key={index}>
              <div>
                {item.imageUrl ? (
                  <Image src={item.imageUrl} height={100} width={100} />
                ) : (
                  <span>logos not available</span>
                )}
              </div>
              <div className="desktop-events-details-text-logo">
                <span>{item.text_1 }</span>
                <span>{item.text_2}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
export default DesktopEventsDetailsGuide;
