import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Image, Row, Spinner } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { fetchdesktopOurStoryView, fetchSingleMedia } from "../../apiUtils";

function DesktopOurStoryView() {
  const [data, setData] = useState(null);
  const [heroImgUrl, setHeroImgUrl] = useState("");
  const { desktopOurStory, setDesktopOurStory } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchDeskOurStoryData = async () => {
      try {
        const response = await fetchdesktopOurStoryView();
        const pageData = response;

        setData(pageData);
        setDesktopOurStory(pageData);

        const imageResponse = await fetchSingleMedia(
          pageData.desktop_hero_image_
        );
        const imageUrl = imageResponse.source_url;

        setHeroImgUrl(imageUrl);

        setLoading(false);
      } catch (error) {
        console.error("error fetching data:", error);
        setLoading(false);
      }
    };

    fetchDeskOurStoryData();
  }, [setDesktopOurStory]);

  if (loading) {
    return (
      <div
        className="loading-container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading</span>
        </Spinner>
      </div>
    );
  }

  const isDataEmpty = !data || !data.craft_your_dream_experience?.title_1 &&
    !data.craft_your_dream_experience?.title_2 &&
    !data.craft_your_dream_experience?.description;

  if (isDataEmpty) {
    return null; 
  }

  return (
    <div className="desktop-our-story-section">
      <div className="desktop-our-story-hero-img">
        <Image src={heroImgUrl} />
      </div>

      <Row className="desktop-our-story-desc pl-120 pr-120 d-flex gx-0 justify-content-center align-items-center">
        <Col lg={4} className="desktop-our-story-title ">
          <span>{data.craft_your_dream_experience.title_1}</span>
          <span>{data.craft_your_dream_experience.title_2}</span>
        </Col>
        <Col lg={8} className="desktop-our-story-para">
          <span>{data.craft_your_dream_experience.description}</span>
        </Col>
      </Row>
    </div>
  );
}

export default DesktopOurStoryView;
