import React from "react";
import { Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { Link } from "react-router-dom";

function FAQ() {
  const { homeData } = useContext(UserContext);

  if (
    !homeData ||
    !homeData.faq ||
    !homeData.faq.faqs ||
    homeData.faq.faqs.length === 0
  ) {
    return null;
  }

  return (
    <div className="faq-section">
      <div className="faq-title">
        <span>{homeData?.faq?.faqs_title}</span>
      </div>
      <div>
        <Accordion defaultActiveKey="0" className="custom-accordion">
          {/* {homeData?.faq?.faqs?.map((faq, index) => ( */}
          {(homeData?.faq?.faqs || []).slice(0, 5).map((faq, index) => (
            <React.Fragment key={index}>
              <Accordion.Item eventKey={index.toString()}>
                <Accordion.Header>{faq.faq_}</Accordion.Header>
                <Accordion.Body>{faq.faq_description}</Accordion.Body>
              </Accordion.Item>
              {index < homeData.faq.faqs.length - 1 && (
                <hr className="hr-line" />
              )}
            </React.Fragment>
          ))}
        </Accordion>
        <div className="text-center">
          <Link to="/all-FAQs-page">
            <Button className="view-all-btn">
              <span>View All</span>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
