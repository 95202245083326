import React,{  useEffect, useState,  } from "react";
import { Spinner } from "react-bootstrap";
import { fetchDesktopEventData } from "../../apiUtils";

function DesktopEventsView() {
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const fetchEventData = async () => {
      try{
        const response = await fetchDesktopEventData();
      if (response) {
        setEventData(response)
      }
    setLoading(false)

    } catch (error){
      console.error('error fething event data:', error);
    }
  };
    fetchEventData();
  }, []);

  if (!eventData) {
    return<div>Loading...</div>;
  }

  if (loading) {
    return (
      <div className="loading-container d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
    if (!eventData || !eventData.explore_amazing_events) {
      return null; 
    }
  

  return (
    <div className="desktop-events-view-section">
           <div className="desktop-events-title">
        <p className="d-flex justify-content-center mb-0">{eventData.explore_amazing_events.title_1}</p>
        <p className="d-flex justify-content-center mb-0">{eventData.explore_amazing_events.title_2}</p>
      </div>
    </div>

  );
}

export default DesktopEventsView;
