import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Image, Row, Button, Spinner } from 'react-bootstrap';
import TriangleShape from '../desktop-assets/images/destination-details-traingle.png';
import ArrowLeft from '../desktop-assets/images/arrow-left (2).svg';
import ArrowRight from '../desktop-assets/images/arrow-right (1).svg';
import LightArrowLeft from '../desktop-assets/images/arrow-left copy.svg';
import LightArrowRight from '../desktop-assets/images/arrow-right copy.svg';
import { Modal } from 'react-bootstrap';
import { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import { fetchDestinationData, fetchMediaData } from '../../apiUtils';
import axios from 'axios';
import _ from 'lodash';

function DesktopDestinationDetailsView() {
  const { desktopDestinationDetailsData, setDesktopDestinationDetailsData } = useContext(UserContext);
  const { destination } = useParams();
  const [heroImg, setHeroImg] = useState(null);
  const [snapImages, setSnapImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const imagesPerPage = 3;

  useEffect(() => {
    fetchData();
  }, [destination]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const fetchedData = await fetchDestinationData();

      if (!fetchedData) {
        console.error('No data fetched from API');
        return;
      }

      setDesktopDestinationDetailsData(fetchedData);

      const destinationData = fetchedData[destination]?.[0]?.destination_details;

      if (!destinationData) {
        console.error('No destination details found for the specified destination');
        return;
      }

      const heroImgId = destinationData.hero_section?.desktop_banner_image;
      const snapImageIds = destinationData.snaps?.snaps_images.map(img => img.image);
      // console.log(snapImageIds);
      const allImageIds = [heroImgId, ...snapImageIds].filter(Boolean);

      if (allImageIds.length === 0) {
        console.error('No valid image IDs found.');
        return;
      }

      const mediaData = await fetchMediaData(allImageIds);

      if (mediaData.length === 0) {
        console.error('No media data returned for the provided IDs.');
        return;
      }

      const heroMedia = mediaData.find(media => media.id === heroImgId);
      if (heroMedia) {
        setHeroImg(heroMedia.source_url);
      }

      const snapImagesUrls = snapImageIds
        .map(id => {
          const media = mediaData.find(media => media.id === id);
          return media ? media.source_url : null;
        })
        .filter(Boolean);

      setSnapImages(snapImagesUrls);
    } catch (error) {
      console.error('An error occurred during data fetching:', error);
    }
    setLoading(false);
  };

  const showImage = index => setSelectedImageIndex(index);
  const closeImage = () => setSelectedImageIndex(null);

  const goToNextImage = () => setSelectedImageIndex(prev => (prev < snapImages.length - 1 ? prev + 1 : prev));
  const goToPreviousImage = () => setSelectedImageIndex(prev => (prev > 0 ? prev - 1 : prev));

  const handleNextImageSet = () => {
    if (currentStartIndex + imagesPerPage < snapImages.length) {
      setCurrentStartIndex(prevIndex => prevIndex + imagesPerPage);
    }
  };

  const handlePreviousImageSet = () => {
    if (currentStartIndex > 0) {
      setCurrentStartIndex(prevIndex => prevIndex - imagesPerPage);
    }
  };

  if (loading) {
    return (
      <div className='loading-container d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!desktopDestinationDetailsData || !desktopDestinationDetailsData[destination]?.[0]?.destination_details) {
    return null;
  }

  return (
    <div className='desktop-destination-details-section'>
      <motion.div
        className='desktop-destination-details-view'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <div className='desktop-destination-details-hero-img-div'>
          {heroImg && <Image src={heroImg} className='desktop-destination-details-hero' />}
        </div>
        <div className='desktop-destination-details-triangle-container'>
          <Image src={TriangleShape} />
          <p>
            {desktopDestinationDetailsData?.[destination]?.[0]?.destination_details.hero_section.banner_title_1 ||
              'Loading Title...'}
          </p>
        </div>
      </motion.div>

      <motion.div
        className='desktop-destination-details-about'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <p className='d-flex justify-content-center p-2'>
          {desktopDestinationDetailsData?.[destination]?.[0]?.destination_details.hero_section.banner_title_2}
        </p>
        <Row className='desktop-destination-details-desc pr-120 pl-120 d-flex gx-0 justify-content-center align-items-start'>
          <Col lg={3} className='desktop-destination-details-title'>
            <span>
              {desktopDestinationDetailsData?.[destination]?.[0]?.destination_details.hero_section.about.title}
            </span>
          </Col>
          <Col lg={9} className='desktop-destination-details-para'>
            <span>
              {desktopDestinationDetailsData?.[destination]?.[0]?.destination_details.hero_section.about.description}
            </span>

            <br></br>
            <br></br>

            <span>
              {desktopDestinationDetailsData?.[destination]?.[0]?.destination_details.hero_section.about.description_1}
            </span>

            <br></br>
            <br></br>
            <span>
              {desktopDestinationDetailsData?.[destination]?.[0]?.destination_details.hero_section.about.description_2}
            </span>

            <h1>{desktopDestinationDetailsData?.[destination]?.[0]?.destination_details.hero_section.about.title_2}</h1>

            <span>
              {desktopDestinationDetailsData?.[destination]?.[0]?.destination_details.hero_section.about.description_3}
            </span>

            <span>
              {desktopDestinationDetailsData?.[destination]?.[0]?.destination_details.hero_section.about.description_4}
            </span>

            <p> {desktopDestinationDetailsData?.[destination]?.[0]?.destination_details.hero_section.banner_title_3}</p>
            <ul>
              {desktopDestinationDetailsData?.[destination]?.[0].destination_details.hero_section.highlights.map(
                (highlight, index) => (
                  <li key={index}>{highlight.points}</li>
                )
              )}
            </ul>
          </Col>
        </Row>
      </motion.div>

      <motion.div
        className='desktop-destination-details-snap'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Container>
          <div className='d-flex justify-content-between'>
            <div>
              <h3>
                {desktopDestinationDetailsData?.[destination]?.[0]?.destination_details?.snaps?.title ||
                  'Loading Title...'}
              </h3>
            </div>
            <div className='desktop-snaps-arrow-btn'>
              <Image
                src={ArrowLeft}
                className='me-4'
                onClick={handlePreviousImageSet}
                style={{
                  cursor: currentStartIndex > 0 ? 'pointer' : 'default',
                }}
              />
              <Image
                src={ArrowRight}
                onClick={handleNextImageSet}
                style={{
                  cursor: currentStartIndex + imagesPerPage < snapImages.length ? 'pointer' : 'default',
                  opacity: currentStartIndex + imagesPerPage < snapImages.length ? 1 : 0.5,
                }}
              />
            </div>
          </div>
          <div className='scrollable-container'>
            <div className='scrollable-images'>
              {snapImages.slice(currentStartIndex, currentStartIndex + imagesPerPage).map((snap, index) => (
                <div className='scrollable-img-container' key={index}>
                  <Image
                    src={snap}
                    className='snap-img'
                    onClick={() => showImage(index + currentStartIndex)}
                    alt={`Image ${index + 1}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </Container>

        <div className='modal desktop-show' style={{ display: selectedImageIndex !== null ? 'block' : 'none' }}>
          <Modal.Dialog>
            <div className='custom-modal-body d-flex justify-content-center align-items-center'>
              <div className='custom-modal-content d-flex justify-content-center align-items-center'>
                {selectedImageIndex !== null && (
                  <Image
                    src={snapImages[selectedImageIndex]}
                    alt={`Image ${selectedImageIndex + 1}`}
                    className='snaps-selected-img-modal'
                  />
                )}
                <div className='desktop-modal-image-overlay'></div>
                <Button variant='link' onClick={closeImage} className='close-button '>
                  <FontAwesomeIcon icon={faXmark} size='l' />
                </Button>
                <div>
                  <div onClick={goToPreviousImage} disabled={selectedImageIndex === 0}>
                    <Image src={LightArrowLeft} className='desktop-modal-left-button' />
                  </div>
                  <div onClick={goToNextImage} disabled={selectedImageIndex === snapImages.length - 1}>
                    <Image src={LightArrowRight} className='desktop-modal-right-button' />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Dialog>
        </div>
      </motion.div>
    </div>
  );
}

export default DesktopDestinationDetailsView;
