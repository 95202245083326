import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';

const BookNowForm = ({ show, handleClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = data => {
    const templateParams = {
      from_name: data.name,
      name: data.name,
      companyName: data.companyName,
      designation: data.designation,
      email: data.email,
      phone: data.phone,
      tentativePeople: data.tentativePeople,
      tentativeMonth: data.tentativeMonth,
    };

    emailjs
      .send('service_p62d03w', 'template_mvz94wh', templateParams, 'lv1IeTKgYIwTGD7A5')
      .then(response => {
        console.log('Email sent successfully!', response.status, response.text);
        setIsSubmitted(true);
        reset();
        setTimeout(() => {
          setIsSubmitted(false);
          handleClose();
        }, 3000);
      })
      .catch(error => {
        console.error('Failed to send email:', error);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} className='book-now-desktop-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Inquire Now</Modal.Title>
      </Modal.Header>
      <Modal.Body className='book-now-body'>
      {isSubmitted ? (
          <div className='text-center'>
            <h5>Thank you. Someone from the team will connect with you shortly.</h5>
          </div>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId='formName'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your name'
                {...register('name', { required: 'Name is required' })}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type='invalid'>{errors.name?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formCompanyName'>
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your company name'
                {...register('companyName', { required: 'Company name is required' })}
                isInvalid={!!errors.companyName}
              />
              <Form.Control.Feedback type='invalid'>{errors.companyName?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formDesignation'>
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter your designation'
                {...register('designation', { required: 'Designation is required' })}
                isInvalid={!!errors.designation}
              />
              <Form.Control.Feedback type='invalid'>{errors.designation?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formEmail'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter your email'
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Invalid email format',
                  },
                })}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type='invalid'>{errors.email?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formPhone'>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='tel'
                placeholder='Enter your phone number'
                {...register('phone', {
                  required: 'Phone number is required',
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: 'Phone number must be exactly 10 digits',
                  },
                })}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type='invalid'>{errors.phone?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formTentativePeople'>
              <Form.Label>Tentative People</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter number of people'
                {...register('tentativePeople', {
                  required: 'Tentative People is required',
                  min: { value: 1, message: 'Must be greater than zero' },
                })}
                isInvalid={!!errors.tentativePeople}
              />
              <Form.Control.Feedback type='invalid'>{errors.tentativePeople?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='formTentativeMonth'>
              <Form.Label>Tentative Month</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter tentative month'
                {...register('tentativeMonth', { required: 'Tentative Month is required' })}
                isInvalid={!!errors.tentativeMonth}
              />
              <Form.Control.Feedback type='invalid'>{errors.tentativeMonth?.message}</Form.Control.Feedback>
            </Form.Group>

            <Button variant='primary' type='submit' className='gift-form-submit-btn'>
              Submit
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default BookNowForm;
