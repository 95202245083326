import React from "react";
import "../style/events-details.scss";
import EventsDetailsView from "../events-details-page/events-details-view";
import EventsGuide from "./event-guide";
import Gallery from "./gallery";
import EventDetailsAbout from "../events-details-page/about";
import Venue from "../events-details-page/venue";


function EventsDetailsPage() {
  return (
    <div>
      <EventsDetailsView />
      <EventsGuide />
      <Gallery />
      <EventDetailsAbout />
      {/* <Venue /> */}
    </div>
  );
}

export default EventsDetailsPage;
