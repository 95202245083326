import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { UserContext } from "../../UserContext";
import { Link } from "react-router-dom";
import { fetchHomePageData, fetchMediaData } from "../../apiUtils";

function DesktopAllFAQ() {
  const { desktopHomeData } = useContext(UserContext);
  const [faqData, setFaqData] = useState(null);

  const handleUserContextData = () => {
    if (desktopHomeData) {
      setFaqData(desktopHomeData.faq);
    }
  };

  const fetchDataFromAPI = async () => {
    try {
      const data = await fetchHomePageData();
      setFaqData(data.faq);
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
    }
  };

  useEffect(() => {
    if (desktopHomeData) {
      handleUserContextData();
    } else {
      fetchDataFromAPI();
    }
  }, [desktopHomeData]);

  return (
    <div
      className="desktop-FAQ-section  pl-120 pr-120 mt-5"
      style={{ minHeight: "2000px" }}
    >
      <span>{faqData?.faqs_title}</span>
      <div>
        <Accordion defaultActiveKey="0" className="desktop-custom-accordion">
          {faqData?.faqs.map((faq, index) => (
            <div key={index}>
              <Accordion.Item eventKey={index.toString()}>
                <Accordion.Header>{faq.faq_}</Accordion.Header>
                <Accordion.Body>{faq.faq_description}</Accordion.Body>
              </Accordion.Item>
              {index < faqData.faqs.length - 1 && (
                <hr className="desktop-hr-line" />
              )}
            </div>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default DesktopAllFAQ;
