import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.scss';
import './mixins.scss';
import '../src/desktop-view/desktop-style/desktop-header.scss';
import '../src/desktop-view/desktop-style/desktop-our-story.scss';
import '../src/desktop-view/desktop-style/desktop-blogs.scss';
import '../src/desktop-view/desktop-style/desktop-homepage.scss';
import '../src/desktop-view/desktop-style/desktop-footer.scss';
import '../src/desktop-view/desktop-style/desktop-destination.scss';
import '../src/desktop-view/desktop-style/desktop-events.scss';
import '../src/desktop-view/desktop-style/desktop-destination-details.scss';
import '../src/desktop-view/desktop-style/desktop-events-details.scss';
import '../src/desktop-view/desktop-style/desktop-blogs-details.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import DesktopHomepageCompo from './desktop-view/desktop-homepage/desktop-homepage-component';
import DesktopDestination from './desktop-view/desktop-destination/desktop-destinationcompo';
import DesktopEvents from '../src/desktop-view/desktop-events/desktop-events-compo';
import '../src/mobile-view/style/menu.scss';
import '../src/mobile-view/style/homepage.scss';
import '../src/mobile-view/style/destintions.scss';
import '../src/mobile-view/style/destinations-details.scss';
import '../src/mobile-view/style/our-story.scss';
import '../src/mobile-view/style/blogs.scss';
import '../src/mobile-view/style/blogs-details.scss';
import HomePage from './mobile-view/homepage/homepage';
import DestinationPage from './mobile-view/destination-page/destination-page';
import DestinationsDetails from './mobile-view/destinations-details/destinations-details';
import EventPage from './mobile-view/event-page/events';
import EventsDetailsPage from './mobile-view/events-details-page/events-details-page';
import Menu from './mobile-view/menu/menu-section';
import DesktopHeader from './desktop-view/desktop-header/desktop-header';
import Header from './mobile-view/header-section/header';
import DesktopBlogs from './desktop-view/desktop-blogs/desktop-blogs-compo';
import DesktopOurStory from './desktop-view/desktop-our-story/desktop-our-story-compo';
import DesktopDestinationDetails from './desktop-view/desktop-destination-details/desktop-destination-details-compo';
import DesktopEventsDetails from './desktop-view/desktop-events-details.js/desktop-events-details-compo';
import DesktopBlogsDetails from './desktop-view/desktop-blogs-details/desktop-blogs-details-compo';
import OurStory from './mobile-view/our-story/our-story-compo';
import Blogs from './mobile-view/blogs/blogs-compo';
import BlogsDetails from './mobile-view/blogs-details/blogs-details-compo';
import { UserProvider } from './UserContext';
import DesktopAllFAQ from './desktop-view/desktop-homepage/desktop-all-FAQs';
import MobileAllFAQ from './mobile-view/homepage/all-FAQ-section';
import DesktopPrivacyPolicy from './desktop-privacy-policies';
import DesktopPageNotFound from './desktop-view/desktop-page-not-found';
import MobilePageNotFound from './mobile-view/mobile-page-not-found';
import DesktopTermsAndConditions from './desktop-terms-and-conditions';
import DesktopFooter from '../src/desktop-view/desktop-footer/desktop-footer';
import Footer from '../src/mobile-view/homepage/footer';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const ResponsiveRoute = ({ desktopComponent: DesktopComponent, mobileComponent: MobileComponent }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile ? <MobileComponent /> : <DesktopComponent />;
};

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <UserProvider>
      <BrowserRouter>
        <ScrollToTop />
        {!isMobile && <DesktopHeader />}
        {isMobile && <Header />}
        <Routes>
          <Route
            path='/'
            element={<ResponsiveRoute desktopComponent={DesktopHomepageCompo} mobileComponent={HomePage} />}
          />
          <Route
            path='/destination'
            element={<ResponsiveRoute desktopComponent={DesktopDestination} mobileComponent={DestinationPage} />}
          />
          <Route
            path='/events'
            element={<ResponsiveRoute desktopComponent={DesktopEvents} mobileComponent={EventPage} />}
          />

          <Route
            path='/home'
            element={<ResponsiveRoute desktopComponent={DesktopHomepageCompo} mobileComponent={HomePage} />}
          />
          <Route
            path='/destination-details/:destination'
            element={
              <ResponsiveRoute desktopComponent={DesktopDestinationDetails} mobileComponent={DestinationsDetails} />
            }
          />
          <Route path='/blogs' element={<ResponsiveRoute desktopComponent={DesktopBlogs} mobileComponent={Blogs} />} />
          <Route
            path='/blogs-details/:cardName'
            element={<ResponsiveRoute desktopComponent={DesktopBlogsDetails} mobileComponent={BlogsDetails} />}
          />
          <Route
            path='/our-story'
            element={<ResponsiveRoute desktopComponent={DesktopOurStory} mobileComponent={OurStory} />}
          />
          <Route
            path='/events-details/:cardName'
            element={<ResponsiveRoute desktopComponent={DesktopEventsDetails} mobileComponent={EventsDetailsPage} />}
          />
          <Route
            path='/all-FAQs-page'
            element={<ResponsiveRoute desktopComponent={DesktopAllFAQ} mobileComponent={MobileAllFAQ} />}
          />
          <Route
            path='/privacy-policy'
            element={<ResponsiveRoute desktopComponent={DesktopPrivacyPolicy} mobileComponent={DesktopPrivacyPolicy} />}
          />

          <Route
            path='/terms-and-conditions'
            element={
              <ResponsiveRoute
                desktopComponent={DesktopTermsAndConditions}
                mobileComponent={DesktopTermsAndConditions}
              />
            }
          />

          <Route
            path='*'
            element={<ResponsiveRoute desktopComponent={DesktopPageNotFound} mobileComponent={MobilePageNotFound} />}
          />
        </Routes>
        {!isMobile && <DesktopFooter />}
        {isMobile && <Footer />}

        {isMobile && <Menu />}
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
