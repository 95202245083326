import React, { useEffect, useContext, useState } from "react";
import { Container, ListGroup, Image } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Point from "../assets/images/point.svg";
import { UserContext } from "../../UserContext";
import { useParams } from "react-router-dom";
import { fetchDestinationData } from "../../apiUtils";

const DetailsItinerary = () => {
  const { destinationData, setDestinationData } = useContext(UserContext);
  const [itineraryTitle, setItineraryTitle] = useState("");
  const [itineraryData, setItineraryData] = useState([]);

  const { destination } = useParams();

  useEffect(() => {
    if (destinationData && destinationData.length > 0) {
      handleDataFromContext();
    } else {
      fetchData();
    }
  }, [destination]);

  const handleDataFromContext = async () => {
    try {
      if (!destinationData || !destination) {
        console.error("Invalid destinationData or selectedDestination.");
        return;
      }

      const destinationDetails =
        destinationData[destination]?.[0]?.destination_details;

      if (!destinationDetails || !destinationDetails.itinerary) {
        console.error("Invalid destination details structure.");
        return;
      }

      const itinerary = destinationDetails.itinerary;
      setItineraryTitle(itinerary.title);
      setItineraryData(itinerary.options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const fetchedData = await fetchDestinationData();
      setDestinationData(fetchedData);

      const destinationDetails =
        fetchedData[destination]?.[0]?.destination_details;

      if (!destinationDetails || !destinationDetails.itinerary) {
        console.error("Invalid destination details structure.");
        return;
      }

      const itinerary = destinationDetails.itinerary;
      setItineraryTitle(itinerary.title);
      setItineraryData(itinerary.options);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start((i) => ({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, delay: i * 0.3 },
      }));
    }
  }, [controls, inView]);

  const itemVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: i * 0.3,
      },
    }),
  };

  if (!itineraryData.length) {
    return null;
  }

  return (
    <div className="itinerary-section" ref={ref}>
      <span>{itineraryTitle}</span>
      <Container>
        <ListGroup>
          {itineraryData.map((item, index) => (
            <motion.div
              key={index}
              custom={index}
              initial="hidden"
              animate={controls}
              variants={itemVariants}
            >
              <ListGroup.Item className="d-flex align-items-start position-relative">
                <Image src={Point} className="me-3" />
                <div>
                  <h5>{item.title}</h5>
                  <p className="mb-0">{item.time}</p>
                </div>
              </ListGroup.Item>
            </motion.div>
          ))}
        </ListGroup>
      </Container>
    </div>
  );
};

export default DetailsItinerary;
