import { Col, Image, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { UserContext } from "../../UserContext";
import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import calenderImg from "../desktop-assets/images/calender@2x.png";
import { fetchBlogsData, fetchMediaData } from "../../apiUtils";

function DesktopBlogsDetailsDesc() {
  const {
    desktopBlogsData,
    setDescriptionImg1,
    setDescriptionImg2,
    setRecentImg,
    setRecentIcon,
  } = useContext(UserContext);
  const { cardName } = useParams();
  const { descriptionImg1, descriptionImg2, recentImg, recentIcon } =
    useContext(UserContext);
  const [currentBlogDesc, setCurrentBlogDesc] = useState(null);
  const [recentArticles, setRecentArticles] = useState([]);

  useEffect(() => {
    if (desktopBlogsData && cardName) {
      const currentBlogDetails = desktopBlogsData.find(
        (blog) => blog.id === cardName
      );

      if (currentBlogDetails) {
        const blogsDetailsData = currentBlogDetails.blog_details;

        if (blogsDetailsData) {
          const descriptionImg1Ids = blogsDetailsData.social_media
            ?.description_images_1
            ? Object.values(
                blogsDetailsData.social_media.description_images_1
              ).filter(Boolean)
            : [];

          const descriptionImg2Ids = blogsDetailsData.social_media
            ?.description_images_2
            ? Object.values(
                blogsDetailsData.social_media.description_images_2
              ).filter(Boolean)
            : [];

          const recentImgIds =
            blogsDetailsData.recent_articles?.camping_places
              ?.map((item) => item.image)
              .filter(Boolean) || [];

          const recentIconIds =
            blogsDetailsData.recent_articles?.camping_places
              ?.map((item) => item.icon)
              .filter(Boolean) || [];

          const allImageIds = [
            ...descriptionImg1Ids,
            ...descriptionImg2Ids,
            ...recentImgIds,
            ...recentIconIds,
          ];

          if (allImageIds.length > 0) {
            fetchMediaData(allImageIds)
              .then((mediaData) => {
                const descriptionImgUrls1 = descriptionImg1Ids
                  .map(
                    (id) =>
                      mediaData.find((media) => media.id === id)?.source_url
                  )
                  .filter(Boolean);
                setDescriptionImg1(descriptionImgUrls1);

                const descriptionImgUrls2 = descriptionImg2Ids
                  .map(
                    (id) =>
                      mediaData.find((media) => media.id === id)?.source_url
                  )
                  .filter(Boolean);
                setDescriptionImg2(descriptionImgUrls2);

                const recentImgUrls = recentImgIds
                  .map(
                    (id) =>
                      mediaData.find((media) => media.id === id)?.source_url
                  )
                  .filter(Boolean);
                setRecentImg(recentImgUrls);

                const recentIconUrls = recentIconIds
                  .map(
                    (id) =>
                      mediaData.find((media) => media.id === id)?.source_url
                  )
                  .filter(Boolean);
                setRecentIcon(recentIconUrls);
              })
              .catch((error) =>
                console.error("Error fetching media data:", error)
              );
          }
        }

        const recentArticlesData =
          currentBlogDetails.blog_details?.recent_articles?.camping_places ||
          [];
        setRecentArticles(recentArticlesData);
        setCurrentBlogDesc(currentBlogDetails);
      }
    }
  }, [
    desktopBlogsData,
    cardName,
    setDescriptionImg1,
    setDescriptionImg2,
    setRecentImg,
    setRecentIcon,
  ]);

  if (!currentBlogDesc || recentArticles.length === 0) return null;

  return (
    <div className="desktop-blogs-details-desc-para pl-120 pr-120">
      <Row className="gx-0">
        <Col lg={8}>
          <div className="pt-6 pe-5">
            <p>{currentBlogDesc?.blog_details?.social_media?.description_1}</p>
            <p>{currentBlogDesc?.blog_details?.social_media?.description_2}</p>

            <div className="desktop-blogs-details-img-grid d-flex">
              {descriptionImg1 && descriptionImg1.length > 0
                ? descriptionImg1.map((imgUrl, index) => (
                    <img
                      key={index}
                      src={imgUrl}
                      alt={`Description Image ${index + 1}`}
                      className="img-fluid"
                    />
                  ))
                : null}
            </div>
            <p>{currentBlogDesc?.blog_details?.social_media?.description_3}</p>

            <div className="desktop-blogs-details-img-grid d-flex">
              {descriptionImg2 && descriptionImg2.length > 0
                ? descriptionImg2.map((imgUrl, index) => (
                    <Image
                      key={index}
                      src={imgUrl}
                      alt={`Description Image ${index + 1}`}
                    />
                  ))
                : null}
            </div>

            <p>{currentBlogDesc?.blog_details?.social_media?.description_4}</p>
          </div>
        </Col>

        <Col lg={4}>
          <div className="desktop-blogs-details-recent-search pt-6">
            <InputGroup className="mb-3 custom-input-group">
              <Form.Control placeholder="Search…" aria-label="Search" />
              <InputGroup.Text id="basic-addon2 d-flex justify-content-center align-items-center">
                <div className="icon-container d-flex justify-content-center align-items-center">
                  <svg className="search-icon" viewBox="0 0 24 24">
                    <circle
                      cx="11"
                      cy="11"
                      r="7"
                      stroke="white"
                      strokeWidth="2"
                      fill="none"
                    />
                    <line
                      x1="16.65"
                      y1="16.65"
                      x2="21"
                      y2="21"
                      stroke="white"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
              </InputGroup.Text>
            </InputGroup>

            <div className="desktop-blogs-details-recent-articles">
              <h1>Recent Articles</h1>
              {recentArticles.length > 0 ? (
                recentArticles.map((article, index) => (
                  <div key={index} className="d-flex pb-3">
                    <Image
                      src={recentImg?.[index]}
                      className="desktop-recent-article-img"
                      alt={`Recent Image ${index + 1}`}
                    />

                    <div>
                      {/* <div className="d-flex">
                        <Image
                          src={recentIcon?.[index]}
                          className="desktop-recent-article-icon"
                          alt={`Recent Icon ${index + 1}`}
                        />
                        <p>{article.date_and_time}</p>
                      </div> */}
                      <h2>{article.text}</h2>
                    </div>
                  </div>
                ))
              ) : (
                <p>No recent articles available.</p>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default DesktopBlogsDetailsDesc;
