// import React, { useContext, useEffect, useState } from 'react';
// import { Image, Row, Spinner } from 'react-bootstrap';
// import LeftArrow from '../assets/images/arrow-left.svg';
// import RightArrow from '../assets/images/arrow-right.svg';
// import { motion } from 'framer-motion';
// import { UserContext } from '../../UserContext';
// import { fetchHomePageData, fetchMediaData } from '../../apiUtils';
// import { useNavigate } from 'react-router-dom';

// // import './HomeView.css'; // Ensure this CSS file is imported

// function HomeView() {
//   const { homeData, setHomeData } = useContext(UserContext);
//   const [sliderData, setSliderData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [direction, setDirection] = useState(0);
//   const navigate = useNavigate();


//   useEffect(() => {
//     setLoading(true);

//     const fetchSliderData = async () => {
//       try {
//         const homedata = await fetchHomePageData();
//         setHomeData(homedata);

//         const sliderItems = homedata?.mobile_slider_ || [];

//         const sliderWithImages = await Promise.all(
//           sliderItems.map(async item => {
//             if (item.image) {
//               const mediaData = await fetchMediaData([item.image]);
//               const image = mediaData.find(media => media.id === item.image);
//               return {
//                 ...item,
//                 imageUrl: image ? image.source_url : null,
//               };
//             } else {
//               return item;
//             }
//           })
//         );

//         setSliderData(sliderWithImages);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching slider data:', error);
//         setLoading(false);
//       }
//     };

//     fetchSliderData();
//   }, [setHomeData]);

//   useEffect(() => {
//     const autoSlideInterval = setInterval(() => {
//       setDirection(1);
//       setCurrentSlide(prev => (prev === sliderData.length - 1 ? 0 : prev + 1));
//     }, 5000); // Change slide every 5 seconds

//     return () => clearInterval(autoSlideInterval);
//   }, [sliderData.length]);

//   const handlePrevSlide = () => {
//     setDirection(-1);
//     setCurrentSlide(prev => (prev === 0 ? sliderData.length - 1 : prev - 1));
//   };

//   const handleNextSlide = () => {
//     setDirection(1);
//     setCurrentSlide(prev => (prev === sliderData.length - 1 ? 0 : prev + 1));
//   };

//   if (loading) {
//     return (
//       <div className='loading-container d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
//         <Spinner animation='border' role='status'>
//           <span className='visually-hidden'>Loading</span>
//         </Spinner>
//       </div>
//     );
//   }
//   if (sliderData.length === 0) return null;

//   const redirectToPage = (path) =>{
//     // console.log(redirectToPage);
//     navigate(path)
//   }


//   return (
//     <div className='slider-container'>
//       <div className='mob-slider-container'>
//         <Row className='hero-section gx-0'>
//           <motion.div
//             key={currentSlide}
//             initial={{ x: direction === 1 ? 300 : -300, opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             exit={{ x: direction === 1 ? -300 : 300, opacity: 0 }}
//             transition={{ duration: 0.5 }}
//             className='slide-wrapper'
//           >
//             <div className='hero-text'>
//               {sliderData.length > 0 && sliderData[currentSlide]?.imageUrl ? (
//                 <Image src={sliderData[currentSlide].imageUrl} alt='Hero' className='mob-hero-img' />
//               ) : (
//                 <div>Loading...</div>
//               )}

//               <div className='title-text-container'>
//                 <motion.div
//                   className='title-text'
//                   initial={{ scale: 0 }}
//                   animate={{ scale: 1 }}
//                   transition={{ duration: 0.5 }}
//                 >
//                   <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.2 }}>
//                     {sliderData[currentSlide]?.slider_title_1}
//                   </motion.span>{' '}
//                   <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.4 }}>
//                     {sliderData[currentSlide]?.slider_title_2}
//                   </motion.span>{' '}
//                   <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.6 }}>
//                     {sliderData[currentSlide]?.slider_title_3}
//                   </motion.span>{' '}
//                   <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.8 }}>
//                     {sliderData[currentSlide]?.slider_description}
//                   </motion.span>
//                 </motion.div>
//                 <motion.div
//                   className='btn-container'
//                   initial={{ scale: 0 }}
//                   animate={{ scale: 1 }}
//                   transition={{ delay: 1 }}
//                 >
//                   <motion.div className='btn-img' whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
//                     {/* <a href={sliderData[currentSlide]?.mobile_explore_more_link}> */}
//                       <a onClick={()=> redirectToPage(sliderData[currentSlide]?.mobile_explore_more_link)}>

//                       <p>Explore More</p>
//                     </a>
//                   </motion.div>
//                 </motion.div>
//               </div>
//             </div>
//             <div className='arrow-container'>
//               <motion.div
//                 className='arrow-btn left-arrow'
//                 initial={{ scale: 0 }}
//                 animate={{ scale: 1 }}
//                 transition={{ duration: 0.5 }}
//                 onClick={handlePrevSlide}
//               >
//                 <motion.img
//                   src={LeftArrow}
//                   className='left-arrow'
//                   initial={{ scale: 0 }}
//                   animate={{ scale: 1 }}
//                   transition={{ delay: 1 }}
//                 />
//               </motion.div>
//               <motion.div
//                 className='arrow-btn right-arrow'
//                 initial={{ scale: 0 }}
//                 animate={{ scale: 1 }}
//                 transition={{ duration: 0.5 }}
//                 onClick={handleNextSlide}
//               >
//                 <motion.img
//                   src={RightArrow}
//                   className='right-arrow'
//                   initial={{ scale: 0 }}
//                   animate={{ scale: 1 }}
//                   transition={{ delay: 1 }}
//                 />
//               </motion.div>
//             </div>
//             <div className='dots-container'>
//               {sliderData.map((_, index) => (
//                 <div
//                   key={index}
//                   className={`mob-dot ${index === currentSlide ? 'active' : ''}`}
//                   onClick={() => setCurrentSlide(index)}
//                 />
//               ))}
//             </div>
//           </motion.div>
//         </Row>
//       </div>
//     </div>
//   );
// }

// export default HomeView;



import React, { useContext, useEffect, useState } from 'react';
import { Image, Row, Spinner } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { UserContext } from '../../UserContext';
import { fetchHomePageData, fetchMediaData } from '../../apiUtils';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable'; 

function HomeView() {
  const { homeData, setHomeData } = useContext(UserContext);
  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0); 
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    const fetchSliderData = async () => {
      try {
        const homedata = await fetchHomePageData();
        setHomeData(homedata);

        const sliderItems = homedata?.mobile_slider_ || [];

        const sliderWithImages = await Promise.all(
          sliderItems.map(async item => {
            if (item.image) {
              const mediaData = await fetchMediaData([item.image]);
              const image = mediaData.find(media => media.id === item.image);
              return {
                ...item,
                imageUrl: image ? image.source_url : null,
              };
            } else {
              return item;
            }
          })
        );

        setSliderData(sliderWithImages);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching slider data:', error);
        setLoading(false);
      }
    };

    fetchSliderData();
  }, [setHomeData]);


  useEffect(() => {
        const autoSlideInterval = setInterval(() => {
          setDirection(1);
          setCurrentSlide(prev => (prev === sliderData.length - 1 ? 0 : prev + 1));
        }, 5000); 
    
        return () => clearInterval(autoSlideInterval);
      }, [sliderData.length]);

  const handleSwipe = (swipeDirection) => {
    if (swipeDirection === 'LEFT') {
      setDirection(1);  
      setCurrentSlide(prev => (prev === sliderData.length - 1 ? 0 : prev + 1));
    } else if (swipeDirection === 'RIGHT') {
      setDirection(-1);  
      setCurrentSlide(prev => (prev === 0 ? sliderData.length - 1 : prev - 1));
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('LEFT'),
    onSwipedRight: () => handleSwipe('RIGHT'),
    trackMouse: true,  
  });

  if (loading) {
    return (
      <div className='loading-container d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading</span>
        </Spinner>
      </div>
    );
  }
  if (sliderData.length === 0) return null;

  const redirectToPage = (path) => {
    navigate(path);
  };

  return (
    <div className='slider-container'>
      <div className='mob-slider-container' {...swipeHandlers}>  
        <Row className='hero-section gx-0'>
          <motion.div
            key={currentSlide}
            initial={{ x: direction === 1 ? 300 : -300, opacity: 0 }}  
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: direction === 1 ? -300 : 300, opacity: 0 }}  
            transition={{ duration: 0.5 }}
            className='slide-wrapper'
          >
            <div className='hero-text'>
              {sliderData.length > 0 && sliderData[currentSlide]?.imageUrl ? (
                <Image src={sliderData[currentSlide].imageUrl} alt='Hero' className='mob-hero-img' />
              ) : (
                <div>Loading...</div>
              )}

              <div className='title-text-container'>
                <motion.div
                  className='title-text'
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.2 }}>
                    {sliderData[currentSlide]?.slider_title_1}
                  </motion.span>{' '}
                  <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.4 }}>
                    {sliderData[currentSlide]?.slider_title_2}
                  </motion.span>{' '}
                  <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.6 }}>
                    {sliderData[currentSlide]?.slider_title_3}
                  </motion.span>{' '}
                  <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.8 }}>
                    {sliderData[currentSlide]?.slider_description}
                  </motion.span>
                </motion.div>
                <motion.div
                  className='btn-container'
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 1 }}
                >
                  <motion.div className='btn-img' whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <a onClick={() => redirectToPage(sliderData[currentSlide]?.mobile_explore_more_link)}>
                      <p>Explore More</p>
                    </a>
                  </motion.div>
                </motion.div>
              </div>
            </div>
            <div className='dots-container'>
              {sliderData.map((_, index) => (
                <div
                  key={index}
                  className={`mob-dot ${index === currentSlide ? 'active' : ''}`}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </div>
          </motion.div>
        </Row>
      </div>
    </div>
  );
}

export default HomeView;
