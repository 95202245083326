import React, { useState } from "react";
import { Image } from "react-bootstrap";
import PageNotFoundImg from "./assets/images/404_page_not_foundΓÇôwith_text.png";

function MobilePageNotFound() {
  return (
    <div>
      <Image src={PageNotFoundImg} className="mobile-not-found-img" 
      
       />
    </div>
  );
}

export default MobilePageNotFound;
