import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Image, Spinner } from 'react-bootstrap';
import GradientBtn from '../gradient-btn';
import DesktopLeftArrow from '../desktop-assets/images/arrow-left copy.svg';
import DesktopRightArrow from '../desktop-assets/images/arrow-right copy.svg';
import { UserContext } from '../../UserContext';
import { fetchHomePageData, fetchSingleMedia } from '../../apiUtils';
import DesktopShape from '../desktop-assets/images/shape.png';
import { useNavigate } from 'react-router-dom';

function DesktopHomepageView() {
  const { setDesktopHomeData } = useContext(UserContext);
  const navigate = useNavigate();
  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    setLoading(true);
    const fetchSliderData = async () => {
      try {
        const homedata = await fetchHomePageData();
        setDesktopHomeData(homedata);

        const sliderItems = homedata?.slider || [];
        const sliderWithImages = await Promise.all(
          sliderItems.map(async item => {
            if (item.image) {
              const mediaData = await fetchSingleMedia(item.image);
              const fullImageUrl = mediaData.media_details.sizes.full.source_url || null;

              return { ...item, imageUrl: fullImageUrl };
            } else {
              return item;
            }
          })
        );

        setSliderData(sliderWithImages);
      } catch (error) {
        console.error('Error fetching slider data:', error);
      }
      setLoading(false);
    };

    fetchSliderData();
  }, [setDesktopHomeData]);

  useEffect(() => {
    const autoSlide = setTimeout(() => {
      setDirection(1);
      setCurrentSlide(prev => (prev === sliderData.length - 1 ? 0 : prev + 1));
    }, 4000);

    return () => clearTimeout(autoSlide);
  }, [currentSlide, sliderData.length]);

  const handlePrevSlide = () => {
    setDirection(-1);
    setCurrentSlide(prev => (prev === 0 ? sliderData.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setDirection(1);
    setCurrentSlide(prev => (prev === sliderData.length - 1 ? 0 : prev + 1));
  };

  const handleDotClick = index => {
    setDirection(index > currentSlide ? 1 : -1);
    setCurrentSlide(index);
  };

  if (loading) {
    return (
      <div className='loading-container d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading</span>
        </Spinner>
      </div>
    );
  }

  if (!sliderData.length) {
    return null;
  }

  const redirectToPage = (path) =>{
    navigate(path)
  }

  return (
    <div className='slider-container'>
      <div>
        <motion.div
          className='homepage-hero-section'
          key={currentSlide}
          initial={{ x: direction === 1 ? 300 : -300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: direction === 1 ? -300 : 300, opacity: 0 }}
          transition={{ duration: 0.8, ease: [0.43, 0.13, 0.23, 0.96] }}
        >
          <Image
            src={sliderData[currentSlide].imageUrl}
            className='d-block w-100 desktop-hero-img'
            alt={`Slide ${currentSlide}`}
          />
          {/* <Image src={DesktopShape} className='desktop-logo-shape' /> */}
          <div className='overlay'></div>
          <div className='desktop-hero-text'>
            <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.7 }}>
              {sliderData[currentSlide].slider_title_1}
            </motion.span>
            <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.9 }}>
              {sliderData[currentSlide].slider_title_2}
            </motion.span>
            <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 1.1 }}>
              {sliderData[currentSlide].slider_description}
            </motion.span>
          </div>
          <motion.div
            className='btn-container'
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1.3 }}
          >
            <a onClick={()=> redirectToPage(sliderData[currentSlide]?.explore_more_link)}>
              <GradientBtn
                className='explore-more-btn d-flex justify-content-center align-items-center'
                text='Explore More'
              />
            </a>
          </motion.div>
        </motion.div>

        <motion.div
          className='desktop-arrow-btn desktop-left-arrow'
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5 }}
          onClick={handlePrevSlide}
        >
          <motion.img
            src={DesktopLeftArrow}
            className='desktop-left-arrow'
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1.5 }}
          />
        </motion.div>

        <motion.div
          className='desktop-arrow-btn desktop-right-arrow'
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5 }}
          onClick={handleNextSlide}
        >
          <motion.img
            src={DesktopRightArrow}
            className='desktop-right-arrow'
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1.5 }}
          />
        </motion.div>

        <div className='pagination-dots'>
          {sliderData.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DesktopHomepageView;
