import React, { useEffect, useState } from "react";
import { Navbar, Container } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import HomeIcon from "../assets/images/ic-home.svg";
import DestinationIcon from "../assets/images/ic-destinations.svg";
import DestinationIconActive from "../assets/images/destination-active.svg";
import EventsIcon from "../assets/images/ic-events.svg";
import HomeIconActive from "../assets/images/home-active.svg";
import EventsIconActive from "../assets/images/Group 36992.svg";
import BlogsIcon from "../assets/images/ic-blog.svg";
import OurStoryIcon from "../assets/images/ic-story.svg";
import OurStoryActiveIcon from "../assets/images/ic-story-active.svg";
import BlogsActiveIcon from "../assets/images/ic-blog-active.svg";

function Menu() {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("home");

  useEffect(() => {
    const currentPath = location.pathname;

    if (
      currentPath.startsWith("/destination") ||
      currentPath.includes("/destination-details")
    ) {
      setActiveItem("destination");
    } else if (currentPath.startsWith("/events")) {
      setActiveItem("events");
    } else if (currentPath.startsWith("/blogs")) {
      setActiveItem("blogs");
    } else if (currentPath.startsWith("/our-story")) {
      setActiveItem("our-story");
    } else {
      setActiveItem("home");
    }
  }, [location.pathname]);

  return (
    <div>
      <Navbar fixed="bottom" className="menu">
        <Container>
          <NavLink to="/home" className="text-decoration-none">
            <div>
              <Navbar.Brand href="#home">
                <img
                  src={activeItem === "home" ? HomeIconActive : HomeIcon}
                  alt="Home"
                />
                <span className={activeItem === "home" ? "bold-text" : ""}>
                  Home
                </span>
              </Navbar.Brand>
            </div>
          </NavLink>

          <NavLink to="/destination" className="text-decoration-none">
            <div>
              <Navbar.Brand href="#destination">
                <img
                  src={
                    activeItem === "destination"
                      ? DestinationIconActive
                      : DestinationIcon
                  }
                  alt="Destination"
                />
                <span
                  className={activeItem === "destination" ? "bold-text" : ""}
                >
                  Destination
                </span>
              </Navbar.Brand>
            </div>
          </NavLink>

          <NavLink to="/our-story" className="text-decoration-none">
            <div>
              <Navbar.Brand href="#our-story">
                <img
                  src={
                    activeItem === "our-story"
                      ? OurStoryActiveIcon
                      : OurStoryIcon
                  }
                  alt="Our Story"
                />
                <span className={activeItem === "our-story" ? "bold-text" : ""}>
                  Our Story
                </span>
              </Navbar.Brand>
            </div>
          </NavLink>

          <NavLink to="/events" className="text-decoration-none">
            <div>
              <Navbar.Brand href="#events">
                <img
                  src={activeItem === "events" ? EventsIconActive : EventsIcon}
                  alt="Events"
                />
                <span className={activeItem === "events" ? "bold-text" : ""}>
                  Events
                </span>
              </Navbar.Brand>
            </div>
          </NavLink>

          <NavLink to="/blogs" className="text-decoration-none">
            <div>
              <Navbar.Brand href="#blogs">
                <img
                  src={activeItem === "blogs" ? BlogsActiveIcon : BlogsIcon}
                  alt="Blogs"
                />
                <span className={activeItem === "blogs" ? "bold-text" : ""}>
                  Blogs
                </span>
              </Navbar.Brand>
            </div>
          </NavLink>
        </Container>
      </Navbar>
    </div>
  );
}

export default Menu;
