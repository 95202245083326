import React, { useState } from "react";
import "../style/events.scss";
import { Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardsMail from "../assets/images/dark-mail.svg";
import { UserContext } from "../../UserContext";
import { useContext, useEffect } from "react";
import { fetchSingleMedia } from "../../apiUtils";
import CardsLinkedin from "../assets/images/linkedin (1).svg";
import KnowMoreArrowForMobile from "../assets/images/our-team-arrow.svg";
import OurTeamImg from '../assets/images/MSH team.jpg';


function OurTeam() {
  const { ourStoryData } = useContext(UserContext);
  const [passportImg, setPassportImg] = useState([]);

  const teamMembers = ourStoryData?.know__our_team?.team_card || [];

  useEffect(() => {
    const fetchLogos = async () => {
      if (
        !ourStoryData ||
        !ourStoryData.know__our_team ||
        !Array.isArray(ourStoryData.know__our_team.team_card) ||
        ourStoryData.know__our_team.team_card.length === 0
      ) {
        // console.log("ourStoryData is not populated yet");
        return;
      }

      try {
        const cardsImages = ourStoryData.know__our_team.team_card;

        const imagePromises = cardsImages.map(async (item) => {
          if (!item.image) {
            console.warn("No image ID found for item:", item);
            return null;
          }

          const id = item.image;

          try {
            const mediaResponse = await fetchSingleMedia(id);

            const descriptionHtml = mediaResponse?.description?.rendered || "";
            const parser = new DOMParser();
            const doc = parser.parseFromString(descriptionHtml, "text/html");
            const imgElement = doc.querySelector("img");

            return imgElement ? imgElement.src : null;
          } catch (fetchError) {
            console.error("Error fetching media:", fetchError);
            return null;
          }
        });

        const passportImgUrls = await Promise.all(imagePromises);
        // console.log("Fetched image URLs:", passportImgUrls);

        setPassportImg(passportImgUrls.filter((url) => url !== null));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLogos();
  }, [ourStoryData]);


    if (!ourStoryData?.know__our_team || teamMembers.length === 0 || passportImg.length === 0) {
      return null;
    }
    
  return (
    <div className="our-team-section">
          <div className="our-team-title">
              <p className="d-flex justify-content-center">
                {ourStoryData?.know__our_team?.title_1}
              </p>
            
              <p className="d-flex justify-content-center">
                {ourStoryData?.know__our_team?.title_2}
              </p>
          </div>

          <div className="mob-our-team-cards">
            <Image src={OurTeamImg} />
            {/* {teamMembers.map((member, index) => (
              <Card key={index} style={{ width: "100%" }}>
                <Card.Img
                  variant="top"
                  src={passportImg[index]}
                  className="mob-passport-photo"
                />
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                       <Card.Title>{member.name}</Card.Title>
                      <div className="mob-card-text-images">
                        <Card.Text>{member.post}</Card.Text>
                      </div>
                    </div>
                    <div className="mob-card-image d-flex justify-content-center align-items-center me-3">
                      <Image src={CardsMail} alt="Image 1" />
                      <Image src={CardsLinkedin} alt="Image 1" className="p-0" />
                    </div>
                  </div>
                  <div className="mob-cards-hr-line"></div>
                  <div className="mob-cards-btn">
                    <span>Know More</span>
                    <Image src={KnowMoreArrowForMobile}></Image>
                  </div>
                </Card.Body>
              </Card>
            ))} */}
          </div>
    </div >
  );
}

export default OurTeam;
