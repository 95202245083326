import React from 'react';
import { Image, Button, FormControl } from 'react-bootstrap';
import Logo from '../assets/images/Asset-3.png';
import Shape from '../assets/images/shape.png';
import Call from '../assets/images/call.svg';
import Mail from '../assets/images/mail.svg';
import FB from '../assets/images/fb (1).svg';
import YouTube from '../assets/images/youtube.svg';
import Insta from '../assets/images/insta (1).svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import GiftCardForm from './gift-card-mob';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { UserContext } from '../../UserContext';
import { useContext } from 'react';

function Footer() {
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [acfMessage, setAcfMessage] = useState('');
  const [message, setMessage] = useState('');

  const [showGiftCardForm, setShowGiftCardForm] = useState(false);

  const { giftMessage, setGiftMessage } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    axios
      .get('https://msh.infoglans.com/wp-json/wp/v2/pages/3079')
      .then(response => {
        const acf = response.data.acf;
        if (acf?.message) {
          setAcfMessage(acf.message);
        }

        if (acf?.gift_card_message) {
          setGiftMessage(acf.gift_card_message);
        }
      })
      .catch(error => {
        console.error('Failed to fetch API data:', error);
      });
  }, []);

  const sendEmail = data => {
    const templateParams = {
      message: `New subscription from ${data.email}`,
    };

    emailjs
      .send('service_r68krna', 'template_umh5nnc', templateParams, 'Q_bSr6P2XoBECSp8E')
      .then(response => {
        setMessage('Thank you for subscribing!');
      })
      .catch(err => {
        setMessage('Failed to send your request. Please try again later.');
      });
  };

  const onSubmit = data => {
    sendEmail(data);
  };

  const handleOpenGiftCardForm = () => {
    setShowGiftCardForm(true);
  };

  const handleCloseGiftCardForm = () => {
    setShowGiftCardForm(false);
  };

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div>
      <div className=''>
        {isVisible && (
          <div className='scroll-to-top-icon' onClick={scrollToTop}>
            <FontAwesomeIcon icon={faAngleUp} className='scroll-to-top' />
          </div>
        )}
        <div className='footer-logo d-flex'>
          <Image src={Shape} className='footer-logo-bg-shape' />
          <Image src={Logo} className='footer-logo-img' />
        </div>
        <div className='footer-section'>
          <p>
            Started by five friends, we at Moonstone Hammock provide a one-of-a-kind adventure tourism experience to
            campers across India. Everyone from Six-year-olds, 60-year-olds, solo campers, couples, groups, corporates
            and families, their clients are as varied as the adventures at their bespoke campsites.
          </p>
          <div className='social-icon d-flex'>
            <div className='icon me-3'>
              <Image src={FB} onClick={() => openInNewTab('https://www.facebook.com')} style={{ cursor: 'pointer' }} />
            </div>
            <div className='icon me-3  '>
              <Image
                src={Insta}
                onClick={() => openInNewTab('https://www.instagram.com')}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className='icon'>
              <Image
                src={YouTube}
                onClick={() => openInNewTab('https://www.youtube.com')}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>

          <div className='footer-quick-links d-flex '>
            <div>
              <h2 className='pt-0 pb-0 footer-title'>Quick Links</h2>
              <div>
                <Link to='/home' className='text-decoration-none'>
                  <p>Home</p>
                </Link>
                <Link to='/destination' className='text-decoration-none'>
                  {' '}
                  <p>Destination</p>
                </Link>
                <Link to='/events' className='text-decoration-none'>
                  {' '}
                  <p>Events</p>
                </Link>
                <Link to='/blogs' className='text-decoration-none'>
                  {' '}
                  <p>Blogs</p>
                </Link>
                <Link to='/our-story' className='text-decoration-none'>
                  {' '}
                  <p>Our Story</p>
                </Link>
                <p onClick={handleOpenGiftCardForm} style={{ cursor: 'pointer' }}>
                  Gift Card
                </p>

                <GiftCardForm show={showGiftCardForm} handleClose={handleCloseGiftCardForm} />
              </div>
            </div>
          </div>

          <div className='contact-section'>
            <h2>Contact</h2>
            <div className='contact d-flex align-item-center '>
              <Image src={Call} />
              <a href='tel:+919321992712' className='text-decoration-none'>
                +91 93219 92712
              </a>
            </div>
            <div className='contact d-flex align-items-center'>
              <Image src={Mail} />
              <a href='mailto:hello@moonstonehammock.com' className='text-decoration-none'>
                hello@moonstonehammock.com
              </a>
            </div>
          </div>

          <div className='footer-newsletter  d-flex '>
            <div>
              <h2 className='pt-0  d-flex'>Newsletter</h2>
              <div className='newsletter-container'>
                <form onSubmit={handleSubmit(onSubmit)} className='newsletter-form'>
                  <InputGroup className='email-input-group'>
                    <FormControl
                      type='email'
                      placeholder='Your Email Address'
                      {...register('email', {
                        required: 'Your Email Address is required',
                      })}
                      className='email-input'
                    />
                    <Button type='submit' className='newsletter-submit email-button'>
                      <span className='arrow-icon'>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </Button>
                  </InputGroup>
                  {errors.email && <span className='text-danger mt-2 error-message'>{errors.email.message}</span>}
                  {message && <span className='response-message'>{message}</span>}
                </form>
              </div>
            </div>
          </div>

          <div className='hr-line'></div>

          <div>
            <h2>Moonstone Hammock © 2024. All Rights Reserved.</h2>
          </div>
          <div className='footer-policy d-flex align-items-center'>
            <Link to='/privacy-policy' className='text-decoration-none'>
              <span>Privacy Policy</span>
            </Link>
            <span className='separator mx-2'></span>
            <Link to='/terms-and-conditions' className='text-decoration-none'>
              <span>Terms & Conditions</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
