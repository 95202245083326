import React from "react";
import "../style/events.scss";
import OurStoryView from "./our-story-view";
import OurStoryHistoryTimeline from "./our-story-history-timeline";
import OurStoryOrigin from "./our-story-origin";
import OurTeam from "./our-team";
import OurStoryJoinDream from "./join-dream";

function OurStory() {
  return (
    <div>
      <OurStoryView />
      <OurStoryHistoryTimeline />
      <OurStoryOrigin />
      <OurTeam />
      <OurStoryJoinDream />
    </div>
  );
}

export default OurStory;
