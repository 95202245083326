import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Image } from 'react-bootstrap';
import hammockImg from '../desktop-assets/images/MSHLogo.png';

function DesktopHistoryTimeline() {
  return (
    <div className='desktop-history-timeline-section'>
      <div className='desktop-history-timeline-title'>
        <p>How did</p>
        <p>We Get Here?</p>
      </div>
      <div className='timeline'>
        <Row>
          <Col xs={12} md={6} className='timeline-item bottom'>
            <div className='timeline-content-2'>
              <h3>2016</h3>
              <ul className='custom-list'>
                {/* <Image className='list-logo' src={hammockImg}/> */}
                <li className='custom-list-image'>Karjat</li>
                <li className='custom-list-dot'>Begins</li>
              </ul>
            </div>
          </Col>

          <Col xs={12} md={6} className='timeline-item top'>
            <div className='timeline-content'>
              <h3>2017</h3>
              <ul className='custom-list '>
                <li className='custom-list-dot'>Floating Tents</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6} className='timeline-item bottom'>
            <div className='timeline-content-2'>
              <h3>2018</h3>
              <ul className='custom-list'>
                <li className='custom-list-dot'>365 days Glamping experience</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6} className='timeline-item top'>
            <div className='timeline-content'>
              <h3>2019</h3>
              <ul className='custom-list'>
                <li className='custom-list-dot'>Forest Cabins</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6} className='timeline-item bottom'>
            <div className='timeline-content-2'>
              <h3>2020</h3>
              <ul className='custom-list'>
                <li className='custom-list-dot'>Hunters Nest</li>
                <li className='custom-list-dot'>Top Glamping sites in India by TOI</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6} className='timeline-item top'>
            <div className='timeline-content'>
              <h3>2021</h3>
              <ul className='custom-list'>
                <li className='custom-list-image'>Alibaug</li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={6} className='timeline-item bottom'>
            <div className='timeline-content-2'>
              <h3>2022</h3>
              <ul className='custom-list'>
                <li className='custom-list-dot'>Buddha cafe became the must visit restaurant in Alibaug</li>
                <li className='custom-list-image'>Jibhi</li>
              </ul>
            </div>
          </Col>
          {/* <Col xs={12} md={6} className='timeline-item top'>
            <div className='timeline-content'>
              <h3>2023</h3>
              <ul className='custom-list'>
                <li>Lorem Ipsum Sit Amet</li>
              </ul>
            </div>
          </Col> */}
          <Col xs={12} md={6} className='timeline-item top'>
            <div className='timeline-content '>
              <h3>2024</h3>
              <ul className='custom-list'>
                <li className='custom-list-image'>Bengaluru</li>
                <li className='custom-list-dot'>Outdoor adventure partner with Decathlon </li>

                {/* <li>Decathlon</li> */}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default DesktopHistoryTimeline;
